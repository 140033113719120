/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default () => {
  return (
    <MDBox>
      <MDBox px={4}>
        <MDTypography variant="h3">Marketing</MDTypography>
      </MDBox>
      <MDBox px={4} py={2}>
        Coming Soon
      </MDBox>
    </MDBox>
  );
};
