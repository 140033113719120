import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

export const getOrders = async (data) => {
  try {
    let res = await axios.get("/orders");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const setStatus = async (data) => {
  try {
    let res = await axios.post("/order/status", data);
    return res.data;
  } catch (err) {
    return err;
  }
};
