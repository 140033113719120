import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import "../css/product.css";
import { Checkbox, TextField, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { MDDialog } from "components/MDDialog";
import {
  FilterList,
  FilterListOff,
  AddAPhoto,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowUp,
  KeyboardDoubleArrowDown,
  Create,
  DoneOutline,
  Add,
  Cancel,
  Undo,
  VisibilityOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import axios from "../../../redux/axios.config";

export default function Segment({ article, setProd, updating, idx, prod, currentLp }) {
  const [original, setOriginal] = useState(article);
  const [mArticle, setMArticle] = useState(article);

  // Move Segment
  const moveUp = async (idx) => {
    if (idx === 0) return;
    let lp = {};
    prod.landingPages.map((l) => {
      if (l.name === currentLp) {
        lp = l;
      }
    });
    let array = [...lp.articles];

    let removedItem = array.splice(idx, 1);

    array.splice(idx - 1, 0, removedItem[0]);

    let lps = [...prod.landingPages];
    lps = lps.map((l) => {
      if (l.name === currentLp) {
        l.articles = array;
      }
      return l;
    });
    setProd({ ...prod, landingPages: lps });
  };

  const moveDown = async (idx) => {
    let lp = {};
    prod.landingPages.map((l) => {
      if (l.name === currentLp) {
        lp = l;
      }
    });

    let array = [...lp.articles];

    let removedItem = array.splice(idx, 1);

    array.splice(idx + 1, 0, removedItem[0]);

    let lps = [...prod.landingPages];
    lps = lps.map((l) => {
      if (l.name === currentLp) {
        l.articles = array;
      }
      return l;
    });
    setProd({ ...prod, landingPages: lps });
  };
  const toTop = async (idx) => {
    let lp = {};
    prod.landingPages.map((l) => {
      if (l.name === currentLp) {
        lp = l;
      }
    });

    let array = [...lp.articles];

    let removedItem = array.splice(idx, 1);

    array = [removedItem[0], ...array];
    let lps = [...prod.landingPages];
    lps = lps.map((l) => {
      if (l.name === currentLp) {
        l.articles = array;
      }
      return l;
    });
    setProd({ ...prod, landingPages: lps });
  };

  const toBottom = async (idx) => {
    let lp = {};
    prod.landingPages.map((l) => {
      if (l.name === currentLp) {
        lp = l;
      }
    });

    let array = [...lp.articles];

    let removedItem = array.splice(idx, 1);

    array.push(removedItem[0]);

    let lps = [...prod.landingPages];
    lps = lps.map((l) => {
      if (l.name === currentLp) {
        l.articles = array;
      }
      return l;
    });
    setProd({ ...prod, landingPages: lps });
  };

  //update article controls
  const [update, setUpdate] = useState(false);
  const [saving, setSaving] = useState(false);
  //   toggle updates
  const toggleUpdate = async () => {
    if (!update) {
      setUpdate(true);
    } else {
      setSaving(true);
      if (isFile(image)) {
        if (image.size / (1024 * 1024) > 10) {
          alert("Please upload an image under 10MB for web compatibility.");
          setSaving(false);
          return;
        }
        let fd = new FormData();
        fd.append("img", image);

        let res = await axios.post("/image/upload/single", fd, {
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // setAddingArticle(percentCompleted + " %");
          },
        });

        if (res.data.success) {
          updateImage(res.data.data);
        } else {
          alert("Error occured while uploading image");
          updateImage(original.content.image);
        }
      }

      let lp = {};
      prod.landingPages.map((l) => {
        if (l.name === currentLp) {
          lp = l;
        }
      });

      let array = [...lp.articles];
      array = array.map((a) => {
        if (a._id === article._id) {
          return mArticle;
        } else return a;
      });

      let lps = [...prod.landingPages];
      lps = lps.map((l) => {
        if (l.name === currentLp) {
          l.articles = array;
        }
        return l;
      });
      setProd({ ...prod, landingPages: lps });

      setSaving(false);
      setUpdate(false);
    }
  };
  //cancel changes
  const cancelChanges = () => {
    let lp = {};
    prod.landingPages.map((l) => {
      if (l.name === currentLp) {
        lp = l;
      }
    });

    let array = [...lp.articles];

    array = array.map((a) => {
      if (a._id === article._id) {
        return original;
      } else return a;
    });

    let lps = [...prod.landingPages];
    lps = lps.map((l) => {
      if (l.name === currentLp) {
        l.articles = array;
      }
      return l;
    });
    setProd({ ...prod, landingPages: lps });

    setImage(article.content.image);
    setUpdate(false);
  };

  //   List Items
  const [newListItem, setNewListItem] = useState("");
  const addListItem = () => {
    if (!newListItem) return;
    if (mArticle.content.items.length >= 5) return;
    setMArticle({
      ...mArticle,
      content: { ...mArticle.content, items: [...mArticle.content.items, newListItem] },
    });
    setNewListItem("");
  };

  const removeListItem = (idx) => {
    let items = [...mArticle.content.items];
    items.splice(idx, 1);
    let myA = { ...mArticle };
    myA.content = { ...mArticle.content };
    myA.content.items = items;
    setMArticle(myA);
  };

  //   Counter Items
  const [newCounterItem, setNewCounterItem] = useState({});
  const addCounterItem = () => {
    if (!newCounterItem.value || !newCounterItem.title) return;
    if (mArticle.content.values >= 2) return;

    setMArticle({
      ...mArticle,
      content: { ...mArticle.content, values: [...mArticle.content.values, newCounterItem] },
    });
    setNewCounterItem({});
  };
  const removeCounterItem = (idx) => {
    let mvalues = [...mArticle.content.values];
    mvalues.splice(idx, 1);
    setMArticle({
      ...mArticle,
      content: { ...mArticle.content, values: mvalues },
    });
  };

  //Carousel Items
  const [newCarouselItem, setNewCarouselItem] = useState({});
  const [addingCarousel, setAddingCarousel] = useState(false);
  const addCarouselItem = async () => {
    if (!newCarouselItem.description && !newCarouselItem.title && !newCarouselItem.image) return;
    if (mArticle.content.values >= 10) return;
    setAddingCarousel(true);

    let item = { ...newCarouselItem };
    if (newCarouselItem.image && isFile(newCarouselItem.image)) {
      if (newCarouselItem.image.size / (1024 * 1024) > 10) {
        alert("Please upload an image under 10MB for web compatibility.");
        setSaving(false);
        setAddingCarousel(false);
        return;
      }
      let fd = new FormData();
      fd.append("img", newCarouselItem.image);

      let res = await axios.post("/image/upload/single", fd, {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          // setAddingCarousel(percentCompleted + " %");
        },
      });
      if (res.data.success) {
        item.image = res.data.data;
      } else {
        alert("Error occured while uploading image");
        setAddingCarousel(false);
        return;
      }
    }
    setMArticle({
      ...mArticle,
      content: { ...mArticle.content, values: [...mArticle.content.values, item] },
    });
    setNewCarouselItem({});
    setAddingCarousel(false);
  };
  const removeCarouselItem = (idx) => {
    let mvalues = [...mArticle.content.values];
    mvalues.splice(idx, 1);
    setMArticle({
      ...mArticle,
      content: { ...mArticle.content, values: mvalues },
    });
  };

  //Other Article Values
  const updateArticle = (key, value) => {
    let mcontent = { ...mArticle.content };
    mcontent[key] = value;
    setMArticle({ ...mArticle, content: mcontent });
  };

  // Update Image
  const [image, setImage] = useState(article.content.image);
  const isFile = (input) => "File" in window && input instanceof File;
  const getURL = (image) => {
    let url = URL.createObjectURL(image);
    return url;
  };
  const updateImage = (url) => {
    setImage(url);
    let mcontent = { ...mArticle.content };
    mcontent.image = url;
    setMArticle({ ...mArticle, content: mcontent });
  };
  const removeImage = () => {
    setImage("");
    let mcontent = { ...mArticle.content };
    delete mcontent.image;
    setMArticle({ ...mArticle, content: mcontent });
  };

  // Revert when user cancels other changes
  useEffect(() => {
    setImage(article.content.image);
  }, [prod]);

  //Toggle Visibility
  const toggleVisibility = () => {
    let lp = {};
    prod.landingPages.map((l) => {
      if (l.name === currentLp) {
        lp = l;
      }
    });

    let array = [...lp.articles];
    array = array.map((a) => {
      if (a._id === article._id) {
        let myA = { ...a };
        myA.content = { ...a.content };
        myA.visibility = !myA.visibility;
        return myA;
      } else return a;
    });

    let lps = [...prod.landingPages];
    lps = lps.map((l) => {
      if (l.name === currentLp) {
        l.articles = array;
      }
      return l;
    });
    setProd({ ...prod, landingPages: lps });
  };

  const [keys, setKeys] = useState(Object.keys(article.content));

  const camelCase = (text) => {
    return text[0].toUpperCase() + text.slice(1);
  };

  const deleteArticle = (id) => {
    setProd({
      ...prod,
      landingPages: prod.landingPages.map((lp) => {
        if (lp.name === currentLp) {
          lp.articles = lp.articles.filter((a) => a._id !== id);
        }
        return lp;
      }),
    });
  };

  return (
    <MDBox
      style={{
        backgroundColor: "#dfdfdf80",
        padding: "15px",
        margin: "5px 0px",
        borderRadius: "7px",
      }}
    >
      <MDBox display="flex" justifyContent="space-between">
        <Typography
          style={{ display: "flex", alignItems: "center", marginRight: "10px", fontSize: "13pt" }}
        >
          Type: {camelCase(article.type)}{" "}
          <span
            onClick={toggleVisibility}
            style={{
              marginLeft: "15px",
              cursor: "pointer",
              pointerEvents: updating ? "initial" : "none",
            }}
          >
            {article.visibility ? (
              <VisibilityOutlined style={{ color: "dodgerblue" }} />
            ) : (
              <VisibilityOffOutlined style={{ color: "grey" }} />
            )}
          </span>
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            display: "flex",
            flexWrap: "wrap",
            // justifyContent: "center",
          }}
        >
          <MDTypography>
            <KeyboardDoubleArrowUp
              onClick={() => toTop(idx)}
              size="small"
              style={{
                backgroundColor: "green",
                color: "white",
                cursor: "pointer",
                marginRight: "15px",
                borderRadius: "5px",
                pointerEvents: updating ? "initial" : "none",
              }}
            />
            <KeyboardArrowUp
              onClick={() => moveUp(idx)}
              size="small"
              style={{
                backgroundColor: "green",
                color: "white",
                cursor: "pointer",
                marginRight: "15px",
                borderRadius: "5px",
                pointerEvents: updating ? "initial" : "none",
              }}
            />
          </MDTypography>
          <span style={{ marginRight: "15px" }}>{idx}</span>
          <MDTypography>
            <KeyboardArrowDown
              onClick={() => moveDown(idx)}
              size="small"
              style={{
                backgroundColor: "green",
                color: "white",
                cursor: "pointer",
                marginRight: "15px",
                borderRadius: "5px",
                pointerEvents: updating ? "initial" : "none",
              }}
            />
            <KeyboardDoubleArrowDown
              onClick={() => toBottom(idx)}
              size="small"
              style={{
                backgroundColor: "green",
                color: "white",
                cursor: "pointer",
                marginRight: "15px",
                borderRadius: "5px",
                pointerEvents: updating ? "initial" : "none",
              }}
            />
          </MDTypography>
        </Typography>
        <Typography style={{ display: "flex", justifyContent: "right", flexWrap: "wrap" }}>
          <MDButton
            onClick={() => toggleUpdate()}
            size="small"
            style={{
              backgroundColor: "dodgerblue",
              color: "white",
              pointerEvents: updating ? "initial" : "none",
              margin: "5px 7px",
            }}
          >
            {saving ? "..." : update ? <DoneOutline /> : <Create />}
          </MDButton>
          <MDButton
            onClick={() => cancelChanges()}
            size="small"
            style={{
              backgroundColor: "goldenrod",
              color: "white",
              pointerEvents: updating ? "initial" : "none",
              margin: "5px 7px",
            }}
          >
            <Undo />
          </MDButton>
          <MDButton
            onClick={() => deleteArticle(article._id)}
            size="small"
            style={{
              backgroundColor: "indianred",
              color: "white",
              pointerEvents: updating ? "initial" : "none",
              margin: "5px 7px",
            }}
          >
            {<Delete />}
          </MDButton>
        </Typography>
      </MDBox>
      <Typography style={{ fontSize: "13pt" }}>Content</Typography>
      <MDBox display="flex" flexWrap="wrap">
        {keys.map((key, idx) => {
          if (key === "image") {
            return (
              <MDBox key={idx} width="100%">
                {update ? (
                  <MDBox width="100%" pt={2} textAlign="center" display="flex">
                    <img
                      src={isFile(image) ? getURL(image) : image}
                      style={{ maxWidth: "100px" }}
                      height="80px"
                    />
                    <MDBox width="100%" pl={2} textAlign="left">
                      <Typography variant="body" style={{ width: "100%" }}>
                        <Cancel
                          onClick={() => removeImage()}
                          size="small"
                          style={{
                            color: "indianred",
                            pointerEvents: updating ? "initial" : "none",
                            marginRight: "15px",
                          }}
                        />
                      </Typography>
                      <MDInput
                        size="small"
                        type="file"
                        display="none"
                        id="icon-select"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                      />
                    </MDBox>
                  </MDBox>
                ) : (
                  <img
                    src={isFile(image) ? getURL(image) : image}
                    key={idx}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      pointerEvents: updating ? "initial" : "none",
                    }}
                  />
                )}
              </MDBox>
            );
          }
          if (key === "values" && mArticle.type === "counter") {
            return (
              <MDBox key={idx} width="100%">
                {mArticle.content[key].map((v, dx) => {
                  return (
                    <MDBox key={dx + "-" + idx} width="100%">
                      {update && dx === 0 && (
                        <MDBox
                          width="100%"
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          style={{
                            backgroundColor: "#dedede",
                            borderRadius: "7px",
                            padding: "7px",
                          }}
                        >
                          <Typography
                            variant="body"
                            style={{
                              fontSize: "13pt",
                              fontWeight: "bold",
                              width: "100%",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            New Counter Item
                          </Typography>
                          <MDInput
                            value={newCounterItem.title ? newCounterItem.title : ""}
                            style={{ width: "100%", margin: "10px 0", padding: "5px" }}
                            onChange={(e) =>
                              setNewCounterItem({ ...newCounterItem, title: e.target.value })
                            }
                            label="Title"
                          />
                          <MDBox display={"flex"} margin={"5px 0"} alignItems="center">
                            <MDInput
                              value={newCounterItem.prefix ? newCounterItem.prefix : ""}
                              style={{ width: "20%", padding: "5px" }}
                              onChange={(e) =>
                                setNewCounterItem({ ...newCounterItem, prefix: e.target.value })
                              }
                              label="Prefix"
                            />
                            <MDInput
                              type="number"
                              value={newCounterItem.value ? newCounterItem.value : ""}
                              style={{ width: "50%", padding: "5px" }}
                              onChange={(e) =>
                                setNewCounterItem({ ...newCounterItem, value: e.target.value })
                              }
                              label="Value"
                            />
                            <MDInput
                              value={newCounterItem.suffix ? newCounterItem.suffix : ""}
                              style={{ width: "20%", padding: "5px" }}
                              onChange={(e) =>
                                setNewCounterItem({ ...newCounterItem, suffix: e.target.value })
                              }
                              label="Suffix"
                            />
                            <MDButton
                              onClick={() => addCounterItem()}
                              size="small"
                              style={{
                                backgroundColor: "dodgerblue",
                                color: "white",
                                pointerEvents: updating ? "initial" : "none",
                                // marginRight: "15px",
                              }}
                            >
                              <Add />
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      )}
                      <Typography
                        style={{
                          margin: "10px 0",
                          backgroundColor: "#cecece",
                          borderRadius: "7px",
                          padding: "7px",
                          fontSize: "13pt",
                        }}
                      >
                        {(v.prefix ? v.prefix + " " : "") + v.value + (v.suffix ? v.suffix : "")}:{" "}
                        {v.title + " "}
                        {update && (
                          <Cancel
                            onClick={() => removeCounterItem(dx)}
                            size="small"
                            style={{
                              color: "indianred",
                              pointerEvents: updating ? "initial" : "none",
                              // marginRight: "15px",
                            }}
                          />
                        )}
                      </Typography>
                    </MDBox>
                  );
                })}
              </MDBox>
            );
          }
          if (key === "values" && mArticle.type === "carousel") {
            return (
              <MDBox key={idx} width="100%">
                {mArticle.content[key].map((v, dx) => {
                  return (
                    <MDBox key={dx + "-" + idx} width="100%">
                      {update && dx === 0 && (
                        <MDBox
                          width="100%"
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          style={{
                            backgroundColor: "#dedede",
                            borderRadius: "7px",
                            padding: "7px",
                          }}
                        >
                          <MDBox
                            width="100%"
                            pt={2}
                            textAlign="center"
                            display="flex"
                            flexWrap="wrap"
                          >
                            <Typography
                              variant="body"
                              style={{ fontSize: "13pt", fontWeight: "bold", width: "100%" }}
                            >
                              New Carousel Item
                            </Typography>
                            <img
                              src={
                                isFile(newCarouselItem.image)
                                  ? getURL(newCarouselItem.image)
                                  : newCarouselItem.image
                              }
                              style={{ maxWidth: "100px" }}
                              height="80px"
                            />
                            <MDBox width="100%" pl={2} textAlign="left">
                              <Typography variant="body">
                                <Cancel
                                  onClick={() => delete newCarouselItem.image}
                                  size="small"
                                  style={{
                                    color: "indianred",
                                    pointerEvents: updating ? "initial" : "none",
                                    marginRight: "15px",
                                  }}
                                />
                              </Typography>
                              <MDInput
                                size="small"
                                type="file"
                                display="none"
                                id="icon-select"
                                onChange={(e) => {
                                  setNewCarouselItem({
                                    ...newCarouselItem,
                                    image: e.target.files[0],
                                  });
                                }}
                              />
                            </MDBox>
                          </MDBox>

                          <MDInput
                            value={newCarouselItem.title ? newCarouselItem.title : ""}
                            style={{ width: "100%", margin: "5px 0", padding: "5px" }}
                            onChange={(e) =>
                              setNewCarouselItem({ ...newCarouselItem, title: e.target.value })
                            }
                            label="Title"
                            multiline={true}
                          />
                          <MDInput
                            value={newCarouselItem.description ? newCarouselItem.description : ""}
                            style={{ width: "100%", margin: "5px 0", padding: "5px" }}
                            onChange={(e) =>
                              setNewCarouselItem({
                                ...newCarouselItem,
                                description: e.target.value,
                              })
                            }
                            label="description"
                            multiline={true}
                          />

                          <MDButton
                            onClick={() => addCarouselItem()}
                            size="small"
                            style={{
                              backgroundColor: "dodgerblue",
                              color: "white",
                              pointerEvents: updating ? "initial" : "none",
                              marginRight: "15px",
                            }}
                          >
                            {addingCarousel ? "..." : <Add />}
                          </MDButton>
                        </MDBox>
                      )}
                      <MDBox
                        display="flex"
                        width="100%"
                        style={{
                          margin: "10px 0",
                          backgroundColor: "#cecece",
                          borderRadius: "7px",
                          padding: "7px",
                        }}
                      >
                        {v.image && (
                          <img
                            src={v.image}
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                          />
                        )}
                        <MDBox width="100%">
                          {v.title && (
                            <Typography
                              style={{
                                padding: "7px",
                                width: "100%",
                                fontSize: "13pt",
                              }}
                            >
                              <b>Title: </b>
                              {" " + v.title + " "}
                            </Typography>
                          )}
                          {v.description && (
                            <Typography
                              style={{
                                padding: "7px",
                                width: "100%",
                                fontSize: "13pt",
                              }}
                            >
                              <b>Description: </b>
                              {" " + v.description + " "}
                            </Typography>
                          )}
                          {update && (
                            <Cancel
                              onClick={() => removeCarouselItem(dx)}
                              size="small"
                              style={{
                                color: "indianred",
                                pointerEvents: updating ? "initial" : "none",
                                marginRight: "15px",
                              }}
                            />
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  );
                })}
              </MDBox>
            );
          }
          if (key === "items") {
            return (
              <MDBox key={idx} width="100%">
                {mArticle.content[key].map((v, dx) => {
                  return (
                    <MDBox key={dx + "-" + idx} width="100%">
                      {update && dx === 0 && (
                        <MDBox
                          display="flex"
                          alignItems="center"
                          style={{
                            backgroundColor: "#dedede",
                            borderRadius: "7px",
                            padding: "7px",
                          }}
                          flexWrap={"wrap"}
                        >
                          <Typography
                            variant="body"
                            style={{
                              fontSize: "13pt",
                              fontWeight: "bold",
                              width: "100%",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            New List Item
                          </Typography>
                          <MDBox display="flex" width="100%" alignItems="center">
                            <MDInput
                              value={newListItem}
                              style={{ width: "100%", margin: "10px", padding: "5px" }}
                              onChange={(e) => setNewListItem(e.target.value)}
                              label="Add List Item"
                            />
                            <MDButton
                              onClick={() => addListItem()}
                              size="small"
                              style={{
                                backgroundColor: "dodgerblue",
                                color: "white",
                                pointerEvents: updating ? "initial" : "none",
                                marginRight: "15px",
                              }}
                            >
                              <Add />
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      )}
                      <Typography
                        marginLeft={"15px"}
                        style={{
                          margin: "10px 0",
                          backgroundColor: "#cecece",
                          borderRadius: "7px",
                          padding: "7px",
                          fontSize: "13pt",
                        }}
                      >
                        {v + " "}
                        {update && (
                          <Cancel
                            onClick={() => removeListItem(dx)}
                            size="small"
                            style={{
                              color: "indianred",
                              pointerEvents: updating ? "initial" : "none",
                              marginRight: "15px",
                            }}
                          />
                        )}
                      </Typography>
                    </MDBox>
                  );
                })}
              </MDBox>
            );
          }
          if (key === "videoId") {
            return (
              <MDBox key={idx} width="100%">
                {update ? (
                  <Typography key={idx} width="100%">
                    <MDInput
                      value={mArticle.content[key]}
                      style={{ width: "100%", margin: "5px 0", padding: "5px" }}
                      onChange={(e) => updateArticle(key, e.target.value)}
                      label={key}
                    />
                  </Typography>
                ) : (
                  <Typography key={idx} style={{ fontSize: "13pt" }}>
                    <b>{camelCase(key)}</b>: {mArticle.content[key]}
                  </Typography>
                )}
                {article.content[key] && (
                  <MDBox>
                    <iframe
                      style={{ aspectRatio: "3/2", marginBottom: "20px" }}
                      width="100%"
                      src={"https://www.youtube.com/embed/" + article.content[key]}
                    ></iframe>
                  </MDBox>
                )}
              </MDBox>
            );
          }
          return (
            <MDBox key={idx} width="100%">
              {update ? (
                <Typography key={idx} style={{ width: "100%" }}>
                  <TextField
                    value={mArticle.content[key]}
                    style={{ width: "100%", margin: "10px 0", padding: "5px", fontSize: "13pt" }}
                    onChange={(e) => updateArticle(key, e.target.value)}
                    label={camelCase(key)}
                    multiline={true}
                  />
                </Typography>
              ) : (
                <Typography key={idx} style={{ fontSize: "13pt" }}>
                  <b>{camelCase(key)}</b>: {mArticle.content[key]}
                </Typography>
              )}
            </MDBox>
          );
        })}
      </MDBox>
    </MDBox>
  );
}
