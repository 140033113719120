import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const initialState = {
  statistics: [],
  visits: {
    labels: ["Loading"],
    datasets: {
      label: "Website Visits",
      data: [0],
    },
  },
  growthData: {
    labels: ["Loading"],
    datasets: {
      label: "Users",
      data: [0],
    },
  },
  revenueData: {
    labels: ["Loading"],
    datasets: {
      label: "Revenue",
      data: [0],
    },
  },
  error: "",
};

export const getStatistics = createAsyncThunk(
  "dashboard/statistics",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.get("/statistics");
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatistics.pending, (state) => {
      state.isLoading = true;
      state.error = "";
    });
    builder.addCase(getStatistics.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = "";
      if (action.payload.success) {
        state.statistics = action.payload.data;
        state.growthData = action.payload.data.growth;
        state.visits = action.payload.data.visits_data;
        state.revenueData = action.payload.data.revenue_data;
      }
    });
    builder.addCase(getStatistics.rejected, (state) => {
      state.isLoading = false;
      state.error = "Error Occurred";
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
