import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {
  Autocomplete,
  FilledInput,
  Icon,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import MDSnackbar from "components/MDSnackbar";
import { getDeliveries } from "redux/features/deliverySlice";
import { deleteOption } from "redux/routes/delivery";
// import { deleteOption } from "redux/features/deliverySlice";

function DisplayDeliveries({ locations, setLocations }) {
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const [deleting, setDeleting] = useState("");

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getDeliveries());
  // }, [dispatch]);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={"Deliveries"}
      content="Succefully Deleted Delviery Location"
      dateTime="just now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bggreen="true"
    />
  );

  const handleDeleteLocation = async (id) => {
    setDeleting(id);
    let res = await deleteOption({ id });
    if (res.success) {
      openSuccessSB();
      setLocations(res.data);
    } else {
      if (res.message) {
        setErr(res.message);
      } else {
        setErr("Failed to delete location");
      }
      openErrorSB();
    }
    setDeleting("");
  };
  let [err, setErr] = useState("");
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={"Deliveries"}
      content={err}
      dateTime="1 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgindianred="true"
    />
  );
  return (
    <MDBox
      // minWidth="320px"
      // width="47%"
      borderRadius="10px"
      // display="flex"
      // flexDirection="column"
      // justify="center"
      // alignItems="center"
      className="profile"
      // m={2}
      p={1}
      style={{ boxShadow: "0 0 5px grey", maxHeight: "600px", overflowY: "auto" }}
    >
      <MDBox display="flex" width="100%" justifyContent="space-evenly" flexWrap="wrap">
        <MDTypography width="100%">Delivery Locations</MDTypography>
      </MDBox>
      <MDBox
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="start"
        style={{ overflowY: "auto" }}
      >
        {locations.map((location) => (
          <MDBox
            key={location._id}
            m="5px"
            style={{
              width: "95%",
              border: "solid 1px grey",
              padding: "5px",
              borderRadius: "5px",
              backgroundColor: "#eee",
            }}
          >
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <Typography fontSize="11pt">
                {location.county +
                  "/" +
                  location.subcounty +
                  " Ksh. " +
                  location.price +
                  " (" +
                  location.time +
                  " hrs) " +
                  (location.payOnDelivery ? "* Pay on delivery" : "")}
              </Typography>
              <IconButton
                style={{ color: "indianred" }}
                onClick={() => handleDeleteLocation(location._id)}
              >
                {deleting === location._id ? "..." : <Icon>delete</Icon>}
              </IconButton>
            </MDBox>
            <MDBox>
              <Typography fontSize="10pt">{location.description}</Typography>
            </MDBox>
          </MDBox>
        ))}
      </MDBox>
      {renderSuccessSB}
      {renderErrorSB}
    </MDBox>
  );
}

export default DisplayDeliveries;
