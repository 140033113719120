import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const initialState = {
  profile: {},
  mpesaTill: {},
  mpesaPaybill: {},
  account: {},
  error: "",
  profileLoading: false,
  tillLoading: false,
  paybillLoading: false,
};

export const fetchFullProfile = createAsyncThunk(
  "profile/fetchFullProfile",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.get("/profile");
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchAccountProfile = createAsyncThunk(
  "profile/account",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.get("/profile/account");
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.post("/update/profile", payload);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateMpesaTill = createAsyncThunk(
  "profile/updateMpesaTill",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.post("/update/mpesatill", payload);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateMpesaPaybill = createAsyncThunk(
  "profile/updateMpesaPaybill",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.post("/update/mpesapaybill", payload);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteMpesaTill = createAsyncThunk(
  "profile/deleteMpesaTill",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.post("/delete/mpesatill", payload);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteMpesaPaybill = createAsyncThunk(
  "profile/deleteMpesaPaybill",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.post("/delete/mpesapaybill", payload);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFullProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFullProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profile = action.payload.data.data.profile;
      state.mpesaTill = action.payload.data.data.MPesaTill
        ? action.payload.data.data.MPesaTill
        : {};

      state.mpesaPaybill = action.payload.data.data.MPesaPaybill
        ? action.payload.data.data.MPesaPaybill
        : {};
    });
    builder.addCase(fetchFullProfile.rejected, (state) => {
      state.isLoading = false;
      state.error = "Error Occured";
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.profileLoading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.profileLoading = false;
      state.profile = action.payload.data.data;
    });
    builder.addCase(updateProfile.rejected, (state) => {
      state.profileLoading = false;
      state.error = "Error Occured";
    }),
      builder.addCase(updateMpesaTill.pending, (state) => {
        state.tillLoading = true;
      }),
      builder.addCase(updateMpesaTill.fulfilled, (state, action) => {
        state.tillLoading = false;
        state.mpesaTill = action.payload.data.data;
      }),
      builder.addCase(updateMpesaTill.rejected, (state) => {
        state.tillLoading = false;
        state.error = "Error Occured";
      }),
      builder.addCase(updateMpesaPaybill.pending, (state) => {
        state.paybillLoading = true;
      }),
      builder.addCase(updateMpesaPaybill.fulfilled, (state, action) => {
        state.paybillLoading = false;
        state.mpesaPaybill = action.payload.data.data;
      }),
      builder.addCase(updateMpesaPaybill.rejected, (state) => {
        state.paybillLoading = false;
        state.error = "Error Occured";
      }),
      builder.addCase(deleteMpesaTill.pending, (state) => {
        state.tillLoading = true;
      }),
      builder.addCase(deleteMpesaTill.fulfilled, (state, action) => {
        state.tillLoading = false;
        state.mpesaTill = {};
      }),
      builder.addCase(deleteMpesaTill.rejected, (state) => {
        state.tillLoading = false;
        state.error = "Error Occured";
      }),
      builder.addCase(deleteMpesaPaybill.pending, (state) => {
        state.paybillLoading = true;
      }),
      builder.addCase(deleteMpesaPaybill.fulfilled, (state, action) => {
        state.paybillLoading = false;
        state.mpesaPaybill = {};
      }),
      builder.addCase(deleteMpesaPaybill.rejected, (state) => {
        state.paybillLoading = false;
        state.error = "Error Occured";
      });
    builder.addCase(fetchAccountProfile.fulfilled, (state, action) => {
      state.account = action.payload.data.data;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = profileSlice.actions;

export default profileSlice.reducer;
