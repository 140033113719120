import axios from "../axios.config";

export const updateProduct = async (payload) => {
  try {
    let res = await axios.post("/update/product", payload);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const fetchProduct = async (_, {}) => {
  try {
    return await axios.get("");
  } catch (err) {
    return err;
  }
};

export const deleteProduct = async (payload) => {
  try {
    return await axios.post("/delete/product/" + payload._id);
  } catch (err) {
    return err;
  }
};

export const addFeature = async (payload) => {
  try {
    return await axios.post("/add-feature/", payload);
  } catch (err) {
    return err;
  }
};
