import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const initialState = {
  templates: [],
  selectedTemplate: "",
  selectedTheme: "",
  holidayTheme: false,
};

export const getTemplates = createAsyncThunk("templates/get", async (data, { rejectWithValue }) => {
  try {
    let res = await axios.get("/templates");
    return res.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const setTemplate = createAsyncThunk("templates/set", async (data, { rejectWithValue }) => {
  try {
    let res = await axios.post("/template", data);
    return res.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const setHolidayTheme = createAsyncThunk(
  "holidayTheme/set",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.post("/holidayTheme", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.templates = action.payload.data.templates;
        state.selectedTemplate = action.payload.data.selectedTemplate;
        state.selectedTheme = action.payload.data.selectedTheme;
        state.holidayTheme = action.payload.data.holidayTheme;
      }
    });

    builder.addCase(setTemplate.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.selectedTemplate = action.payload.data.selectedTemplate;
        state.selectedTheme = action.payload.data.selectedTheme;
      }
    });

    builder.addCase(setHolidayTheme.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.holidayTheme = action.payload.data.holidayTheme;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = templatesSlice.actions;

export default templatesSlice.reducer;
