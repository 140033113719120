import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "./features/profileSlice";
import productsReducer from "./features/productsSlice";
import productReducer from "./features/productSlice";
import authReducer from "./features/authSlice";
import ordersReducer from "./features/ordersSlice";
import deliveryReducer from "./features/deliverySlice";
import dashboardReducer from "./features/dashboardSlice";
import promotionsReducer from "./features/promotionsSlice";
import billingReducer from "./features/billingSlice";
import templatesReducer from "./features/templatesSlice";
import policiesReducer from "./features/policiesSlice";

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    products: productsReducer,
    product: productReducer,
    auth: authReducer,
    orders: ordersReducer,
    delivery: deliveryReducer,
    dashboard: dashboardReducer,
    promotions: promotionsReducer,
    billing: billingReducer,
    templates: templatesReducer,
    policies: policiesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "auth/login/fulfilled",
          "auth/login/rejected",
          "profile/fetchFullProfile/fulfilled",
          "profile/fetchFullProfile/rejected",
          "profile/updateProfile/fulfilled",
          "profile/updateProfile/rejected",
          "profile/updateMpesaTill/fulfilled",
          "profile/updateMpesaTill/rejected",
          "profile/updateMpesaPaybill/fulfilled",
          "profile/updateMpesaPaybill/rejected",
          "products/getGenderizable/fulfilled",
          "products/getGenderizable/rejected",
          "products/getCategories/fulfilled",
          "products/getCategories/rejected",
          "products/getNextBatch/fulfilled",
          "products/getNextBatch/rejected",
          "products/addProduct/fulfilled",
          "products/addProduct/rejected",
          "products/getWearables/fulfilled",
          "products/getWearables/rejected",
          "profile/deleteMpesaTill/fulfilled",
          "profile/deleteMpesaTill/rejected",
          "profile/deleteMpesaPaybill/fulfilled",
          "profile/deleteMpesaPaybill/rejected",
          "product/deleteProduct/fulfilled",
          "product/deleteProduct/rejected",
          "templates/get/fulfilled",
          "templates/get/rejected",
          "templates/set/fulfilled",
          "templates/set/rejected",
          "policies/fetch/fulfilled",
          "policies/fetch/rejected",
          "policies/update/fulfilled",
          "policies/update/rejected",
          "profile/account/fulfilled",
          "profile/account/rejected",
        ],
      },
    }),
});
