import axios from "../axios.config";

export const getTemplates = async (data) => {
  try {
    let res = await axios.get("/templates");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const setTemplate = async (data) => {
  try {
    let res = await axios.post("/template", data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const setHolidayTheme = async (data) => {
  try {
    let res = await axios.post("/holidayTheme", data);
    return res.data;
  } catch (err) {
    return err;
  }
};
