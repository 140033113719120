import axios from "../axios.config";

export const addOption = async (data) => {
  try {
    let res = await axios.post("/delivery/add", data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deleteOption = async (data) => {
  try {
    let res = await axios.post("/delivery/delete", data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getDeliveries = async (data) => {
  try {
    let res = await axios.get("/delivery");
    return res.data;
  } catch (err) {
    return err;
  }
};
