/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Modal } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import axios from "redux/axios.config";
import { useGlobalContext } from "context/context";
import { payInvoice } from "redux/routes/billing";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Invoices({ invoices, setInvoices, setLastPayment }) {
  let { profile } = useGlobalContext();

  const convertDate = (date) => {
    let dt = new Date(date);
    let nd = dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
    return nd;
  };
  const [code, setCode] = useState("");
  const [paymentPhone, setPaymentPhone] = useState(profile.phone);

  const submitPayment = async (amt, id) => {
    setPayError("");
    if (code === "") {
      setPayError("Please provide the payment code");
      return;
    }
    if (submitting) return;
    setSubmitting(true);
    let res = await payInvoice({ amount: amt, id, code });

    if (res.success) {
      setLastPayment(res.data.lastPayment);
      setInvoices(res.data.invoices.reverse());
      handleClose();
    } else if (res.message) {
      setPayError(res.message);
    } else {
      setPayError("Error Occured. Kindly refresh page and try again");
    }
    setSubmitting(false);
  };

  const [paymentStatus, setPaymentStatus] = useState("Waiting");
  const checkForPayment = async (stkData) => {
    let id1 = setInterval(() => {
      if (paymentStatus.length === 19) setPaymentStatus("Waiting for payment.");
      if (paymentStatus.length === 20) setPaymentStatus("Waiting for payment..");
      if (paymentStatus.length === 21) setPaymentStatus("Waiting for payment...");
      if (paymentStatus.length === 22) setPaymentStatus("Waiting for payment");
    }, 1000);

    const queryPayment = async () => {
      let res = await axios.post("/query-payment", stkData);
      res = res.data;
      if (res.success) {
        if (res.data.paid) {
          clearInterval(id1);
          setPaymentStatus(
            "We have successfully received your payment. Kindly refresh the page to update your profile"
          );
        } else {
          setPayError("Unable to complete transaction. Try again?");
          setSentStk(false);
        }

        return true;
      } else {
        return false;
      }
    };
    let intervalId = setInterval(async () => {
      let res = await queryPayment();
      if (res) {
        clearInterval(intervalId);
      }
    }, 2000);

    // if(res.data)
  };

  const convertDatePaid = (date) => {
    if (date[0] === "~") {
      let parts = date.split("~");
      let id = parts[1];
      let amt = parts[2];
      let invNum = parts[3];
      let r = (
        <>
          <MDButton
            variant="gradient"
            onClick={() => handleOpen(id)}
            style={{ color: "white", backgroundColor: "green" }}
          >
            Pay Now
          </MDButton>
          <Modal
            open={open === id}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDBox sx={style}>
              <MDTypography id="modal-modal-title" variant="h6" component="h2">
                {manualPayment ? "Payment Procedure" : "Lipa na MPesa"}
              </MDTypography>

              <MDTypography
                id="modal-modal-description"
                variant="text"
                sx={{ mt: 2, fontSize: "11pt" }}
              >
                {manualPayment ? (
                  <>
                    1. Go to MPesa Menu
                    <br />
                    2. Select Lipa na MPesa <br />
                    3. Select Pay Bill <br />
                    4. Enter business number - 4123793
                    <br />
                    5. Enter account number - {invNum}
                    <br />
                    6. Enter amount Ksh.{amt}
                    <br />
                    7. Enter MPesa Pin <br />
                    Business Name: Bunika Solutions
                    <br />I Have Paid:{" "}
                    <MDInput
                      label="MPesa Code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <MDButton
                      size="small"
                      style={{ mt: 2, backgroundColor: "green", color: "white" }}
                      onClick={() => submitPayment(amt, id)}
                    >
                      {submitting ? "Submitting" : "Submit"}
                    </MDButton>
                  </>
                ) : (
                  <>
                    <MDTypography sx={{ fontSize: "11pt" }}>
                      You will receive a prompt on your phone to complete the payment
                      <br />
                      <br />
                      Business Name: Bunika Solutions
                      <br />
                      Account Number: {invNum}
                      <br />
                      Amount Ksh.{amt}
                    </MDTypography>

                    <MDInput
                      label="Phone Number"
                      value={paymentPhone}
                      onChange={(e) => setPaymentPhone(e.target.value)}
                      style={{ width: "100%", margin: "20px 0" }}
                    />

                    <br />
                    {sentStk ? (
                      <span>{paymentStatus}</span>
                    ) : (
                      <MDButton
                        size="small"
                        style={{
                          mt: 2,
                          backgroundColor: "green",
                          color: "white",
                          margin: "0 auto",
                        }}
                        onClick={() => initPayment(invNum, amt, id)}
                      >
                        {sendingStk ? "Submitting..." : "Proceed"}
                      </MDButton>
                    )}
                  </>
                )}

                <br />
                {payError && <span style={{ color: "indianred" }}>{payError}</span>}
              </MDTypography>
            </MDBox>
          </Modal>
        </>
      );
      return r;
    } else if (date === "Processing") {
      return date;
    } else {
      return convertDate(date);
    }
  };

  const [openDes, setOpenDes] = useState("-");
  const handleOpenDes = (id) => {
    setOpenDes(id);
  };
  const handleCloseDes = () => setOpenDes("-");
  const columns = [
    { field: "invNum", headerName: "Invoice Num", width: 130 },
    {
      field: "dateGenerated",
      headerName: "Date Generated",
      width: 125,
      renderCell: (params) => convertDate(params.value),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 120,
      renderCell: (params) => convertDate(params.value),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "description",
      headerName: "Purpose",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <>
          <p
            onClick={() => {
              handleOpenDes(params.id);
              initPayment();
            }}
            style={{ cursor: "pointer" }}
          >
            {params.value}
          </p>
          <Modal
            open={openDes === params.id}
            onClose={handleCloseDes}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDBox sx={style}>
              <MDTypography id="modal-modal-title" variant="h6" component="h2">
                Purpose
              </MDTypography>
              <MDTypography style={{ fontSize: "11pt" }}>{params.value}</MDTypography>
            </MDBox>
          </Modal>
        </>
      ),
    },
    {
      field: "datePaid",
      headerName: "Date Paid",
      width: 130,
      renderCell: (params) => convertDatePaid(params.value),
    },
  ];
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState("-");
  const handleOpen = (id) => {
    setOpen(id);
    setPayError("");
    setCode("");
  };
  const handleClose = () => setOpen("-");
  useEffect(() => {
    let r = invoices.map((i, index) => {
      let inv = { ...i };

      inv.id = index;
      if (inv.datePaid === "-") {
        inv.datePaid = "~" + inv._id + "~" + inv.amount + "~" + inv.invNum;
      } else if (inv.status === "Processing") {
        inv.datePaid = "Processing";
      }
      return inv;
    });
    setRows(r);
  }, [invoices]);
  const [payError, setPayError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [sendingStk, setSendingStk] = useState(false);
  const [sentStk, setSentStk] = useState(false);
  const [manualPayment, setManualPayment] = useState(false);

  const initPayment = async (invNum, amt, iid) => {
    setPayError("");
    if (sendingStk) return;
    setSendingStk(true);
    let res = await axios.post("/initiate-payment", {
      phone: paymentPhone,
      amount: amt,
      invoiceNum: invNum,
      description: iid,
      iid: iid,
    });
    res = res.data;
    if (res.success) {
      setSentStk(true);
      res.data.iid = iid;
      checkForPayment(res.data);
    } else {
      setManualPayment(true);
      setPayError("Unable to initiate payment. Kindly use this procedure to submit the payment");
    }
    setSendingStk(false);
  };

  return (
    <MDBox>
      <MDBox px={4}>
        <MDTypography variant="h3">Invoices</MDTypography>
      </MDBox>
      <MDBox px={4} py={2}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
        />
      </MDBox>
    </MDBox>
  );
}

export default Invoices;
