import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const initialState = {
  products: [],
  batchNo: 0,
  error: "",
  categories: [],
  genderizable: [],
  wearables: [],
  isAdding: false,
  getNextBatchError: false,
  uploadProgress: "10",
};

export const addProduct = createAsyncThunk(
  "products/addProduct",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      let state = getState();
      const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        dispatch(setUploadProgress(percent));

        if (percent < 100) {
          // console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
        }
      };

      let ret = await axios.post("/product", payload, { onUploadProgress });
      return ret;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCategories = createAsyncThunk(
  "products/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      let res = await axios.get("/categories");
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getGenderizable = createAsyncThunk(
  "products/getGenderizable",
  async (_, { rejectWithValue }) => {
    try {
      let res = await axios.get("/genderizable");
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getWearables = createAsyncThunk(
  "products/getWearables",
  async (_, { rejectWithValue }) => {
    try {
      let res = await axios.get("/wearables");
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNextBatch = createAsyncThunk(
  "products/getNextBatch",
  async (payload, { getState, rejectWithValue }) => {
    const state = getState();
    try {
      return await axios.get("/products/full/" + state.products.batchNo);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    filterProducts: (state, action) => {},
    setUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNextBatch.pending, (state) => {
      state.getNextBatchError = false;
      state.isLoading = true;
    }),
      builder.addCase(getNextBatch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.products = [...state.products, ...action.payload.data.data];
        state.batchNo += 1;
      }),
      builder.addCase(getNextBatch.rejected, (state, action) => {
        state.isLoading = false;
        state.getNextBatchError = true;
        state.error = "Error Occured";
      }),
      builder.addCase(addProduct.pending, (state) => {
        state.isAdding = true;
      }),
      builder.addCase(addProduct.fulfilled, (state, action) => {
        state.isAdding = false;
        state.products = [...state.products, action.payload.data.data];
      });
    builder.addCase(addProduct.rejected, (state, action) => {
      state.isAdding = false;
      state.error = "Error Occured";
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      if (action.payload.data.success) state.categories = action.payload.data.data;
    });
    builder.addCase(getGenderizable.fulfilled, (state, action) => {
      if (action.payload.data.success) state.genderizable = action.payload.data.data;
    });
    builder.addCase(getWearables.fulfilled, (state, action) => {
      if (action.payload.data.success) state.wearables = action.payload.data.data;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setUploadProgress } = productsSlice.actions;

export default productsSlice.reducer;
