import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const initialState = {
  promotions: [],
};

export const getPromotions = createAsyncThunk(
  "promotions/get",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.get("/promotions");
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addPromotion = createAsyncThunk(
  "promotions/add",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.post("/promotions/add", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deletePromotion = createAsyncThunk(
  "promotions/delete",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.post("/promotions/delete", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPromotions.fulfilled, (state, action) => {
      state.promotions = action.payload.data;
    });
    builder.addCase(addPromotion.fulfilled, (state, action) => {
      state.promotions = action.payload.data;
    });
    builder.addCase(deletePromotion.fulfilled, (state, action) => {
      state.promotions = action.payload.data;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = promotionsSlice.actions;

export default promotionsSlice.reducer;
