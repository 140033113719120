import React, { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import { useDispatch, useSelector } from "react-redux";
import { TextField, Tooltip, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import MDModal from "components/MDModal/MDModal";
import axios from "../../../redux/axios.config";
import { setStatus } from "redux/routes/orders";
// import { setStatus } from "redux/features/ordersSlice";

function Order({ order, modifiable, updateOrders }) {
  const [downloading, setDownloading] = useState("");
  const [updating, setUpdating] = useState("");

  const handleSetStatus = async (oid, uid, opid, status) => {
    if (updating) return;
    setUpdating(status);
    let res = await setStatus({ oid, uid, opid, status });
    if (res.success) {
      updateOrders(res.data);
    }
    setUpdating("");
  };

  const downloadReceipt = async (oid, uid) => {
    setDownloading(oid);
    await axios.get("/receipt/" + oid + "/" + uid);
    setDownloading("");
  };

  return (
    <MDBox
      m="10px"
      p="8px"
      style={{
        justifyContent: "space-evenly",
        boxShadow: "0 0 2px grey",
        borderRadius: "7px",
        backgroundColor: "#ddd",
      }}
    >
      <MDBox
        m="10px"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          borderRadius: "7px",
        }}
      >
        <MDBox style={{ width: "300px" }} my="10px">
          <MDBox>
            <TextField
              label="Name"
              value={order.name}
              style={{ width: "280px", pointerEvents: "none", margin: "7px 0" }}
            />
          </MDBox>
          <MDBox>
            <TextField
              label="Phone"
              value={order.phone}
              style={{ width: "280px", pointerEvents: "none", margin: "7px 0" }}
              m="10px"
            />
          </MDBox>
          <MDBox>
            <TextField
              label="Email"
              value={order.email}
              style={{ width: "280px", pointerEvents: "none", margin: "7px 0" }}
              m="10px"
            />
          </MDBox>
          <MDBox>
            <TextField
              label="Date"
              value={order.date}
              style={{ width: "280px", pointerEvents: "none", margin: "7px 0" }}
              m="10px"
            />
          </MDBox>

          <Typography variant="h5">
            {"Ksh. " + order.total + " " + order.paymentMode + " "}
            <span style={{ color: "orange" }}>{order.paymentCode}</span>
          </Typography>
        </MDBox>
        <MDBox style={{ width: "300px" }} my="10px" p="10px">
          <MDBox>
            <TextField
              label="County"
              value={order.county}
              style={{ width: "280px", pointerEvents: "none", margin: "7px 0" }}
              m="10px"
            />
          </MDBox>
          <MDBox>
            <TextField
              label="Sub-County"
              value={order.subcounty}
              style={{ width: "280px", pointerEvents: "none", margin: "7px 0" }}
              m="10px"
            />
          </MDBox>
          <MDBox>
            <TextField
              label="Courier"
              value={order.courier}
              style={{ width: "280px", pointerEvents: "none", margin: "7px 0" }}
              m="10px"
            />
          </MDBox>
          <MDBox>
            <Typography variant="caption">Description</Typography>
            <br />
            <Typography variant="body">{order.pickupDescription}</Typography>
          </MDBox>
          <MDBox>
            <MDButton
              variant="contained"
              style={{ cursor: "pointer", backgroundColor: "green", color: "white" }}
              onClick={() => downloadReceipt(order.oid, order.uid)}
            >
              {downloading === order.oid ? "Downloading..." : "Download Receipt"}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        style={{
          border: "solid 1px grey",
          borderRadius: "7px",
          backgroundColor: "#eee",
          maxHeight: "250px",
          overflowY: "scroll",
        }}
        p="8px"
        m="10px"
      >
        {order.products.map((item, index) => (
          <div key={index}>
            <div
              style={{
                borderBottom: "solid 1px #bbb",
                marginBottom: "10px",
                paddingBottom: "5px",
              }}
            >
              <MDBox
                className="order"
                style={{ display: "flex", flexWrap: "wrap", padding: "10px 0" }}
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{
                      height: "125px",
                      borderRadius: "7px",
                      aspectRatio: "3/2",
                    }}
                    src={item.img}
                    alt={item.name}
                  ></img>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <MDModal
                      name={
                        <Tooltip title="Open Description">
                          <Typography variant="h4">
                            <p className="" style={{ cursor: "pointer" }}>
                              {item.name}
                            </p>
                          </Typography>
                        </Tooltip>
                      }
                      title={item.name}
                      content={item.description}
                      styles={{ textTransform: "initial" }}
                    />
                  </div>
                </div>
                <MDBox style={{}} ml="10px">
                  <Typography className="" style={{ fontSize: "10pt" }}>
                    Selected size: <span className="ml-2">{item.size}</span>
                  </Typography>
                  <Typography className="" style={{ fontSize: "10pt" }}>
                    Selected color:{" "}
                    {item.color && item.color !== "-" ? (
                      <span
                        style={{
                          backgroundColor: item.color,
                          borderRadius: "50%",
                          display: "inline-block",
                          padding: "7px",
                        }}
                        ml="7px"
                      ></span>
                    ) : (
                      "-"
                    )}
                  </Typography>
                  <Typography className="" style={{ fontSize: "10pt" }}>
                    Amount:{" "}
                    <span className="" ml="6px">
                      {item.amount}
                    </span>
                  </Typography>
                  <Typography className="" style={{ fontSize: "10pt" }}>
                    Single Item Price: <span className="ml-2">Ksh.{" " + item.price}</span>
                  </Typography>
                  <Typography className="" variant="subtitle" style={{ fontSize: "10pt" }}>
                    Total Price: <span className="ml-2">Ksh.{" " + item.price * item.amount}</span>
                  </Typography>
                  <MDBox className="pt-4">
                    <Tooltip content="Status" placement="bottom">
                      <Typography variant="body" style={{ fontSize: "11pt" }}>
                        Status:{" " + item.status}
                      </Typography>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox width="100%">
                {modifiable && (
                  <MDBox className="order-btns">
                    <MDButton
                      mx="7px"
                      style={{
                        backgroundColor: "dodgerblue",
                        color: "white",
                        margin: "3px 6px",
                      }}
                      onClick={() => handleSetStatus(order.oid, order.uid, item._id, "PACKAGING")}
                    >
                      {updating === "PACKAGING" ? "..." : "Packaging"}
                    </MDButton>
                    <MDButton
                      mx="7px"
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        margin: "3px 6px",
                      }}
                      onClick={() => handleSetStatus(order.oid, order.uid, item._id, "DELIVERED")}
                    >
                      {updating === "DELIVERED" ? "..." : "Delivered"}
                    </MDButton>
                    <MDButton
                      style={{
                        backgroundColor: "indianred",
                        color: "white",
                        margin: "3px 6px",
                      }}
                      onClick={() => handleSetStatus(order.oid, order.uid, item._id, "REFUNDED")}
                    >
                      {updating === "REFUNDED" ? "..." : "Refunded"}
                    </MDButton>
                  </MDBox>
                )}
              </MDBox>
            </div>
          </div>
        ))}
      </MDBox>
    </MDBox>
  );
}

export default Order;
