/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "pages/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Switch } from "@mui/material";
import { login } from "redux/routes/auth";
import { resetPasswordLink } from "redux/routes/auth";
import { useGlobalContext } from "context/context";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [url, setUrl] = useState("");
  const [password, setPassword] = useState("");
  const { setLoggedIn, setBusiness } = useGlobalContext();
  const handleSignIn = async () => {
    setError("");
    if (!url || !password) {
      setError("Please provide a url and a password");
      return;
    }

    setIsLoading(true);
    let res = await login({ url, password, rememberMe });

    if (res.success) {
      window.localStorage.setItem("token", res.accessToken);
      window.localStorage.setItem("last_login", new Date());
      setBusiness(res.data);
      setLoggedIn(true);
    } else {
      setError("Invalid Credentials");
    }
    setIsLoading(false);
  };

  const [resetRequested, setResetRequested] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [isResetting, setResetting] = useState(false);
  const [cError, setCError] = useState("");
  const handleRequestReset = async () => {
    setCError("");
    if (url === "") {
      setCError("Please provide the website url");
      return;
    }
    if (isResetting) {
      return;
    }
    setResetting(true);
    let res = await resetPasswordLink({ url });
    // await dispatch(resetPasswordLink({ url }));
    if (res.success) {
      setResetRequested(true);
    } else {
      setCError("The provided url does not exist");
    }
    setResetting(false);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {forgotPass ? "Forgot Password" : "Sign in"}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Website URL"
                fullWidth
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </MDBox>
            {!forgotPass && (
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBox>
            )}
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {forgotPass ? (
                resetRequested ? (
                  <MDTypography
                    variant="h6"
                    color="black"
                    fontWeight="medium"
                    style={{ textAlign: "center" }}
                  >
                    A password reset link has been sent to your email address
                  </MDTypography>
                ) : (
                  <MDButton variant="gradient" color="info" fullWidth onClick={handleRequestReset}>
                    {isResetting ? "Requesting..." : "Reset Password"}
                  </MDButton>
                )
              ) : (
                <MDButton variant="gradient" color="info" fullWidth onClick={handleSignIn}>
                  {isLoading ? "Loading..." : "sign in"}
                </MDButton>
              )}
            </MDBox>
            <MDBox textAlign="center">
              {error && (
                <MDTypography
                  variant="button"
                  fontWeight="light"
                  style={{
                    backgroundColor: "indianRed",
                    padding: "7px 10px",
                    width: "100%",
                    color: "white",
                    borderRadius: "4px",
                  }}
                >
                  {error}
                </MDTypography>
              )}
              {cError && (
                <MDTypography
                  variant="button"
                  fontWeight="light"
                  style={{
                    backgroundColor: "indianRed",
                    padding: "7px 10px",
                    width: "100%",
                    color: "white",
                    borderRadius: "4px",
                  }}
                >
                  {cError}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              {!forgotPass && (
                <MDTypography
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => setForgotPass(true)}
                >
                  Forgot Password?{" "}
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
