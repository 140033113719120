import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import "../css/product.css";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { updateProduct } from "redux/features/productSlice";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { MDDialog } from "components/MDDialog";
// import { deleteProduct } from "redux/features/productSlice";
import {
  FilterList,
  FilterListOff,
  AddAPhoto,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoveUp,
  KeyboardDoubleArrowUp,
  KeyboardDoubleArrowDown,
  AutoFixHigh,
  CancelOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import axios from "../../../redux/axios.config";
import loadingImage from "../../../assets/images/loader.gif";
import Segment from "./Segment";
import { updateProduct } from "redux/routes/product";
import { deleteProduct } from "redux/routes/product";

const MyBox = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    width: "96%",
    padding: "0",
  },
  [theme.breakpoints.up("sm")]: {
    width: "48%",
    padding: "0",
  },
}));

function Product({ storeData, setProdCount, categories, profile }) {
  const [successSB, setSuccessSB] = useState(false);
  const [tempData, setTempData] = useState(storeData);
  const [data, setData] = useState(storeData);
  const [errorSB, setErrorSB] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const tmp = storeData.length > 0 ? storeData[0] : {};
  const [tempProd, setTemp] = useState(tmp);
  const [prod, setProd] = useState(tempProd);
  const [updating, setUpdating] = useState(false);
  // const dispatch = useDispatch();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  function containsUnwantedHtmlTags(inputString) {
    const htmlTagPattern = /<[^>]+>/g;

    const allowedTags = [
      "<p>",
      "<ul>",
      "<strong>",
      "<b>",
      "<br>",
      "<ol>",
      "<li>",
      "<em>",
      "<i>",
      "</p>",
      "</ul>",
      "</strong>",
      "</b>",
      "</br>",
      "</ol>",
      "</li>",
      "</em>",
      "</i>",
      "<br/>",
      "</h2>",
      "</h3>",
      "<h2>",
      "<h3>",
    ];

    const foundTags = inputString.match(htmlTagPattern);

    if (foundTags) {
      for (const tag of foundTags) {
        const isAllowed = allowedTags.some((allowedTag) => tag.startsWith(allowedTag));
        if (!isAllowed) {
          return true; // Found a disallowed HTML tag.
        }
      }
    }

    return false; // No disallowed HTML tags found.
  }

  let updateProductHandler = async () => {
    if (!updating) setUpdating(true);
    else if (!isUpdating) {
      const fd = new FormData();
      if (prod.img === null) {
        setErr("Please provide an image");
        openErrorSB();
        return;
      }
      if (isFile(prod.img) && prod.img.size / (1024 * 1024) > 10) {
        setErr("The image is too large. Maximum 10 Mb");
        openErrorSB();
        return;
      }

      if (
        prod.description === "" ||
        (prod.name === "" && prod.subcategory !== "Vehicles") ||
        prod.category === "" ||
        (prod.subcategory === "" && prod.category !== "Other") ||
        (prod.subcategory === "Vehicles" &&
          (!prod.extras || !prod.extras.make || !prod.extras.model))
      ) {
        setErr("Fill in all required fields");
        openErrorSB();
        return;
      }
      if (prod.price < 0) {
        setErr("The price cannot be less than 0");
        openErrorSB();
        return;
      }
      if (containsUnwantedHtmlTags(prod.description)) {
        setErr(
          "Invalid description. Only p, ul, ol, li, strong, b, br, em & i tags are allowed here"
        );
        openErrorSB();
        return;
      }

      setIsUpdating(true);
      fd.append("img", prod.img);
      fd.append("description", prod.description);
      fd.append("name", prod.name);
      fd.append("price", prod.price);
      fd.append("gender", prod.gender);
      fd.append("category", prod.category);
      fd.append(
        "subcategory",
        prod.subcategory === "Other" || prod.category === "Other" ? otherSubCat : prod.subcategory
      );
      fd.append("colors", JSON.stringify(prod.colors ? prod.colors : []));
      fd.append("sizes", JSON.stringify(prod.sizes ? prod.sizes : []));
      fd.append("categories", JSON.stringify(prod.categories ? prod.categories : []));
      fd.append("features", JSON.stringify(prod.features ? prod.features : []));
      fd.append("extras", JSON.stringify(prod.extras ? prod.extras : {}));
      fd.append("handlingTime", JSON.stringify(prod.handlingTime));
      fd.append("_id", prod._id);
      fd.append("brand", prod.brand);
      fd.append("condition", prod.condition);
      fd.append("inStock", prod.inStock);
      fd.append("showRelated", prod.showRelated);
      fd.append("showGoogle", prod.showGoogle);
      fd.append("hide", prod.hide);
      fd.append("specs", JSON.stringify(prod.specs));
      fd.append("faqs", JSON.stringify(prod.faqs));
      fd.append("USPs", JSON.stringify(prod.USPs));
      fd.append("videoLink", prod.videoLink);
      fd.append("priceOptions", JSON.stringify(prod.priceOptions));
      fd.append("coupons", JSON.stringify(prod.coupons));
      fd.append("landingPages", JSON.stringify(prod.landingPages));

      await updateProduct(fd);
      //dispatch(updateProduct(fd));
      updateProducts();
      setTemp(prod);
      setActivity("Updated");
      openSuccessSB();
      setIsUpdating(false);
      setUpdating(false);
    }
  };
  let updateProducts = () => {
    let nData = tempData.map((item) => {
      let itm = { ...item };
      if (item._id === prod._id) {
        itm = { ...prod };
      }
      return itm;
    });
    setTempData(nData);
  };
  const [openModal, setOpenModal] = useState(false);
  let deleteAccepted = async () => {
    setActivity("Deleted");
    let res = await deleteProduct({ _id: prod._id });
    //dispatch(deleteProduct({ _id: prod._id }));
    if (res.success) {
      openSuccessSB();
      setProdCount(tempData.length - 1);
      setTempData(tempData.filter((p) => p._id !== prod._id));
      setData(data.filter((p) => p._id !== prod._id));
    } else {
      setErr("Error deleting Product");
      openErrorSB();
    }
  };

  useEffect(() => {
    let exists = tempData.filter((p) => p._id === prod._id);
    if (exists.length === 0) {
      if (tempData.length > 1) {
        setProd(tempData[0]);
        setTemp(tempData[0]);
      } else {
        setProd({});
      }
    }
    setData(tempData.filter((itm) => filter(itm)));
  }, [tempData]);

  let deleteItemHandler = () => {
    setOpenModal(true);
  };

  let cancelUpdating = () => {
    setUpdating(false);
    setProd({ ...tempProd, images: prod.images });
    tempProd.landingPages.map((lp) => {
      if (lp.name === currentLP) {
        setLandingPage(lp);
        setMiniHeader(lp.miniHeader);
        setDefaultLP(lp.default);
      }
    });
  };

  // const categories = useSelector((state) => state.products.categories);
  // const wearables = useSelector((state) => state.products.wearables);
  // const profile = useSelector((state) => state.profile.account);

  const sizesArray = ["XS", "S", "M", "L", "XL", "XXL"];
  const shoeSizes = ["35", "36", "37", "38", "39", "40", "41", "42", "43", "44"];
  const getURL = (image) => {
    let url = URL.createObjectURL(image);
    return url;
  };
  const addColor = (color) => {
    if (prod.colors.length >= 6) {
      setErr(
        "You can have a maximum of 6 colors per product. You can add multiple products if insufficient"
      );
      openErrorSB();
      return;
    }
    if (prod.colors.includes(color)) {
      setErr("This color already exists");
      openErrorSB();
      return;
    }
    if (color && prod.colors) {
      setProd({ ...prod, colors: [...prod.colors, color] });
    } else if (color && !prod.colors) {
      setProd({ ...prod, colors: [color] });
    } else {
    }
  };

  const removeColor = (color) => {
    if (!updating) return;
    const newColors = prod.colors.filter((c) => color != c);
    setProd({ ...prod, colors: newColors });
  };

  const [newSize, setNewSize] = useState("");
  const addSize = (size) => {
    if (size && !prod.sizes.includes(size)) {
      setProd({ ...prod, sizes: [...prod.sizes, size] });
    }
    setNewSize("");
  };

  const removeSize = (size) => {
    if (!updating) return;
    const newSizes = prod.sizes.filter((c) => size != c);
    setProd({ ...prod, sizes: newSizes });
  };

  const isFile = (input) => "File" in window && input instanceof File;
  let [activity, setActivity] = useState("");
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={activity.slice(0, 6) + " Product"}
      content={"Product Successfully " + activity}
      dateTime="just now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bggreen="true"
    />
  );
  let [err, setErr] = useState("");
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={activity.slice(0, 6) + " Product"}
      content={err}
      dateTime="1 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgindianred="true"
    />
  );
  let [listFilters, setListFilters] = useState([]);
  let features = ["Slider", "Home Page"];
  let filters = ["Slider", "Home Page"];
  let myCategories = ["All"];
  let mySubs = ["All"];
  let [sCat, setSCat] = useState("All");
  let [sSub, setSSub] = useState("All");
  let types = [];
  storeData.map((item) => {
    let cat = item.category;
    let scat = item.subcategory;

    if (!myCategories.includes(cat) && cat && cat.length > 0) {
      myCategories.push(cat);
      types.push(cat);
    }
    if (!mySubs.includes(scat) && scat && scat.length > 0) {
      mySubs.push(scat);
      types.push(scat);
    }

    return item;
  });

  let filter = (item) => {
    let pass = true;

    listFilters.map((f) => {
      if (pass) {
        if (features.includes(f)) {
          pass = item.features.includes(f);
        }
      }

      return f;
    });

    if (pass) pass = sCat === "All" || item.category === sCat;
    if (pass) pass = sSub === "All" || item.subcategory === sSub;

    return pass;
  };

  let toggleFilter = (filter) => {
    if (listFilters.includes(filter)) {
      setListFilters(listFilters.filter((f) => f !== filter));
    } else {
      setListFilters([...listFilters, filter]);
    }
  };

  useEffect(() => {
    setData(tempData.filter((itm) => filter(itm)));
  }, [listFilters]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleFeature = (feature) => {
    if (prod.features.includes(feature)) {
      setProd({ ...prod, features: prod.features.filter((f) => f !== feature) });
    } else {
      if (feature === "Slider") {
        let current = tempData.filter((p) => p.features.includes(feature));
        if (current.length >= 12) {
          setErr("You cannot set more than 12 Primary products");
          openErrorSB();
          return;
        }
      }
      if (feature === "Home Page") {
        let current = tempData.filter((p) => p.features.includes(feature));
        if (current.length >= 48) {
          setErr("You cannot set more than 48 Secondary products");
          openErrorSB();
          return;
        }
      }
      setProd({ ...prod, features: [...prod.features, feature] });
    }
  };
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const setNextItem = (item) => {
    setActive(1);
    if (updating) {
      setOpenUpdateModal(true);
    } else {
      setProd(item);
      setTemp(item);
      setCurrentImage(loadingImage);
      setTimeout(() => {
        setCurrentImage(item.img);
      }, 10);
      setCurrentLP("default");
      item.landingPages.map((lp) => {
        if (lp.name === "default") {
          setLandingPage(lp);
          setMiniHeader(lp.miniHeader);
          setDefaultLP(lp.default);
        }
      });
    }
  };
  const [selectedColor, setSelectedColor] = useState("#000");
  const [currentImage, setCurrentImage] = useState(prod.img);
  const [addingImage, setAddingImage] = useState("");
  useEffect(() => {
    updateProducts();
  }, [prod.images]);
  let addImage = async (newImage) => {
    if (newImage === null) {
      return;
    }
    if (isFile(newImage) && newImage.size / (1024 * 1024) > 10) {
      setErr("The image is too large. Maximum 2 Mb");
      openErrorSB();
      return;
    }
    let fd = new FormData();
    fd.append("img", newImage);
    fd.append("pid", prod._id);
    let res = await axios.post("/product/image/add", fd, {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setAddingImage(percentCompleted + " %");
      },
    });
    setAddingImage("");
    setProd(res.data.data);
    updateProducts();
    setTemp(prod);
    setActivity("Image Added");
    openSuccessSB();
  };
  let toggleImage = (url) => {
    if (currentImage === url) {
      setCurrentImage(prod.img);
    } else {
      setCurrentImage(loadingImage);
      setTimeout(() => {
        setCurrentImage(url);
      }, 10);
    }
  };
  let deleteImage = async (iid) => {
    let res = await axios.post("/product/image/remove", { pid: prod._id, iid });
    setProd(res.data.data);
    updateProducts();
    setTemp(prod);
    setActivity("Image Removed");
    openSuccessSB();
  };

  useEffect(() => {
    setCurrentImage(loadingImage);
    setTimeout(() => {
      setCurrentImage(prod.img);
    }, 10);
  }, [prod.img]);

  const resizeCardImage = (img) => {
    img = img.replace(
      "https://storage.googleapis.com/test-bucket001/",
      "https://ik.imagekit.io/d4mmlivtj/goduka/tr:w-900/"
    );
    return img;
  };

  const [otherSubCat, setOther] = useState("");
  const [warningSB, setWarningSB] = useState(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  let [warning, setWarning] = useState("");
  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning"
      id="warning"
      title={" Product"}
      content={warning}
      dateTime="1 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgindianred="true"
    />
  );

  let notUpdating = (event) => {
    let id = event.target.id;
    if (!updating && id !== "edit-btn" && id !== "warning") {
      setWarning("To start editing. Click on the green pencil button");
      openWarningSB();
    }
  };
  const changeCondition = (e) => {
    setProd({ ...prod, condition: e.target.value });
  };
  const changeAvailability = (e) => {
    setProd({ ...prod, inStock: e.target.value });
  };
  const [active, setActive] = useState(1);
  const [specName, setSpecName] = useState("");
  const [specDetail, setSpecDetail] = useState("");
  const addSpec = () => {
    if (specName && specDetail) {
      setProd({
        ...prod,
        specs: [
          ...prod.specs,
          { name: specName, detail: specDetail, _id: new Date().getTime().toString() },
        ],
      });
      setSpecName("");
      setSpecDetail("");
    }
  };
  const deleteSpec = (id) => {
    setProd({ ...prod, specs: prod.specs.filter((itm) => itm._id !== id) });
  };

  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");
  const addFaq = () => {
    if (faqQuestion && faqAnswer) {
      setProd({
        ...prod,
        faqs: [
          ...prod.faqs,
          { question: faqQuestion, answer: faqAnswer, _id: new Date().getTime().toString() },
        ],
      });
      setFaqQuestion("");
      setFaqAnswer("");
    }
  };
  const deleteFaq = (id) => {
    setProd({ ...prod, faqs: prod.faqs.filter((itm) => itm._id !== id) });
  };

  const [usp, setUSP] = useState("");
  const addUSP = () => {
    if (usp) {
      if (prod.USPs.length >= 3) {
        setErr("You can have a maximum of 3 Unique Selling Points");
        openErrorSB();
      } else if (!prod.USPs.includes(usp))
        setProd({
          ...prod,
          USPs: [...prod.USPs, usp],
        });
      setUSP("");
    }
  };
  const deleteUSP = (id) => {
    setProd({ ...prod, USPs: prod.USPs.filter((itm) => itm !== id) });
  };

  const [link, setLink] = useState("");
  const [addingLink, setAddingLink] = useState(false);
  const setLinkHandler = async () => {
    if (addingLink) return;
    if (link === "") {
      setErr("Please provide the video id. i.e W2o-XJmS6jM");
      openErrorSB();
      return;
    }
    // if (!link.startsWith("https://www.youtube.com/embed/")) {
    //   setErr("Please provide a valid YouTube link");
    //   openErrorSB();
    //   return;
    // }
    setAddingLink(true);
    setProd({ ...prod, videoLink: "https://www.youtube.com/embed/" + link });
    setAddingLink(false);
    setLink("");
  };

  const [option, setOption] = useState("");
  const [optionPrice, setOptionPrice] = useState("");
  const [defaultOption, setDefaultOption] = useState(false);
  const addPriceOption = () => {
    if (option && optionPrice) {
      if (defaultOption || prod.priceOptions.length === 0) {
        setProd({
          ...prod,
          priceOptions: [
            ...prod.priceOptions.map((po) => {
              return { ...po, default: false };
            }),
            {
              option: option,
              price: optionPrice,
              default: defaultOption,
              _id: new Date().getTime().toString(),
            },
          ],
        });
      } else {
        setProd({
          ...prod,
          priceOptions: [
            ...prod.priceOptions,
            {
              option: option,
              price: optionPrice,
              default: defaultOption,
              _id: new Date().getTime().toString(),
            },
          ],
        });
      }

      setOption("");
      setOptionPrice("");
      setDefaultOption(false);
    }
  };
  const updateDefaultOption = (id) => {
    setProd({
      ...prod,
      priceOptions: [
        ...prod.priceOptions.map((po) => {
          if (po._id === id) return { ...po, default: true };
          else return { ...po, default: false };
        }),
      ],
    });
  };
  const deleteOption = (id) => {
    setProd({ ...prod, priceOptions: prod.priceOptions.filter((itm) => itm._id !== id) });
  };

  const [couponName, setCouponName] = useState("");
  const [couponDiscount, setCouponDiscount] = useState("");
  const [defaultCoupon, setDefaultCoupon] = useState(false);
  const addCoupon = () => {
    if (couponName && couponDiscount) {
      if (defaultCoupon || prod.coupons.length === 0) {
        setProd({
          ...prod,
          coupons: [
            ...prod.coupons.map((po) => {
              return { ...po, default: false };
            }),
            {
              name: couponName,
              discount: couponDiscount,
              default: defaultOption,
              _id: new Date().getTime().toString(),
            },
          ],
        });
      } else {
        setProd({
          ...prod,
          coupons: [
            ...prod.coupons,
            {
              name: couponName,
              discount: couponDiscount,
              default: defaultOption,
              _id: new Date().getTime().toString(),
            },
          ],
        });
      }

      setCouponName("");
      setCouponDiscount("");
      setDefaultCoupon(false);
    }
  };
  const updateDefaultCoupon = (id) => {
    setProd({
      ...prod,
      coupons: [
        ...prod.coupons.map((po) => {
          if (po._id === id) return { ...po, default: true };
          else return { ...po, default: false };
        }),
      ],
    });
  };
  const deleteCoupon = (id) => {
    setProd({ ...prod, coupons: prod.coupons.filter((itm) => itm._id !== id) });
  };
  const [copyingCoupon, setCopyingCoupon] = useState(false);
  const copyCoupon = async (link, id) => {
    setCopyingCoupon(id);
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(link);
        alert("Link copied to clipboard!");
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    } else {
      console.warn("Clipboard API not supported in this browser.");
    }
    setCopyingCoupon("");
  };

  const [copyingLpLink, setCopyingLpLink] = useState(false);
  const copyLandingPageLink = async (link, id) => {
    setCopyingLpLink(id);
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(link);
        alert("Link copied to clipboard!");
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    } else {
      console.warn("Clipboard API not supported in this browser.");
    }
    setCopyingLpLink("");
  };

  //Articles management
  const [articleType, setArticleType] = useState("article");
  const [newArticle, setNewArticle] = useState({});
  const [addingArticle, setAddingArticle] = useState("Add");
  const [listItem, setListItem] = useState({});
  const [listText, setListText] = useState("");
  const [carouselItem, setCarouselItem] = useState({});
  const addArticle = async () => {
    if (Object.keys(newArticle).length === 0) return;
    let order = 0;
    prod.articles.map((p) => {
      if (p.order > order) order = p.order;
    });
    let article = {
      type: articleType,
      _id: Date.now(),
      order: order + 1,
      content: newArticle,
    };

    if (isFile(newArticle.image) && newArticle.image.size / (1024 * 1024) > 10) {
      setErr("The image is too large. Maximum 10 Mb");
      openErrorSB();
      return;
    }
    let invalid = false;
    if (articleType === "carousel") {
      newArticle.values.map((ca, idx) => {
        if (ca.image && isFile(ca.image) && ca.image.size / (1024 * 1024) > 10) {
          setErr("Image number " + (idx + 1) + " is too large. Maximum 10 Mb");
          openErrorSB();
          invalid = true;
          return;
        }
      });
    }
    if (invalid) return;

    if (
      newArticle.content &&
      articleType === "article" &&
      containsUnwantedHtmlTags(newArticle.content)
    )
      invalid = true;
    if (articleType === "list") {
      newArticle.items.map((itm) => {
        if (containsUnwantedHtmlTags(itm)) invalid = true;
      });
    }
    if (invalid) {
      setErr("Invalid content. Only p, ul, ol, li, strong, b, br, em & i tags are allowed here");
      openErrorSB();
      return;
    }

    if (articleType === "carousel") {
      newArticle.values = await Promise.all(
        newArticle.values.map(async (ca, idx) => {
          if (ca.image && isFile(ca.image)) {
            let mfd = new FormData();
            mfd.append("img", ca.image);
            let res = await axios.post("/image/upload/single", mfd, {
              onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setAddingArticle(percentCompleted + " %");
              },
            });
            if (res.data.success) {
              ca.image = res.data.data;
            } else {
              ca.image = "";
            }
          }
          return ca;
        })
      );
    }

    let fd = new FormData();
    fd.append("img", newArticle.image);

    let res = await axios.post("/image/upload/single", fd, {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setAddingArticle(percentCompleted + " %");
      },
    });

    fd.append("pid", prod._id);
    fd.append("article", JSON.stringify(article));

    // let res = await axios.post("/product/article/add", fd, {
    //   onUploadProgress: function (progressEvent) {
    //     var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //     setAddingArticle(percentCompleted + " %");
    //   },
    // });

    setAddingArticle("Add");
    // setProd(res.data.data);
    let lps = [...prod.landingPages];
    lps = lps.map((lp) => {
      if (lp.name === currentLP) {
        let artcls = [...lp.articles];
        artcls.push(article);
        lp.articles = artcls;
      }
      return lp;
    });

    setProd({
      ...prod,
      landingPages: lps,
    });
    updateProducts();
    setActivity("Article Added");
    openSuccessSB();
    setNewArticle({});
  };

  //AI Modal
  const [openAIModal, setOpenAIModal] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [generatingSpecs, setGeneratingSpecs] = useState(false);
  const [generatingFAQs, setGeneratingFAQs] = useState(false);
  const [generatingUSPs, setGeneratingUSPs] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [AIResponse, setAIResponse] = useState(prod.description);
  const [AIError, setAIError] = useState("");
  const fetchAIDesc = async () => {
    setAIError("");
    if (generating) return;
    setGenerating(true);
    let res = await axios.post("/product/ai/info", {
      pid: prod._id,
      message: instructions,
      description: AIResponse,
    });
    if (res.data.success) {
      setAIResponse(res.data.data);
    } else {
      setAIError("Unable to generate. Kindly refresh the page and try again");
    }
    setGenerating(false);
    setInstructions("");
  };
  const getAISpec = async () => {
    if (generatingSpecs) return;

    if (prod.specs.length >= 10) {
      setErr("You already have 10 or more specs");
      openErrorSB();
      return;
    }
    setGeneratingSpecs(true);
    let res = await axios.post("/product/ai/specs", {
      pid: prod._id,
      specs: JSON.stringify(prod.specs),
      num: 10 - prod.specs.length,
    });
    if (res.data.success) {
      let newSpecs = JSON.parse(res.data.data).map((sp, idx) => {
        return { ...sp, _id: new Date().getTime().toString() + idx };
      });
      setProd({ ...prod, specs: [...prod.specs, ...newSpecs] });
    } else {
      if (prod.specs.length >= 10) {
        setErr("Unable to generate. Kindly refresh the page and try again");
        openErrorSB();
      }
    }
    setGeneratingSpecs(false);
  };
  const getAIFAQs = async () => {
    if (generatingFAQs) return;

    if (prod.faqs.length >= 10) {
      setErr("You already have 10 or more FAQs");
      openErrorSB();
      return;
    }
    setGeneratingFAQs(true);
    let res = await axios.post("/product/ai/faqs", {
      pid: prod._id,
      faqs: JSON.stringify(prod.faqs),
      num: 10 - prod.faqs.length,
    });
    if (res.data.success) {
      let newFAQs = JSON.parse(res.data.data).map((fa, idx) => {
        return { ...fa, _id: new Date().getTime().toString() + idx };
      });
      setProd({ ...prod, faqs: [...prod.faqs, ...newFAQs] });
    } else {
      if (prod.specs.length >= 10) {
        setErr("Unable to generate. Kindly refresh the page and try again");
        openErrorSB();
      }
    }
    setGeneratingFAQs(false);
  };
  const getAIUSPs = async () => {
    if (generatingUSPs) return;

    if (prod.USPs.length >= 3) {
      setErr("You already have 3 USPs");
      openErrorSB();
      return;
    }
    setGeneratingUSPs(true);
    let res = await axios.post("/product/ai/usps", {
      pid: prod._id,
      usps: JSON.stringify(prod.USPs),
      num: 3 - prod.USPs.length,
    });

    if (res.data.success) {
      let newUSPs = JSON.parse(res.data.data);
      setProd({ ...prod, USPs: [...prod.USPs, ...newUSPs] });
    } else {
      setErr("Unable to generate. Kindly refresh the page and try again");
      openErrorSB();
    }
    setGeneratingUSPs(false);
  };
  useEffect(() => {
    setAIResponse(prod.description);
  }, [prod]);

  //Multiple Landing Pages
  const [newlandingPage, setNewLandingPage] = useState("");
  const [currentLP, setCurrentLP] = useState("default");
  const [addingLp, setAddingLp] = useState(false);
  const [landigPage, setLandingPage] = useState({});
  const [miniHeader, setMiniHeader] = useState(landigPage.miniHeader);
  const [defaultLP, setDefaultLP] = useState(landigPage.default);
  const addLandingPage = () => {
    if (addingLp) return;
    setAddingLp(true);
    let exists = prod.landingPages.find(
      (lp) => lp.name.toLowerCase() === newlandingPage.toLowerCase()
    );
    if (exists) {
      setErr("A Landing Page with a similar name already exists");
    } else {
      setProd({
        ...prod,
        landingPages: [...prod.landingPages, { name: newlandingPage, articles: [] }],
      });
    }
    setCurrentLP(newlandingPage);
    setNewLandingPage("");
    setAddingLp(false);
  };
  useEffect(() => {
    if (prod && prod.landingPages)
      prod.landingPages.map((lp) => {
        if (lp.name === currentLP) {
          setLandingPage(lp);
          setMiniHeader(lp.miniHeader);
          setDefaultLP(lp.default);
        }
      });
  }, [currentLP]);

  // useEffect(() => {
  //   setMiniHeader(landigPage.miniHeader);
  //   setDefaultLP(landigPage.default);
  // }, [landigPage]);

  const toggleMiniHeader = () => {
    let lps = [...prod.landingPages];

    lps = lps.map((lp) => {
      // console.log(lp.name);
      if (lp.name === currentLP) {
        lp.miniHeader = !lp.miniHeader;
        // console.log(lps, currentLP, lp.miniHeader);
        setMiniHeader(lp.default);
      }
      return lp;
    });

    setProd({
      ...prod,
      landingPages: lps,
    });
  };
  const toggleDefault = () => {
    let lps = [...prod.landingPages];
    if (landigPage.default) {
      lps = lps.map((lp) => {
        if (lp.name === currentLP) {
          lp.default = false;
          setDefaultLP(false);
        } else if (lp.name === "default") {
          lp.default = true;
          setDefaultLP(true);
        }
        return lp;
      });
    } else {
      lps = lps.map((lp) => {
        if (lp.name === currentLP) {
          lp.default = true;
          setDefaultLP(true);
        } else {
          lp.default = false;
          setDefaultLP(false);
        }
        return lp;
      });
    }

    setProd({
      ...prod,
      landingPages: lps,
    });
  };

  return (
    <>
      {tempData.length > 0 ? (
        <MDBox
          minWidth="320px"
          borderRadius="10px"
          className="product"
          m={2}
          p={1}
          style={{ boxShadow: "0 0 5px grey" }}
        >
          {/* Filters */}
          <MDBox display="flex" flexWrap="wrap" justifyContent="center">
            <MyBox>
              <Typography fontSize="12pt">Filtered Products ({data.length})</Typography>
              <MDBox
                p={1}
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  backgroundColor: "#ddd",
                  borderRadius: "5px",
                  border: "solid 1px grey",
                }}
              >
                {data
                  .filter((item) => filter(item))
                  .map((item) => (
                    <Typography
                      key={item._id}
                      fontSize="11pt"
                      className="prod-list"
                      onClick={() => {
                        setNextItem(item);
                      }}
                      style={{
                        backgroundColor: prod._id === item._id ? "#404040" : "transparent",
                        color: prod._id === item._id ? "#eee" : "inherit",
                        borderRadius: "3px",
                        padding: "2px",
                        paddingLeft: "5px",
                      }}
                    >
                      {item.subcategory === "Vehicles"
                        ? item.extras.make + " " + item.extras.model
                        : item.name}
                    </Typography>
                  ))}
              </MDBox>
            </MyBox>

            <MDBox
              pl={1}
              width="48%"
              minWidth="120px"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <MDBox display="flex" justifyContent="space-between">
                <Typography fontSize="12pt" variant="">
                  Filters
                </Typography>
                <Typography
                  variant="body"
                  fontSize="11pt"
                  onClick={() => setListFilters([])}
                  style={{ cursor: "pointer" }}
                  className="hover"
                >
                  <FilterListOff /> Clear
                </Typography>
              </MDBox>
              <MDBox
                display="flex"
                flexWrap="wrap"
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  backgroundColor: "#eee",
                  borderRadius: "5px",
                  border: "solid 1px grey",
                }}
                p={1}
              >
                {filters.map((f, index) => (
                  <Typography
                    key={f + "-" + index}
                    onClick={() => toggleFilter(f)}
                    style={{
                      color: listFilters.includes(f) ? "green" : "black",
                      border: listFilters.includes(f) ? "solid green 1px" : "solid black 1px",
                      borderRadius: "5px",
                      padding: "1px 3px",
                      margin: "2px",
                      cursor: "pointer",
                    }}
                    fontSize={"11pt"}
                  >
                    {f === "Slider"
                      ? "Primary Products"
                      : f === "Home Page"
                      ? "Secondary Products"
                      : f}
                  </Typography>
                ))}
                <Autocomplete
                  size="small"
                  disablePortal
                  isOptionEqualToValue={(option, value) => option === value}
                  options={myCategories}
                  renderInput={(params) => <TextField {...params} label="Filter by Category" />}
                  value={sCat}
                  style={{ width: 100 + "%", margin: "10px auto" }}
                  name="type"
                  onChange={(e, v) => {
                    setSCat(v);
                  }}
                />
                <Autocomplete
                  size="small"
                  disablePortal
                  isOptionEqualToValue={(option, value) => option === value}
                  options={mySubs}
                  renderInput={(params) => <TextField {...params} label="Filter by Sub-category" />}
                  value={sSub}
                  style={{ width: 100 + "%", margin: "0 auto" }}
                  name="type"
                  onChange={(e, v) => {
                    setSSub(v);
                  }}
                />
              </MDBox>
            </MDBox>
            <MDBox m={1}>
              <MDButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                color="info"
                size="small"
                style={{ cursor: "pointer" }}
                sx={{ display: { xs: "block", sm: "none" } }}
              >
                Filters&nbsp;
                <FilterList />
              </MDButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>Close</MenuItem>
                <MenuItem onClick={() => setListFilters([])}>Clear Filters</MenuItem>
                {filters.map((f, index) => (
                  <MenuItem
                    key={f + "-" + index}
                    onClick={() => toggleFilter(f)}
                    style={{
                      color: listFilters.includes(f) ? "green" : "inherit",
                      border: listFilters.includes(f) ? "solid green 1px" : "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    fontSize={"11pt"}
                  >
                    {f}
                  </MenuItem>
                ))}
              </Menu>
            </MDBox>
          </MDBox>
          {/* Product */}
          <MDBox
            mt={1}
            p={1}
            style={{ backgroundColor: "#eee", borderRadius: "5px", border: "solid 1px #bbb" }}
          >
            {/* Tab Indicators */}
            {profile.package && profile.package.toLowerCase() !== "starter" && (
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                marginBottom="30px"
              >
                <MDBox
                  textAlign="center"
                  width="30%"
                  style={{
                    borderBottom: "solid 2px grey",
                    borderColor: active === 1 ? "dodgerblue" : "initial",
                    color: active === 1 ? "dodgerblue" : "initial",
                    cursor: "pointer",
                  }}
                  onClick={() => setActive(1)}
                >
                  <Typography>Basic Information</Typography>
                </MDBox>
                <MDBox
                  textAlign="center"
                  width="30%"
                  style={{
                    borderBottom: "solid 2px grey",
                    borderColor: active === 2 ? "dodgerblue" : "initial",
                    cursor: "pointer",
                    color: active === 2 ? "dodgerblue" : "initial",
                  }}
                  onClick={() => setActive(2)}
                >
                  <Typography>Supplementary</Typography>
                </MDBox>
                <MDBox
                  textAlign="center"
                  width="30%"
                  style={{
                    borderBottom: "solid 2px grey",
                    borderColor: active === 3 ? "dodgerblue" : "initial",
                    cursor: "pointer",
                    color: active === 3 ? "dodgerblue" : "initial",
                  }}
                  onClick={() => setActive(3)}
                >
                  <Typography>Articles</Typography>
                </MDBox>
              </MDBox>
            )}
            {/* Basic Information Div */}
            {active === 1 && (
              <Grid container spacing={2} onClick={notUpdating}>
                {/* Product Images */}
                <Grid item xs={12} md={6}>
                  <MDBox display="flex" justify="space-evenly" flexWrap="wrap">
                    <MDBox
                      pr={1}
                      pb={1}
                      width="100%"
                      textAlign="center"
                      style={{ aspectRatio: "3/2" }}
                    >
                      <img
                        id={"prod-img"}
                        className={
                          prod.img === tempProd.img ? "item-image " : "item-image input-modified"
                        }
                        src={resizeCardImage(
                          currentImage === prod.img
                            ? isFile(prod.img)
                              ? getURL(prod.img)
                              : prod.img
                            : currentImage
                        )}
                        alt={prod.name}
                      />
                    </MDBox>
                    <MDBox display="flex" alignItems="center" width="100%">
                      {prod.images &&
                        prod.images.map((im, index) => {
                          return (
                            <MDBox
                              id="edit-btn"
                              key={im.img + "-" + index}
                              style={{
                                aspectRatio: "3/2",
                                width: "20%",
                                position: "relative",
                                border: currentImage === im.img ? "solid 3px green" : "none",
                              }}
                              mr={2}
                            >
                              <img
                                id="edit-btn"
                                src={im.img}
                                style={{ width: "100%", height: "100%" }}
                                onClick={() => toggleImage(im.img)}
                              />
                              {updating && (
                                <Tooltip title="Delete this image">
                                  <span
                                    style={{
                                      position: "absolute",
                                      bottom: "0",
                                      right: "0",
                                      color: "indianred",
                                      backgroundColor: "#202020",
                                      borderRadius: "3px",
                                      height: "25px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteImage(im._id)}
                                  >
                                    <Delete />
                                  </span>
                                </Tooltip>
                              )}
                            </MDBox>
                          );
                        })}
                      {updating && prod.images.length < 3 && (
                        <MDBox
                          style={{
                            aspectRatio: "3/2",
                            width: "20%",
                            cursor: "pointer",
                            position: "relative",
                          }}
                        >
                          <AddAPhoto
                            style={{ width: "100%", height: "100%" }}
                            onClick={() => document.getElementById("add-image").click()}
                          />
                          {addingImage && (
                            <span
                              style={{
                                position: "absolute",
                                right: "0",
                                top: "0",
                                padding: "10px",
                                backgroundColor: "#ccc",
                                borderRadius: "4px",
                              }}
                            >
                              {addingImage}
                            </span>
                          )}
                          <input
                            type="file"
                            style={{ display: "none" }}
                            id="add-image"
                            onChange={(e) => {
                              addImage(e.target.files[0]);
                            }}
                          />
                        </MDBox>
                      )}
                    </MDBox>
                    <MDBox display="flex" flexWrap="wrap" alignItems="center">
                      <MDBox>
                        <MDButton
                          size="small"
                          disabled={!updating}
                          style={{ backgroundColor: "dodgerblue", color: "white" }}
                          onClick={() => {
                            if (updating) document.getElementById("edit-prod-image").click();
                          }}
                        >
                          <Icon>edit</Icon>&nbsp;Image
                        </MDButton>
                        <Input
                          size="small"
                          type="file"
                          style={{ display: "none" }}
                          id="edit-prod-image"
                          onChange={(e) => {
                            setProd({ ...prod, img: e.target.files[0] });
                          }}
                        />
                      </MDBox>
                      {features.map((f, index) => (
                        <Typography fontSize={"10pt"} key={f + "-" + index}>
                          <Switch
                            style={{
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            className="small-switch"
                            checked={prod.features.includes(f)}
                            onChange={() => toggleFeature(f)}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                            m={1}
                          />
                          {f === "Slider" ? "Primary" : f === "Home Page" ? "Secondary" : f}
                        </Typography>
                      ))}
                      <Typography fontSize={"10pt"}>
                        <Switch
                          style={{
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          className="small-switch"
                          checked={prod.showRelated}
                          onChange={(e) => setProd({ ...prod, showRelated: e.target.checked })}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                          m={1}
                        />
                        Show Related
                      </Typography>
                      <Typography fontSize={"10pt"}>
                        <Switch
                          style={{
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          className="small-switch"
                          checked={prod.showGoogle}
                          onChange={(e) => setProd({ ...prod, showGoogle: e.target.checked })}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                          m={1}
                        />
                        Show On Google
                      </Typography>
                      <Typography fontSize={"10pt"}>
                        <Switch
                          style={{
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          className="small-switch"
                          checked={prod.hide}
                          onChange={(e) => setProd({ ...prod, hide: e.target.checked })}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                          m={1}
                        />
                        Hide
                      </Typography>
                    </MDBox>
                  </MDBox>
                </Grid>
                {/* Basic Product Information */}
                <Grid item xs={12} md={6}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MDBox width="100%" pt={2} textAlign="center">
                      <Autocomplete
                        size="small"
                        disablePortal
                        isOptionEqualToValue={(option, value) => option === value}
                        options={categories.map((cat) => cat.name)}
                        renderInput={(params) => <TextField {...params} label="Select Category" />}
                        value={prod.category}
                        style={{
                          width: 100 + "%",
                          margin: "0 auto",
                          pointerEvents: updating ? "initial" : "none",
                        }}
                        name="type"
                        onChange={(e, v) => {
                          setProd({ ...prod, category: v, subcategory: "" });
                        }}
                      />
                    </MDBox>
                    {prod.category !== "Other" && (
                      <MDBox width="100%" pt={2} textAlign="center">
                        <Autocomplete
                          size="small"
                          disablePortal
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          options={
                            categories.filter((cat) => cat.name === prod.category).length > 0
                              ? [
                                  ...categories.filter((cat) => cat.name === prod.category)[0]
                                    .subcategories,
                                  "Other",
                                ]
                              : []
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Select Sub-category" />
                          )}
                          value={prod.subcategory}
                          style={{
                            width: 100 + "%",
                            margin: "0 auto",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          name="subcategory"
                          onChange={(e, v) => {
                            setProd({ ...prod, subcategory: v });
                          }}
                        />
                      </MDBox>
                    )}
                    {(prod.category === "Other" || prod.subcategory === "Other") && (
                      <MDBox width="100%" pt={2} textAlign="center">
                        <MDInput
                          size="small"
                          label="Enter Sub-category"
                          value={otherSubCat === "" ? prod.subcategory : otherSubCat}
                          type="text"
                          name="subcategory"
                          inputProps={{ maxLength: 50 }}
                          style={{ width: 100 + "%", pointerEvents: updating ? "initial" : "none" }}
                          onChange={(e) => setOther(e.target.value)}
                        />
                      </MDBox>
                    )}
                    {prod.subcategory === "Vehicles" && (
                      <>
                        <MDBox width="100%" pt={2} textAlign="center">
                          <MDInput
                            size="small"
                            label="Make"
                            value={prod.extras ? (prod.extras.make ? prod.extras.make : "") : ""}
                            type="text"
                            name="Make"
                            inputProps={{ maxLength: 50 }}
                            style={{
                              width: 100 + "%",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            onChange={(e) =>
                              setProd({ ...prod, extras: { ...prod.extras, make: e.target.value } })
                            }
                          />
                        </MDBox>
                        <MDBox width="100%" pt={2} textAlign="center">
                          <MDInput
                            size="small"
                            label="Model"
                            value={prod.extras ? (prod.extras.model ? prod.extras.model : "") : ""}
                            type="text"
                            name="Model"
                            inputProps={{ maxLength: 50 }}
                            style={{
                              width: 100 + "%",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            onChange={(e) =>
                              setProd({
                                ...prod,
                                extras: { ...prod.extras, model: e.target.value },
                              })
                            }
                          />
                        </MDBox>
                      </>
                    )}
                    {prod.subcategory !== "Vehicles" && (
                      <MDBox width="100%" pt={2} textAlign="center">
                        <MDInput
                          size="small"
                          label="Title"
                          value={prod.name}
                          inputProps={{ maxLength: 150 }}
                          type="text"
                          style={{ width: 100 + "%", pointerEvents: updating ? "initial" : "none" }}
                          className={prod.name === tempProd.name ? "" : "input-modified"}
                          onChange={(e) => setProd({ ...prod, name: e.target.value })}
                        />
                      </MDBox>
                    )}
                    <MDBox width="100%" pt={2} textAlign="center">
                      <MDInput
                        size="small"
                        label="Price"
                        value={prod.price}
                        inputProps={{ min: 0, max: 99999999 }}
                        type="number"
                        style={{ width: 100 + "%", pointerEvents: updating ? "initial" : "none" }}
                        className={prod.price === tempProd.price ? "" : "input-modified"}
                        onChange={(e) => setProd({ ...prod, price: e.target.value })}
                      />
                    </MDBox>
                    <MDBox
                      width="100%"
                      pt={2}
                      textAlign="center"
                      overflow-y="auto"
                      className="m-textarea"
                    >
                      <MDBox width="100%" textAlign="right" marginBottom="-10px">
                        <span
                          onClick={() => setOpenAIModal(true)}
                          style={{
                            color: "black",
                            cursor: "pointer",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                        >
                          <AutoFixHigh />
                        </span>

                        <Modal
                          open={openAIModal}
                          onClose={() => setOpenAIModal(false)}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <MDBox sx={style}>
                            <span
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => setOpenAIModal(false)}
                            >
                              <CancelOutlined />
                            </span>
                            <MDTypography id="modal-modal-title" variant="h6" component="h2">
                              Generate AI Description
                            </MDTypography>
                            <MDTypography
                              id="modal-modal-description"
                              variant="text"
                              sx={{ mt: 2, fontSize: "11pt" }}
                            >
                              <br />
                            </MDTypography>
                            <MDBox>
                              <MDTypography
                                id="modal-modal-description"
                                variant="text"
                                sx={{ mt: 2, fontSize: "11pt" }}
                              >
                                <MDBox
                                  style={{
                                    overflowY: "auto",
                                    maxHeight: "200px",
                                    backgroundColor: "#efefef",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <MDInput
                                    label="Description"
                                    value={AIResponse}
                                    multiline={true}
                                    onChange={(e) => setAIResponse(e.target.value)}
                                    style={{
                                      width: "100%",
                                      margin: "10px 0",
                                    }}
                                  />
                                </MDBox>
                                <b>Preview</b>
                                <MDBox
                                  style={{
                                    overflowY: "auto",
                                    maxHeight: "200px",
                                    margin: "10px 0",
                                    backgroundColor: "#efefef",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {containsUnwantedHtmlTags(AIResponse) ? (
                                    <p>The text contains disallowed html tags</p>
                                  ) : (
                                    <p
                                      dangerouslySetInnerHTML={{ __html: AIResponse }}
                                      style={{ textAlign: "left", padding: "10px 15px" }}
                                      className="ul-content"
                                    ></p>
                                  )}
                                </MDBox>
                                <MDBox
                                  style={{
                                    overflowY: "auto",
                                    maxHeight: "200px",
                                  }}
                                >
                                  <MDInput
                                    label="Additional Instructions"
                                    value={instructions}
                                    multiline={true}
                                    onChange={(e) => setInstructions(e.target.value)}
                                    style={{ width: "100%", margin: "10px 0" }}
                                  />
                                </MDBox>

                                <br />

                                <MDButton
                                  size="small"
                                  style={{
                                    mt: 2,
                                    backgroundColor: "green",
                                    color: "white",
                                    margin: "0 auto",
                                  }}
                                  onClick={() => fetchAIDesc()}
                                >
                                  {generating ? "Generating..." : "Generate"}
                                </MDButton>

                                <MDButton
                                  size="small"
                                  style={{
                                    mt: 2,
                                    backgroundColor: "dodgerblue",
                                    color: "white",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => {
                                    setProd({ ...prod, description: AIResponse });
                                    setOpenAIModal(false);
                                  }}
                                >
                                  Use
                                </MDButton>

                                <br />
                                {AIError && <span style={{ color: "indianred" }}>{AIError}</span>}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </Modal>
                      </MDBox>
                      <MDInput
                        size="small"
                        multiline={true}
                        label="Description"
                        value={prod.description}
                        type="text"
                        inputProps={{ maxLength: 5000 }}
                        pt={2}
                        style={{
                          width: 100 + "%",
                          overflowY: "scroll",
                          maxHeight: 100 + "px",
                          paddingTop: 10 + "px",
                          pointerEvents: updating ? "initial" : "none",
                        }}
                        className={
                          prod.description === tempProd.description ? "" : "input-modified"
                        }
                        onChange={(e) => setProd({ ...prod, description: e.target.value })}
                      />
                    </MDBox>
                    {prod.category === "Fashion" && (
                      <MDBox width="100%" pt={2}>
                        <Autocomplete
                          size="small"
                          isOptionEqualToValue={(option, value) => option === value}
                          disablePortal={true}
                          options={["Male", "Female", "Unisex", ""]}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="Select Gender" />}
                          value={prod.gender}
                          style={{
                            width: 100 + "%",
                            margin: "0 auto",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          className={prod.gender === tempProd.gender ? "" : "input-modified"}
                          name="type"
                          onChange={(e, v) => setProd({ ...prod, gender: v })}
                        />
                      </MDBox>
                    )}
                    {prod.subcategory !== "Vehicles" && (
                      <MDBox width="100%" pt={2} textAlign="center">
                        <MDInput
                          size="small"
                          label="Brand (Optional)"
                          value={prod.brand}
                          type="text"
                          name="brand"
                          inputProps={{ maxLength: 50 }}
                          style={{
                            width: 100 + "%",
                            margin: "0 auto",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          onChange={(e, v) => setProd({ ...prod, brand: e.target.value })}
                        />
                      </MDBox>
                    )}
                    {profile && profile.handlingType === "custom" && (
                      <MDBox width="100%" marginTop="15px">
                        <Typography style={{ fontSize: "11pt" }}>
                          How much time does it take you to prepare this item for shipping?
                        </Typography>
                        <MDBox display="flex" justifyContent="start" marginTop="20px">
                          <FormControl width="45%">
                            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={prod && prod.handlingTime ? prod.handlingTime.unit : "days"}
                              label="Unit"
                              style={{
                                width: "150px",
                                padding: "8px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              onChange={(e) =>
                                setProd({
                                  ...prod,
                                  handlingTime: { ...prod.handlingTime, unit: e.target.value },
                                })
                              }
                            >
                              <MenuItem value={"hours"}>Hours</MenuItem>
                              <MenuItem value={"days"}>Days</MenuItem>
                              <MenuItem value={"weeks"}>Weeks</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            label="Value"
                            size="small"
                            value={prod && prod.handlingTime ? prod.handlingTime.amount : ""}
                            onChange={(e) =>
                              setProd({
                                ...prod,
                                handlingTime: { ...prod.handlingTime, amount: e.target.value },
                              })
                            }
                            style={{
                              marginLeft: "20px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                          />
                        </MDBox>
                      </MDBox>
                    )}
                    {prod.subcategory === "Vehicles" ? (
                      <MDBox width="100%" pt={2} pl={1} textAlign="left">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          size="small"
                          style={{
                            fontSize: "10pt",
                            textAlign: "left",
                            width: 100 + "%",
                            margin: "0 auto",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                        >
                          Condition
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={prod.condition}
                          onChange={changeCondition}
                          row
                          style={{ pointerEvents: updating ? "initial" : "none" }}
                        >
                          <FormControlLabel
                            value="Brand New"
                            control={<Radio />}
                            label="Brand New"
                            style={{ fontWeight: "normal !important" }}
                          />
                          <FormControlLabel
                            value="Foreign Used"
                            control={<Radio />}
                            label="Foreign Used"
                            style={{ fontWeight: "normal !important" }}
                          />
                          <FormControlLabel
                            value="Local Used"
                            control={<Radio />}
                            label="Local Used"
                            style={{ fontWeight: "normal" }}
                          />
                        </RadioGroup>
                      </MDBox>
                    ) : (
                      <MDBox width="100%" pt={2} pl={1} textAlign="left">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          size="small"
                          style={{ fontSize: "10pt", textAlign: "left" }}
                        >
                          Condition (Optional)
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={prod.condition}
                          onChange={changeCondition}
                          row
                          style={{ pointerEvents: updating ? "initial" : "none" }}
                        >
                          <FormControlLabel
                            value="New"
                            control={<Radio />}
                            label="New"
                            style={{ fontWeight: "normal !important" }}
                          />
                          <FormControlLabel
                            value="Used"
                            control={<Radio />}
                            label="Used"
                            style={{ fontWeight: "normal !important" }}
                          />
                          <FormControlLabel
                            value="Refurbished"
                            control={<Radio />}
                            label="Refurbished"
                            style={{ fontWeight: "normal" }}
                          />
                        </RadioGroup>
                      </MDBox>
                    )}

                    <MDBox width="100%" pt={2} pl={1} textAlign="left">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        size="small"
                        style={{ fontSize: "10pt", textAlign: "left" }}
                      >
                        Availability
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={prod.inStock}
                        onChange={changeAvailability}
                        row
                        style={{ pointerEvents: updating ? "initial" : "none" }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="In Stock"
                          style={{ fontWeight: "normal !important" }}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Out of Stock"
                          style={{ fontWeight: "normal !important" }}
                        />
                      </RadioGroup>
                    </MDBox>
                    <MDBox width="100%" pt={2} style={{ display: "flex", alignItems: "center" }}>
                      <input
                        id="color-input"
                        type="color"
                        onChange={(e) => {
                          setSelectedColor(e.target.value);
                        }}
                        style={{ width: 0, height: 0, opacity: 0 }}
                      />
                      <Tooltip title="Select Color">
                        <span
                          style={{
                            backgroundColor: selectedColor,
                            height: "20px",
                            minWidth: "30px",
                            margin: "0 3px",
                            display: "inline-block",
                            boxShadow: "0 0 3px grey",
                            borderRadius: "5px",
                            cursor: "pointer",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          onClick={() => document.getElementById("color-input").click()}
                        ></span>
                      </Tooltip>
                      <MDButton
                        disabled={!updating}
                        color="info"
                        size="small"
                        onClick={() => addColor(selectedColor)}
                      >
                        Add Color
                      </MDButton>

                      <span style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                        {prod.colors &&
                          prod.colors.map((item, index) => (
                            <Tooltip title="Remove" key={index}>
                              <span
                                style={{
                                  backgroundColor: item,
                                  height: "20px",
                                  width: "20px",
                                  margin: "0 3px",
                                  display: "inline-block",
                                  boxShadow: "0 0 3px grey",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  pointerEvents: updating ? "initial" : "none",
                                }}
                                onClick={() => removeColor(item)}
                              ></span>
                            </Tooltip>
                          ))}
                      </span>
                    </MDBox>
                    {prod.sizes && prod.category === "Fashion" && (
                      <MDBox width="100%" pt={2} style={{ display: "flex" }}>
                        <Autocomplete
                          size="small"
                          isOptionEqualToValue={(option, value) => option === value}
                          disablePortal={true}
                          options={
                            [
                              "Sneakers",
                              "Sandals",
                              "Flats",
                              "Loafers & Slip Ons",
                              "Boots",
                              "Heels",
                            ].includes(prod.subcategory)
                              ? shoeSizes
                              : sizesArray
                          }
                          renderInput={(params) => <TextField {...params} label="Add Size" />}
                          value={newSize}
                          className={prod.sizes === tempProd.sizes ? "" : "input-modified"}
                          name="type"
                          onChange={(e, v) => addSize(v)}
                          style={{
                            minWidth: "150px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                        />
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {prod.sizes.map((item, index) => (
                            <Tooltip title="Remove" key={index}>
                              <MDTypography
                                style={{
                                  height: "20px",
                                  padding: "2px 3px",
                                  display: "inline-block",
                                  cursor: "pointer",
                                  fontSize: "10pt",
                                }}
                                onClick={() => removeSize(item)}
                              >
                                {item + ", "}
                              </MDTypography>
                            </Tooltip>
                          ))}
                        </span>
                      </MDBox>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            )}
            {/* FAQs & Specs Div */}
            {active === 2 && (
              <MDBox
                display="flex"
                flexWrap="wrap"
                justifyContent="space-evenly"
                width="100%"
                onClick={notUpdating}
              >
                {/* Specs, USPs & Price Options */}
                <Grid container spacing={2}>
                  {/* Specs */}
                  <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"}>Specs</Typography>
                    <MDBox>
                      <TextField
                        type="text"
                        label="Name"
                        style={{
                          width: "30%",
                          marginRight: "10px",
                          pointerEvents: updating ? "initial" : "none",
                        }}
                        inputProps={{ maxLength: 50 }}
                        value={specName}
                        onChange={(e) => setSpecName(e.target.value)}
                      />
                      <TextField
                        type="text"
                        label="Detail"
                        multiline={true}
                        style={{ width: "66%", pointerEvents: updating ? "initial" : "none" }}
                        value={specDetail}
                        onChange={(e) => setSpecDetail(e.target.value)}
                      />
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginRight="10px"
                      >
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            marginTop: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          onClick={addSpec}
                        >
                          Add
                        </Button>
                        <MDBox>
                          {generatingSpecs && <span style={{ color: "green" }}>Generating...</span>}
                          <span
                            onClick={() => getAISpec()}
                            style={{
                              color: "black",
                              cursor: "pointer",
                              pointerEvents: updating ? "initial" : "none",
                              margin: "10px",
                            }}
                          >
                            <AutoFixHigh />
                          </span>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        style={{
                          height: "100%",
                          overflowY: "auto",
                          backgroundColor: "#dddddd",
                          borderRadius: "7px",
                          marginTop: "15px",
                          maxHeight: "300px",
                        }}
                      >
                        {prod.specs.map((itm, idx) => {
                          return (
                            <MDBox
                              display="flex"
                              flexWrap="wrap"
                              justifyContent="space-between"
                              padding="7px"
                              key={idx + "_" + itm._id}
                              style={{ borderBottom: "solid 1px #cccccc", fontSize: "11pt" }}
                            >
                              <Typography>{itm.name}</Typography>

                              <Icon
                                onClick={() => deleteSpec(itm._id)}
                                style={{
                                  cursor: "pointer",
                                  color: "indianred",
                                  pointerEvents: updating ? "initial" : "none",
                                }}
                              >
                                delete
                              </Icon>
                              <Typography style={{ width: "95%" }}>{itm.detail}</Typography>
                            </MDBox>
                          );
                        })}
                      </MDBox>
                    </MDBox>
                  </Grid>
                  {/* FAQs */}
                  <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"}>FAQs</Typography>
                    <MDBox>
                      <TextField
                        type="text"
                        label="Question"
                        style={{
                          width: "100%",
                          marginBottom: "10px",
                          pointerEvents: updating ? "initial" : "none",
                        }}
                        inputProps={{ maxLength: 50 }}
                        value={faqQuestion}
                        onChange={(e) => setFaqQuestion(e.target.value)}
                      />
                      <TextField
                        type="text"
                        label="ANswer"
                        multiline={true}
                        style={{ width: "100%", pointerEvents: updating ? "initial" : "none" }}
                        value={faqAnswer}
                        onChange={(e) => setFaqAnswer(e.target.value)}
                      />
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginRight="10px"
                      >
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            marginTop: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          onClick={addFaq}
                        >
                          Add
                        </Button>
                        <MDBox>
                          {generatingFAQs && <span style={{ color: "green" }}>Generating...</span>}
                          <span
                            onClick={() => getAIFAQs()}
                            style={{
                              color: "black",
                              cursor: "pointer",
                              pointerEvents: updating ? "initial" : "none",
                              margin: "10px",
                            }}
                          >
                            <AutoFixHigh />
                          </span>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        style={{
                          height: "100%",
                          overflowY: "auto",
                          backgroundColor: "#dddddd",
                          borderRadius: "7px",
                          marginTop: "15px",
                          maxHeight: "250px",
                        }}
                      >
                        {prod.faqs.map((itm, idx) => {
                          return (
                            <MDBox
                              display="flex"
                              flexWrap="wrap"
                              justifyContent="space-between"
                              padding="7px"
                              key={idx + "_" + itm._id}
                              style={{ borderBottom: "solid 1px #cccccc", fontSize: "11pt" }}
                            >
                              <Typography style={{ fontWeight: "bold" }}>{itm.question}</Typography>

                              <Icon
                                onClick={() => deleteFaq(itm._id)}
                                style={{
                                  cursor: "pointer",
                                  color: "indianred",
                                  pointerEvents: updating ? "initial" : "none",
                                }}
                              >
                                delete
                              </Icon>
                              <Typography style={{ width: "95%" }}>{itm.answer}</Typography>
                            </MDBox>
                          );
                        })}
                      </MDBox>
                    </MDBox>
                  </Grid>
                  {/* USPs */}
                  <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"}>Unique Selling Points</Typography>
                    <MDBox>
                      <MDBox display="flex" flexWrap="wrap" justifyContent="center">
                        <TextField
                          type="text"
                          label="Unique Selling Points"
                          style={{
                            width: "100%",
                            marginBottom: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          inputProps={{ maxLength: 50 }}
                          value={usp}
                          onChange={(e) => setUSP(e.target.value)}
                        />
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          marginRight="10px"
                          width="100%"
                        >
                          <Button
                            variant="contained"
                            style={{
                              color: "white",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            onClick={addUSP}
                          >
                            Add
                          </Button>
                          <MDBox>
                            {generatingUSPs && (
                              <span style={{ color: "green" }}>Generating...</span>
                            )}
                            <span
                              onClick={() => getAIUSPs()}
                              style={{
                                color: "black",
                                cursor: "pointer",
                                pointerEvents: updating ? "initial" : "none",
                                margin: "10px",
                              }}
                            >
                              <AutoFixHigh />
                            </span>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        style={{
                          height: "100%",
                          overflowY: "auto",
                          backgroundColor: "#dddddd",
                          borderRadius: "7px",
                          marginTop: "15px",
                          maxHeight: "300px",
                        }}
                      >
                        {prod.USPs.map((itm, idx) => {
                          return (
                            <MDBox
                              display="flex"
                              flexWrap="wrap"
                              justifyContent="space-between"
                              padding="7px"
                              key={idx + "_" + itm}
                              style={{ borderBottom: "solid 1px #cccccc", fontSize: "11pt" }}
                            >
                              <Typography>{itm}</Typography>

                              <Icon
                                onClick={() => deleteUSP(itm)}
                                style={{
                                  cursor: "pointer",
                                  color: "indianred",
                                  pointerEvents: updating ? "initial" : "none",
                                }}
                              >
                                delete
                              </Icon>
                            </MDBox>
                          );
                        })}
                      </MDBox>
                    </MDBox>
                  </Grid>
                  {/* Price Options */}
                  <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"}>Price Options</Typography>
                    <MDBox>
                      <MDBox display="flex" flexWrap="wrap">
                        <TextField
                          type="text"
                          label="Option"
                          style={{
                            width: "60%",
                            marginBottom: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          inputProps={{ maxLength: 50 }}
                          value={option}
                          onChange={(e) => setOption(e.target.value)}
                        />
                        <TextField
                          type="number"
                          label="Price"
                          min={0}
                          style={{ width: "30%", pointerEvents: updating ? "initial" : "none" }}
                          value={optionPrice}
                          onChange={(e) => setOptionPrice(e.target.value)}
                        />
                        <FormGroup
                          style={{
                            marginLeft: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={defaultOption}
                                onChange={(e) => {
                                  setDefaultOption(e.target.checked);
                                }}
                              />
                            }
                            label="Default"
                          />
                        </FormGroup>
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            marginTop: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          onClick={addPriceOption}
                        >
                          Add
                        </Button>
                      </MDBox>
                      <MDBox
                        style={{
                          height: "100%",
                          overflowY: "auto",
                          backgroundColor: "#dddddd",
                          borderRadius: "7px",
                          marginTop: "15px",
                          maxHeight: "250px",
                        }}
                      >
                        {prod.priceOptions.map((itm, idx) => {
                          return (
                            <MDBox
                              display="flex"
                              padding="7px"
                              justifyContent="space-between"
                              key={idx + "_" + itm._id}
                              style={{ borderBottom: "solid 1px #cccccc", fontSize: "11pt" }}
                            >
                              <Typography style={{ fontWeight: "bold", width: "50%" }}>
                                {itm.option}
                              </Typography>
                              <Typography style={{ width: "30%" }}>{itm.price}</Typography>
                              {itm.default ? (
                                <Icon
                                  style={{
                                    cursor: "pointer",
                                    color: "dodgerblue",
                                    pointerEvents: updating ? "initial" : "none",
                                  }}
                                >
                                  checkcircle
                                </Icon>
                              ) : (
                                <Icon
                                  onClick={() => updateDefaultOption(itm._id)}
                                  style={{
                                    cursor: "pointer",
                                    color: "grey",
                                    pointerEvents: updating ? "initial" : "none",
                                  }}
                                >
                                  checkbox
                                </Icon>
                              )}
                              <Typography style={{ marginRight: "40px" }}>
                                <Icon
                                  onClick={() => deleteOption(itm._id)}
                                  style={{
                                    cursor: "pointer",
                                    color: "indianred",
                                    pointerEvents: updating ? "initial" : "none",
                                  }}
                                >
                                  delete
                                </Icon>
                              </Typography>
                            </MDBox>
                          );
                        })}
                      </MDBox>
                    </MDBox>
                  </Grid>
                  {/* Coupons */}
                  <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"}>Coupons</Typography>
                    <MDBox>
                      <MDBox display="flex" flexWrap="wrap">
                        <TextField
                          type="text"
                          label="Name"
                          style={{
                            width: "60%",
                            marginBottom: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          inputProps={{ maxLength: 50 }}
                          value={couponName}
                          onChange={(e) => setCouponName(e.target.value)}
                        />
                        <TextField
                          type="number"
                          label="Discount"
                          min={0}
                          style={{ width: "30%", pointerEvents: updating ? "initial" : "none" }}
                          value={couponDiscount}
                          onChange={(e) => setCouponDiscount(e.target.value)}
                        />
                        <FormGroup
                          style={{
                            marginLeft: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={defaultCoupon}
                                onChange={(e) => {
                                  setDefaultCoupon(e.target.checked);
                                }}
                              />
                            }
                            label="Default"
                          />
                        </FormGroup>
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            marginTop: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          onClick={addCoupon}
                        >
                          Add
                        </Button>
                      </MDBox>
                      <MDBox
                        style={{
                          height: "100%",
                          overflowY: "auto",
                          backgroundColor: "#dddddd",
                          borderRadius: "7px",
                          marginTop: "15px",
                          maxHeight: "250px",
                        }}
                      >
                        {prod.coupons.map((itm, idx) => {
                          return (
                            <MDBox
                              display="flex"
                              padding="7px"
                              justifyContent="space-between"
                              key={idx + "_" + itm._id}
                              style={{ borderBottom: "solid 1px #cccccc", fontSize: "11pt" }}
                            >
                              <Typography style={{ fontWeight: "bold", width: "50%" }}>
                                {itm.name}
                              </Typography>
                              <Typography style={{ width: "30%" }}>{itm.discount}</Typography>
                              <Typography style={{ marginRight: "40px" }}>
                                {copyingCoupon === itm._id ? (
                                  "..."
                                ) : (
                                  <Icon
                                    onClick={() =>
                                      copyCoupon(
                                        "https://" +
                                          profile.url +
                                          "/filter/item/" +
                                          prod._id +
                                          "?coupon=" +
                                          itm._id,
                                        itm._id
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      color: "dodgerblue",
                                    }}
                                    id="edit-btn"
                                  >
                                    copy
                                  </Icon>
                                )}
                              </Typography>
                              {itm.default ? (
                                <Icon
                                  style={{
                                    cursor: "pointer",
                                    color: "dodgerblue",
                                    pointerEvents: updating ? "initial" : "none",
                                  }}
                                >
                                  checkcircle
                                </Icon>
                              ) : (
                                <Icon
                                  onClick={() => updateDefaultCoupon(itm._id)}
                                  style={{
                                    cursor: "pointer",
                                    color: "grey",
                                    pointerEvents: updating ? "initial" : "none",
                                  }}
                                >
                                  checkbox
                                </Icon>
                              )}
                              <Typography style={{ marginRight: "40px" }}>
                                <Icon
                                  onClick={() => deleteCoupon(itm._id)}
                                  style={{
                                    cursor: "pointer",
                                    color: "indianred",
                                    pointerEvents: updating ? "initial" : "none",
                                  }}
                                >
                                  delete
                                </Icon>
                              </Typography>
                            </MDBox>
                          );
                        })}
                      </MDBox>
                    </MDBox>
                  </Grid>

                  {/* Video */}
                  <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"}>Video</Typography>
                    <MDBox display="flex" width="100%" justifyContent="center">
                      <MDInput
                        type="text"
                        size="small"
                        label="Video Id"
                        m={2}
                        style={{ width: "100%", pointerEvents: updating ? "initial" : "none" }}
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                      />{" "}
                      <MDButton
                        color="info"
                        size="small"
                        style={{ marginLeft: "10px", pointerEvents: updating ? "initial" : "none" }}
                        onClick={() => setLinkHandler()}
                        width="20%"
                      >
                        {addingLink ? "Setting..." : <span>Add</span>}
                      </MDButton>
                    </MDBox>
                    {prod.videoLink && (
                      <MDBox
                        width="90%"
                        p={1}
                        style={{
                          margin: "5px auto",
                          border: "solid 1px #bbb",
                          borderRadius: "7px",
                        }}
                      >
                        <iframe
                          style={{ aspectRatio: "3/2" }}
                          width="100%"
                          src={prod.videoLink}
                        ></iframe>
                        <MDBox>
                          <MDButton
                            onClick={() => setProd({ ...prod, videoLink: "" })}
                            size="small"
                            style={{ backgroundColor: "indianred", color: "white" }}
                          >
                            {<Delete />}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
            )}
            {/* Articles Div */}
            {active === 3 && (
              <Grid container spacing={2} onClick={notUpdating}>
                {/* Add New Article */}
                <Grid item xs={12} md={6}>
                  <MDBox>
                    <Typography fontWeight={"bold"}>Add Article</Typography>
                    <MDBox>
                      <MDBox mb="15px" width="100%">
                        {/* <FormControl width="100%"> */}
                        <InputLabel id="demo-simple-select-label" style={{ marginLeft: "10px" }}>
                          Article Type
                        </InputLabel>
                        <Select
                          value={articleType}
                          label="Article Type"
                          style={{
                            width: "100%",
                            padding: "8px",
                            marginTop: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          onChange={(e) => setArticleType(e.target.value)}
                        >
                          <MenuItem value={"article"}>Article</MenuItem>
                          <MenuItem value={"counter"}>Counter</MenuItem>
                          <MenuItem value={"list"}>List</MenuItem>
                          <MenuItem value={"title"}>Title</MenuItem>
                          <MenuItem value={"link"}>Link</MenuItem>
                          <MenuItem value={"carousel"}>Carousel</MenuItem>
                          <MenuItem value={"video"}>Video</MenuItem>
                          <MenuItem value={"banner-video"}>Video Banner</MenuItem>
                        </Select>
                        {/* </FormControl> */}
                      </MDBox>
                      {/* Article section */}
                      {articleType === "article" && (
                        <MDBox display="flex" flexDirection="column">
                          <MDBox width="100%" textAlign="left" display="flex" alignItems="center">
                            <img
                              src={
                                isFile(newArticle.image)
                                  ? getURL(newArticle.image)
                                  : newArticle.image
                              }
                              style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                            />
                            <Typography
                              variant="body"
                              style={{ fontSize: "10pt", marginLeft: "15px" }}
                            >
                              Article Image
                            </Typography>
                            <MDInput
                              size="small"
                              type="file"
                              display="none"
                              id="icon-select"
                              style={{
                                pointerEvents: updating ? "initial" : "none",
                                marginLeft: "15px",
                              }}
                              onChange={(e) => {
                                setNewArticle({ ...newArticle, image: e.target.files[0] });
                              }}
                            />
                          </MDBox>
                          <TextField
                            type="text"
                            label="Article Title"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 100 }}
                            value={newArticle.title ? newArticle.title : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, title: e.target.value })
                            }
                          />

                          <TextField
                            type="text"
                            label="Content"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 500 }}
                            multiline={true}
                            value={newArticle.content ? newArticle.content : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, content: e.target.value })
                            }
                          />
                        </MDBox>
                      )}
                      {/* Title section */}
                      {articleType === "title" && (
                        <MDBox display="flex" flexDirection="column">
                          <MDBox width="100%" textAlign="left" display="flex" alignItems="center">
                            <img
                              src={
                                isFile(newArticle.image)
                                  ? getURL(newArticle.image)
                                  : newArticle.image
                              }
                              style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                            />
                            <Typography
                              variant="body"
                              style={{ fontSize: "10pt", marginLeft: "15px" }}
                            >
                              Background Image
                            </Typography>
                            <MDInput
                              size="small"
                              type="file"
                              display="none"
                              id="icon-select"
                              style={{
                                pointerEvents: updating ? "initial" : "none",
                                marginLeft: "15px",
                              }}
                              onChange={(e) => {
                                setNewArticle({ ...newArticle, image: e.target.files[0] });
                              }}
                            />
                          </MDBox>
                          <TextField
                            type="text"
                            label="Title"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 100 }}
                            value={newArticle.title ? newArticle.title : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, title: e.target.value })
                            }
                          />

                          <TextField
                            type="text"
                            label="Subtitle"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 150 }}
                            value={newArticle.subtitle ? newArticle.subtitle : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, subtitle: e.target.value })
                            }
                          />
                        </MDBox>
                      )}
                      {/* Counter div */}
                      {articleType === "counter" && (
                        <MDBox display="flex" flexDirection="column">
                          <MDBox display="flex" justifyContent="space-between">
                            <TextField
                              type="text"
                              label="Title"
                              style={{
                                width: "50%",
                                marginTop: "10px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              inputProps={{ maxLength: 50 }}
                              value={listItem.title ? listItem.title : ""}
                              onChange={(e) => setListItem({ ...listItem, title: e.target.value })}
                            />
                            <TextField
                              type="text"
                              label="Prefix"
                              style={{
                                width: "10%",
                                marginTop: "10px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              inputProps={{ maxLength: 3 }}
                              value={listItem.prefix ? listItem.prefix : ""}
                              onChange={(e) => setListItem({ ...listItem, prefix: e.target.value })}
                            />
                            <TextField
                              type="number"
                              label="Value"
                              style={{
                                width: "30%",
                                marginTop: "10px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              inputProps={{
                                min: -999, // set the minimum value
                                max: 999, // set the maximum value
                              }}
                              value={listItem.value ? listItem.value : ""}
                              onChange={(e) => setListItem({ ...listItem, value: e.target.value })}
                            />
                            <TextField
                              type="text"
                              label="Suffix"
                              style={{
                                width: "10%",
                                marginTop: "10px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              inputProps={{ maxLength: 30 }}
                              value={listItem.suffix ? listItem.suffix : ""}
                              onChange={(e) => setListItem({ ...listItem, suffix: e.target.value })}
                            />
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                marginTop: "10px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              onClick={() => {
                                if (listItem.value) {
                                  if (!newArticle.values) newArticle.values = [];
                                  setNewArticle({
                                    ...newArticle,
                                    values: [...newArticle.values, listItem],
                                  });
                                  setListItem({});
                                }
                              }}
                            >
                              Add
                            </Button>
                          </MDBox>
                          <MDBox
                            p="10px"
                            style={{
                              backgroundColor: "#dedede",
                              borderRadius: "8px",
                              margin: "10px 0",
                              overflowY: "auto",
                              maxHeight: "300px",
                            }}
                          >
                            {newArticle.values &&
                              newArticle.values.map((li, idx) => {
                                return (
                                  <Typography key={idx}>
                                    {(li.prefix ? li.prefix : "") +
                                      " " +
                                      li.value +
                                      (li.suffix ? li.suffix : "") +
                                      ": " +
                                      li.title}{" "}
                                    <Delete
                                      style={{ color: "indianred" }}
                                      onClick={() =>
                                        setNewArticle({
                                          ...newArticle,
                                          values: newArticle.values.filter((it, id) => idx !== id),
                                        })
                                      }
                                    />
                                  </Typography>
                                );
                              })}
                          </MDBox>
                        </MDBox>
                      )}
                      {/* List div */}
                      {articleType === "list" && (
                        <MDBox display="flex" flexDirection="column">
                          <TextField
                            type="text"
                            label="List Title"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 100 }}
                            value={newArticle.title ? newArticle.title : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, title: e.target.value })
                            }
                          />
                          <TextField
                            type="text"
                            label="List Intro"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 200 }}
                            value={newArticle.intro ? newArticle.intro : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, intro: e.target.value })
                            }
                          />
                          <MDBox display="flex" justifyContent="space-between">
                            <TextField
                              type="text"
                              label="List Item"
                              style={{
                                width: "90%",
                                marginTop: "10px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              inputProps={{ maxLength: 200 }}
                              value={listText}
                              onChange={(e) => setListText(e.target.value)}
                            />

                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                marginTop: "10px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              onClick={() => {
                                if (listText) {
                                  if (!newArticle.items) newArticle.items = [];
                                  setNewArticle({
                                    ...newArticle,
                                    items: [...newArticle.items, listText],
                                  });
                                  setListText("");
                                }
                              }}
                            >
                              Add
                            </Button>
                          </MDBox>
                          <MDBox
                            p="10px"
                            style={{
                              backgroundColor: "#dedede",
                              borderRadius: "8px",
                              margin: "10px 0",
                              overflowY: "auto",
                              maxHeight: "300px",
                            }}
                          >
                            {newArticle.items &&
                              newArticle.items.map((li, idx) => {
                                return (
                                  <Typography key={idx} style={{ borderBottom: "solid 1px grey" }}>
                                    {li}
                                    <Delete
                                      style={{ color: "indianred", marginLeft: "10px" }}
                                      onClick={() =>
                                        setNewArticle({
                                          ...newArticle,
                                          items: newArticle.items.filter((it, id) => idx !== id),
                                        })
                                      }
                                    />
                                  </Typography>
                                );
                              })}
                          </MDBox>
                        </MDBox>
                      )}
                      {/* Link section */}
                      {articleType === "link" && (
                        <MDBox display="flex" flexDirection="column">
                          <TextField
                            type="text"
                            label="Link Text"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 100 }}
                            value={newArticle.text ? newArticle.text : ""}
                            onChange={(e) => setNewArticle({ ...newArticle, text: e.target.value })}
                          />

                          <TextField
                            type="text"
                            label="Link Url"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 200 }}
                            value={newArticle.url ? newArticle.url : ""}
                            onChange={(e) => setNewArticle({ ...newArticle, url: e.target.value })}
                          />
                        </MDBox>
                      )}
                      {/* Carousel */}
                      {articleType === "carousel" && (
                        <MDBox display="flex" flexDirection="column">
                          <TextField
                            type="text"
                            label="Carousel Title"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              marginBottom: "20px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 50 }}
                            value={newArticle.title ? newArticle.title : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, title: e.target.value })
                            }
                          />
                          <MDBox width="100%" textAlign="left" display="flex" alignItems="center">
                            <img
                              src={
                                isFile(carouselItem.image)
                                  ? getURL(carouselItem.image)
                                  : carouselItem.image
                              }
                              style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                            />
                            <Typography
                              variant="body"
                              style={{ fontSize: "10pt", marginLeft: "15px" }}
                            >
                              Item Image
                            </Typography>
                            <MDInput
                              size="small"
                              type="file"
                              display="none"
                              id="icon-select"
                              style={{
                                pointerEvents: updating ? "initial" : "none",
                                marginLeft: "15px",
                              }}
                              onChange={(e) => {
                                setCarouselItem({ ...carouselItem, image: e.target.files[0] });
                              }}
                            />
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between">
                            <TextField
                              type="text"
                              label="Title"
                              style={{
                                width: "100%",
                                marginTop: "10px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              inputProps={{ maxLength: 50 }}
                              value={carouselItem.title ? carouselItem.title : ""}
                              onChange={(e) =>
                                setCarouselItem({ ...carouselItem, title: e.target.value })
                              }
                            />
                            <TextField
                              type="text"
                              label="Description"
                              style={{
                                width: "100%",
                                marginTop: "10px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              inputProps={{ maxLength: 100 }}
                              value={carouselItem.description ? carouselItem.description : ""}
                              onChange={(e) =>
                                setCarouselItem({ ...carouselItem, description: e.target.value })
                              }
                            />

                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                marginTop: "10px",
                                pointerEvents: updating ? "initial" : "none",
                              }}
                              onClick={() => {
                                if (Object.keys(carouselItem).length > 0) {
                                  if (!newArticle.values) newArticle.values = [];
                                  setNewArticle({
                                    ...newArticle,
                                    values: [...newArticle.values, carouselItem],
                                  });
                                  setCarouselItem({});
                                }
                              }}
                            >
                              Add
                            </Button>
                          </MDBox>
                          <MDBox
                            p="10px"
                            style={{
                              backgroundColor: "#dedede",
                              borderRadius: "8px",
                              margin: "10px 0",
                              overflowY: "auto",
                              maxHeight: "300px",
                            }}
                          >
                            {newArticle.values &&
                              newArticle.values.map((li, idx) => {
                                return (
                                  <MDBox display="flex" marginBottom="10px">
                                    {li.image && (
                                      <img
                                        src={isFile(li.image) ? getURL(li.image) : li.image}
                                        style={{
                                          maxWidth: "100px",
                                          maxHeight: "100px",
                                          pointerEvents: updating ? "initial" : "none",
                                        }}
                                      />
                                    )}
                                    <MDBox width="100%">
                                      <MDTypography key={idx} width="100%">
                                        {li.title}
                                        <Delete
                                          style={{ color: "indianred" }}
                                          onClick={() =>
                                            setNewArticle({
                                              ...newArticle,
                                              values: newArticle.values.filter(
                                                (it, id) => idx !== id
                                              ),
                                            })
                                          }
                                        />
                                      </MDTypography>
                                      <MDTypography key={idx} width="100%">
                                        {li.description}
                                      </MDTypography>
                                    </MDBox>
                                  </MDBox>
                                );
                              })}
                          </MDBox>
                        </MDBox>
                      )}
                      {/* Video section */}
                      {articleType === "video" && (
                        <MDBox display="flex" flexDirection="column">
                          <TextField
                            type="text"
                            label="Title"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 100 }}
                            value={newArticle.title ? newArticle.title : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, title: e.target.value })
                            }
                          />

                          <TextField
                            type="text"
                            label="Caption"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 150 }}
                            value={newArticle.caption ? newArticle.caption : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, caption: e.target.value })
                            }
                          />
                          <TextField
                            type="text"
                            label="Video ID"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 150 }}
                            value={newArticle.videoId ? newArticle.videoId : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, videoId: e.target.value })
                            }
                          />
                          {newArticle.videoId && (
                            <MDBox>
                              <iframe
                                style={{ aspectRatio: "3/2", marginBottom: "20px" }}
                                width="100%"
                                src={"https://www.youtube.com/embed/" + newArticle.videoId}
                              ></iframe>
                            </MDBox>
                          )}
                        </MDBox>
                      )}
                      {/* Video-Banner section */}
                      {articleType === "banner-video" && (
                        <MDBox display="flex" flexDirection="column">
                          <TextField
                            type="text"
                            label="Headline"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 100 }}
                            value={newArticle.headline ? newArticle.headlne : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, headline: e.target.value })
                            }
                          />

                          <TextField
                            type="text"
                            label="Tagline"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 150 }}
                            value={newArticle.tagline ? newArticle.tagline : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, tagline: e.target.value })
                            }
                          />
                          <TextField
                            type="text"
                            label="Manual Link"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 150 }}
                            value={newArticle.manualLink ? newArticle.manualLink : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, manualLink: e.target.value })
                            }
                          />
                          <TextField
                            type="text"
                            label="Video ID"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: updating ? "initial" : "none",
                            }}
                            inputProps={{ maxLength: 150 }}
                            value={newArticle.videoId ? newArticle.videoId : ""}
                            onChange={(e) =>
                              setNewArticle({ ...newArticle, videoId: e.target.value })
                            }
                          />
                          {newArticle.videoId && (
                            <MDBox>
                              <iframe
                                style={{ aspectRatio: "3/2", marginBottom: "20px" }}
                                width="100%"
                                src={"https://www.youtube.com/embed/" + newArticle.videoId}
                              ></iframe>
                            </MDBox>
                          )}
                        </MDBox>
                      )}

                      <Button
                        variant="contained"
                        style={{
                          color: "white",
                          marginTop: "10px",
                          pointerEvents: updating ? "initial" : "none",
                        }}
                        onClick={addArticle}
                      >
                        {addingArticle}
                      </Button>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <Typography fontWeight={"bold"}>Landing Pages</Typography>
                    <MDBox display="flex">
                      <TextField
                        value={newlandingPage}
                        onChange={(e) => setNewLandingPage(e.target.value)}
                        style={{
                          pointerEvents: updating ? "initial" : "none",
                          width: "100%",
                        }}
                        inputProps={{ maxLength: 15 }}
                      />
                      <MDButton
                        onClick={addLandingPage}
                        style={{ backgroundColor: "dodgerblue", color: "white" }}
                      >
                        {addingLp ? "..." : "Add"}
                      </MDButton>
                    </MDBox>
                    <MDBox>
                      <MDBox mb="15px" mt="15px" width="100%">
                        {/* <FormControl width="100%"> */}
                        <InputLabel id="demo-simple-select-label" style={{ marginLeft: "10px" }}>
                          Landing Page
                        </InputLabel>
                        <Select
                          value={currentLP}
                          label="Article Type"
                          style={{
                            width: "100%",
                            padding: "8px",
                            marginTop: "10px",
                            pointerEvents: updating ? "initial" : "none",
                          }}
                          onChange={(e) => setCurrentLP(e.target.value)}
                        >
                          {prod.landingPages.map((lp, idx) => {
                            return (
                              <MenuItem value={lp.name} key={lp.name + "-" + idx}>
                                {lp.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {/* </FormControl> */}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Grid>
                {/* Existing Articles */}
                <Grid item xs={12} md={6}>
                  <Typography fontWeight={"bold"}>{currentLP}</Typography>
                  <MDBox width="100%" display="flex">
                    <Typography fontSize={"10pt"}>
                      <Switch
                        style={{
                          pointerEvents: updating ? "initial" : "none",
                        }}
                        className="small-switch"
                        checked={landigPage.miniHeader}
                        onChange={toggleMiniHeader}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                        m={1}
                      />
                      Minify Header
                    </Typography>
                    <Typography fontSize={"10pt"}>
                      <Switch
                        style={{
                          pointerEvents: updating ? "initial" : "none",
                        }}
                        className="small-switch"
                        checked={landigPage.default}
                        onChange={toggleDefault}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                        m={1}
                      />
                      Default
                    </Typography>
                    <Typography
                      style={{
                        marginLeft: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {copyingLpLink ? (
                        "..."
                      ) : (
                        <>
                          <Icon
                            onClick={() =>
                              copyLandingPageLink(
                                "https://" +
                                  profile.url +
                                  "/filter/item/" +
                                  prod._id +
                                  "?lp=" +
                                  currentLP,
                                currentLP
                              )
                            }
                            style={{
                              cursor: "pointer",
                              color: "dodgerblue",
                            }}
                            id="edit-btn"
                          >
                            link
                          </Icon>
                          <span style={{ fontSize: "10pt", marginLeft: "5px" }}>Copy Link</span>
                        </>
                      )}
                    </Typography>
                  </MDBox>
                  <MDBox width="100%" style={{ overflowY: "auto", maxHeight: "500px" }}>
                    {prod.landingPages.find((lp) => lp.name === currentLP) &&
                      prod.landingPages
                        .filter((lp) => lp.name === currentLP)[0]
                        .articles.map((article, idx) => {
                          return (
                            <div key={article._id + "-" + idx}>
                              <Segment
                                article={article}
                                setProd={setProd}
                                updating={updating}
                                idx={idx}
                                prod={prod}
                                currentLp={currentLP}
                              />
                            </div>
                          );
                        })}
                  </MDBox>
                </Grid>
              </Grid>
            )}
            {/* Edit Cancel & Delete Buttons */}
            <MDBox
              minWidth="150px"
              width="100%"
              display="flex"
              my={1}
              justifyContent="space-between"
            >
              <MDBox>
                <MDButton
                  size="small"
                  style={{ backgroundColor: "green", color: "white" }}
                  onClick={updateProductHandler}
                  id="edit-btn"
                >
                  {isUpdating ? "..." : <Icon id="edit-btn">{updating ? "done" : "edit"}</Icon>}
                </MDButton>
              </MDBox>
              <MDBox>
                <MDButton
                  size="small"
                  disabled={!updating}
                  style={{ backgroundColor: "goldenrod", color: "white" }}
                  onClick={cancelUpdating}
                >
                  <Icon>cancel</Icon>
                </MDButton>
              </MDBox>
              <MDBox ml={1}>
                <MDButton
                  size="small"
                  style={{ backgroundColor: "indianred", color: "white" }}
                  onClick={() => deleteItemHandler()}
                  id="edit-btn"
                >
                  <Icon id="edit-btn">delete</Icon>
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox
          minWidth="320px"
          borderRadius="10px"
          className="product"
          m={2}
          p={1}
          style={{
            boxShadow: "0 0 5px grey",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MDTypography>You have not added any products yet</MDTypography>
        </MDBox>
      )}
      {renderSuccessSB}
      {renderErrorSB}
      {renderWarningSB}
      <MDDialog
        title={"Confirm Delete"}
        content={"Are you sure you want to delete this item? This is action is irreversible"}
        submitText={"Delete"}
        open={openModal}
        setOpen={setOpenModal}
        onAccept={deleteAccepted}
      />
      <MDDialog
        title={"Unsaved Changes"}
        content={
          "You currently have unsaved changes. Kindly save or discard the changes before proceeding"
        }
        submitText={"Okay"}
        open={openUpdateModal}
        setOpen={setOpenUpdateModal}
        onAccept={() => {}}
      />
    </>
  );
}

export default Product;
