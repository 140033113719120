import axios from "../axios.config";

export const validateLoggedIn = () => {
  let res = window.localStorage.getItem("token");
  let lastDate = window.localStorage.getItem("last_login");
  if (res && lastDate) {
    var date1 = new Date();
    var date2 = new Date(lastDate);

    var Difference_In_Time = date1.getTime() - date2.getTime();

    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days > 1) {
      res = false;
    } else {
      res = true;
    }
  } else {
    res = false;
  }

  return res;
};

export const login = async (data) => {
  let res = await axios.post("/login", data);
  return res.data;
};

export const refreshToken = (data) => {
  let { id } = data;
  return axios.post("/refresh-login/" + id, {
    token: window.localStorage.getItem("token"),
  });
};

export const resetPassword = async (payload) => {
  try {
    let res = await axios.post("/reset-password", payload);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const resetPasswordLink = async (payload) => {
  try {
    let res = await axios.post("/admin/request-reset-password", payload);
    return res.data;
  } catch (err) {
    return err;
  }
};
