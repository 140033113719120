import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const validateLoggedIn = () => {
  let res = window.localStorage.getItem("token");
  let lastDate = window.localStorage.getItem("last_login");
  if (res && lastDate) {
    var date1 = new Date();
    var date2 = new Date(lastDate);

    var Difference_In_Time = date1.getTime() - date2.getTime();

    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days > 1) {
      res = false;
    } else {
      res = true;
    }
  } else {
    res = false;
  }

  return res;
};

const initialState = {
  store: window.localStorage.getItem("store") ? window.localStorage.getItem("store") : {},
  error: "",
  loggedIn: validateLoggedIn(),
  isResetting: false,
};

export const login = createAsyncThunk("auth/login", (data) => {
  return axios.post("/login", data);
});

export const refreshToken = createAsyncThunk("auth/refreshToken", (data) => {
  let { id } = data;
  return axios.post("/refresh-login/" + id, {
    token: window.localStorage.getItem("token"),
  });
});

export const resetPassword = createAsyncThunk(
  "auth/reset",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.post("/reset-password", payload);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resetPasswordLink = createAsyncThunk(
  "auth/resetLink",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.post("/admin/request-reset-password", payload);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const logoutHandler = (state, action) => {
  if (action.payload.response.status === 403 || action.payload.response.status === 401) {
    state.loggedIn = false;
    window.localStorage.clear();
  }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout() {
      window.localStorage.clear();
      window.localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.error = "";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = "";
      let res = action.payload;

      if (res.data.success) {
        state.store = res.data.data;
        window.localStorage.setItem("token", res.data.accessToken);
        window.localStorage.setItem("last_login", new Date());
        window.localStorage.setItem("store", JSON.stringify(res.data.data));
        state.loggedIn = true;
      } else {
        state.error = "Invalid Credentials";
      }
    });
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false;
      state.error = "Invalid Credentials";
    });
    builder.addCase(refreshToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = "";
      let res = action.payload;

      if (res.data.success) {
        window.localStorage.setItem("token", res.data.accessToken);
        window.localStorage.setItem("last_login", new Date());
      } else {
        window.localStorage.removeItem("token");
        store.loggedIn = false;
      }
    });
    builder.addCase(refreshToken.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase("products/getNextBatch/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("profile/fetchFullProfile/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("profile/account/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("profile/updateProfile/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("profile/updateMpesaTill/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("profile/updateMpesaPaybill/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("profile/deleteMpesaTill/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("profile/deleteMpesaPaybill/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("products/addProduct/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("products/getCategories/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("products/getGenderizable/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("products/getWearables/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("product/deleteProduct/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("product/fetchProduct/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("product/updateProduct/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("orders/getOrders/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("orders/setStatus/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("delivery/addOption/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("delivery/deleteOption/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("delivery/getDeliveries/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("dashboard/statistics/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("product/removeFeature/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("product/addFeature/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("promotions/add/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("promotions/delete/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("promotions/get/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("invoices/get/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("invoices/pay/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("policies/fetch/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("policies/update/rejected", (state, action) => {
      logoutHandler(state, action);
    });
    builder.addCase("packages/get/rejected", (state, action) => {
      logoutHandler(state, action);
    });
  },
});

// Action creators are generated for each case reducer function
export const { logout } = authSlice.actions;

export default authSlice.reducer;
