import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {
  Autocomplete,
  Checkbox,
  FilledInput,
  FormControlLabel,
  Icon,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "redux/features/profileSlice";
import MDSnackbar from "components/MDSnackbar";
// import { addOption } from "redux/features/deliverySlice";
import { counties } from "assets/data/counties";
import { getSubCounties } from "assets/data/counties";
import { CheckBox } from "@mui/icons-material";
import { addOption } from "redux/routes/delivery";

function Deliveries({ setLocations }) {
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const isAdding = useSelector((state) => state.delivery.isAdding);
  const [subcounties, setSubCounties] = useState([]);
  const [values, setValues] = useState({
    county: "",
    subcounty: "",
    courier: "",
    price: "",
    description: "",
    time: "",
    payOnDelivery: false,
  });

  useEffect(() => {
    let subcts = getSubCounties(values.county);
    if (values.county.toLowerCase() === "nairobi") {
      subcts.push("Mtaani");
      subcts.push("Mashinani");
    }
    setSubCounties(subcts);
  }, [values.county]);

  // const dispatch = useDispatch();

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={"Deliveries"}
      content="Delivery Added"
      dateTime="just now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bggreen="true"
    />
  );

  const handleAddLocation = async () => {
    if (
      values.county === "" ||
      values.subcounty === "" ||
      values.courier === "" ||
      values.description === "" ||
      values.time === ""
    ) {
      setErr("Fill in all fields");
      openErrorSB();
      return;
    }
    if (values.time < 0) {
      setErr("The time cannot be less than 0");
      openErrorSB();
      return;
    }
    if (values.price < 0) {
      setErr("The price cannot be less than 0");
      openErrorSB();
      return;
    }
    if (values.price > 999999) {
      setErr("The price cannot be more than 999,999");
      openErrorSB();
      return;
    }
    let res = await addOption(values);
    if (res.success) {
      setLocations(res.data);
      setValues({
        county: "",
        subcounty: "",
        courier: "",
        price: 0,
        description: "",
        time: "",
        payOnDelivery: false,
      });
      openSuccessSB();
    }
  };
  let [err, setErr] = useState("");
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={"Deliveries"}
      content={err}
      dateTime="1 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgindianred="true"
    />
  );
  return (
    <MDBox
      // minWidth="320px"
      // width="47%"
      borderRadius="10px"
      // display="flex"
      // flexDirection="column"
      // justify="center"
      // alignItems="center"
      className="profile"
      height="100%"
      // m={2}
      p={1}
      style={{ boxShadow: "0 0 5px grey" }}
    >
      <MDBox display="flex" width="100%" justifyContent="space-evenly" flexWrap="wrap">
        <MDTypography width="100%">Add Delivery Location</MDTypography>
      </MDBox>
      <MDBox
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <MDBox width="100%" pt={2} textAlign="center">
          <Autocomplete
            size="small"
            disablePortal={true}
            isOptionEqualToValue={(option, value) => option === value}
            options={counties}
            renderInput={(params) => <TextField {...params} label="Select County" />}
            value={values.county}
            style={{
              width: "100%",
              margin: "18px auto",
            }}
            onChange={(e, v) => setValues({ ...values, county: v })}
          />
          <Autocomplete
            size="small"
            disablePortal={true}
            isOptionEqualToValue={(option, value) => option === value}
            options={subcounties}
            renderInput={(params) => <TextField {...params} label="Select Sub-County" />}
            value={values.subcounty}
            style={{
              width: "100%",
              margin: "18px auto",
            }}
            onChange={(e, v) => setValues({ ...values, subcounty: v })}
          />

          <TextField
            size="small"
            label="Courier Name"
            value={values.courier}
            inputProps={{ maxLength: 50 }}
            type="text"
            style={{ width: "100%", margin: "10px auto" }}
            onChange={(e) => setValues({ ...values, courier: e.target.value })}
          />
          <MDInput
            size="small"
            label="Delivery Cost"
            inputProps={{ min: 0, max: 999999 }}
            value={values.price}
            type="number"
            style={{ width: "100%", margin: "10px auto" }}
            onChange={(e) => setValues({ ...values, price: e.target.value })}
          />
          <MDBox style={{ overflowY: "auto", paddingTop: "10px" }}>
            <TextField
              size="small"
              label="Max Delivery Time (Hours)"
              value={values.time}
              type="number"
              style={{ width: "100%" }}
              onChange={(e) => setValues({ ...values, time: e.target.value })}
            />
          </MDBox>
          <MDBox
            style={{
              overflowY: "auto",
              padding: "10px",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <FormControlLabel
              label="Payment on delivery"
              control={
                <Checkbox
                  checked={values.payOnDelivery}
                  onChange={(e) => setValues({ ...values, payOnDelivery: e.target.checked })}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                />
              }
            />
          </MDBox>
          <MDBox style={{ height: "100px", overflowY: "auto", paddingTop: "10px" }}>
            <TextField
              size="small"
              label="Pickup Location Description"
              multiline={true}
              value={values.description}
              inputProps={{ maxLength: 500 }}
              type="text"
              style={{ width: "100%" }}
              onChange={(e) => setValues({ ...values, description: e.target.value })}
            />
          </MDBox>
        </MDBox>
        <MDBox style={{ textAlign: "center" }} p="10px">
          <MDButton
            style={{ backgroundColor: "dodgerblue", color: "white" }}
            onClick={handleAddLocation}
          >
            {isAdding ? "Adding..." : "Add Location"}
          </MDButton>
        </MDBox>
      </MDBox>
      {renderSuccessSB}
      {renderErrorSB}
    </MDBox>
  );
}

export default Deliveries;
