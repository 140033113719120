import MDBox from "components/MDBox";
import React, { useEffect, useRef, useState } from "react";

const apiKey = "AIzaSyBt3f-2JW44v7THDfAreAJc3CyL78eP3IE";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

// load google map api js
function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });

  return address;
};

function MapsAddress({ value, label, style, oldVal, setLocation }) {
  const searchInput = useRef(null);
  const [address, setAddress] = useState("yoh");
  useEffect(() => {
    setAddress(value);
  }, [value]);

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      setAddress(searchInput.current.value);
      let e = { target: { value: searchInput.current.value } };
      setLocation(searchInput.current.value);
      console.log(searchInput.current.value, place, extractAddress(place));
    }

    // setAddress(extractAddress(place));
    // onChange({ target: { value: place.name } });
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current, {
      fields: ["address_components", "geometry", "name"],
      componentRestrictions: { country: "KE" },
    });
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.componentRestrictions = { country: "KE" };
    autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
  };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  return (
    <MDBox style={{ ...style }} className={value === oldVal ? "" : "input-modified"}>
      <MDBox className="search" style={{ position: "relative" }}>
        <fieldset style={{ borderRadius: "6px", borderWidth: "1px", padding: "0", height: "45px" }}>
          <legend
            style={{
              textAlign: "left",
              fontSize: "8pt",
              marginLeft: "10px",
              color: "gray",
              padding: "0 3px",
            }}
          >
            {label}
          </legend>
          <input
            ref={searchInput}
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              setLocation(e.target.value);
              console.log("->", e.target.value);
            }}
            type="text"
            label={label}
            size="small"
            style={{
              all: "unset",
              width: "95%",
              textAlign: "left",
              marginLeft: "10px",
              fontSize: "9pt",
              marginTop: "-10px",
              position: "absolute",
              left: "0",
              top: "28px",
            }}
          />
        </fieldset>
        {/* <button onClick={findMyLocation}></button> */}
      </MDBox>

      {/* <div className="address">
          <p>
            City: <span>{address.city}</span>
          </p>
          <p>
            State: <span>{address.state}</span>
          </p>
          <p>
            Zip: <span>{address.zip}</span>
          </p>
          <p>
            Country: <span>{address.country}</span>
          </p>
        </div> */}
    </MDBox>
  );
}

export default MapsAddress;
