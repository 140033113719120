import axios from "../axios.config";

export const getPromotions = async (data) => {
  try {
    let res = await axios.get("/promotions");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const addPromotion = async (data) => {
  try {
    let res = await axios.post("/promotions/add", data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deletePromotion = async (data) => {
  try {
    let res = await axios.post("/promotions/delete", data);
    return res.data;
  } catch (err) {
    return err;
  }
};
