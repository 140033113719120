import axios from "../axios.config";

// Set upload progress not properly set up
export const addProduct = async (payload) => {
  try {
    // const onUploadProgress = (progressEvent) => {
    //   const { loaded, total } = progressEvent;
    //   let percent = Math.floor((loaded * 100) / total);
    //   dispatch(setUploadProgress(percent));

    //   if (percent < 100) {
    //     // console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
    //   }
    // };

    let ret = await axios.post("/product", payload, {
      /*onUploadProgress*/
    });
    return ret.data;
  } catch (err) {
    return rejectWithValue(err);
  }
};

export const getCategories = async () => {
  try {
    let res = await axios.get("/categories");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getGenderizable = async () => {
  try {
    let res = await axios.get("/genderizable");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getWearables = async () => {
  try {
    let res = await axios.get("/wearables");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getNextBatch = async (payload) => {
  const state = getState();
  try {
    return await axios.get("/products/full/" + state.products.batchNo);
  } catch (err) {
    return err;
  }
};

export const getAllProducts = async () => {
  try {
    let res = await axios.get("/products/all");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getAllProductsList = async () => {
  try {
    let res = await axios.get("/products/list/all");
    return res.data;
  } catch (err) {
    return err;
  }
};
