import axios from "axios";

let production = process.env.REACT_APP_PRODUCTION;

const baseURL =
  production === "true"
    ? "https://server.bunikasolutions.com/business/zidika"
    : "http://localhost:3001/business/zidika";
const backupUrl1 = "https://bunika.cyclic.app/business/zidika"; //"https://bunika-api.onrender.com/business/zidika";
let instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (config) => {
    return config;
  },

  async (error) => {
    let config = error.config;

    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      window.localStorage.removeItem("token");
      return Promise.reject(error);
    }

    if (!config || config.baseURL === backupUrl1) {
      return Promise.reject(error);
    }
    if (config.response) {
      return config;
    }

    config.baseURL = backupUrl1;

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, config.retryDelay || 1000);
    });

    try {
      const retryResponse = await instance(config);
      return retryResponse;
    } catch (retryError) {
      return Promise.reject(retryError);
    }
  }
);

export default instance;
