/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Typography } from "@mui/material";

// Material Dashboard 2 React examples
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { useEffect, useState } from "react";
import Invoices from "./components/billing";
import Packages from "./components/packages";
import Marketing from "./components/marketing";
import { getInvoices } from "redux/routes/billing";
import { useGlobalContext } from "context/context";

function Billing() {
  const fetchInvoices = async () => {
    let res = await getInvoices();

    setLastPayment(res.data.lastPayment);
    setSubscription(res.data.subscription);
    setInvoices(res.data.invoices.reverse());
    setPackage(res.data.package);
    setStatus(res.data.status);
  };
  useEffect(() => {
    fetchInvoices();
  }, []);
  const [lastPayment, setLastPayment] = useState({ year: 0, month: 0, day: 0 });
  const [subscription, setSubscription] = useState("");
  const [mpackage, setPackage] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [status, setStatus] = useState({});

  let [validUntil, setValidUntil] = useState("");
  let [daysRem, setDaysRem] = useState(10);
  let { profile } = useGlobalContext();

  useEffect(() => {
    var mydate = new Date(lastPayment.year, lastPayment.month - 1, lastPayment.day);
    mydate.setDate(mydate.getDate() + 31);
    if (lastPayment.year === 0) setValidUntil("");
    else setValidUntil(mydate.toDateString());

    if (subscription === "one-off") setValidUntil("Infinity");

    var date2 = new Date();
    const diffTime = mydate - date2;
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 0) {
      diffDays = 0;
    }
    setDaysRem(diffDays);
    if (subscription === "one-off") {
      setValidUntil("Infinity");
      setDaysRem("");
    }
  }, [lastPayment]);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const section = urlParams.get("section");

  const [active, setActive] = useState(section === "invoices" ? 3 : 1);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Payments History
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MDBox display="flex" justifyContent="space-between" textAlign="right" px={4}>
                  <MDBox fontSize="11pt">
                    Package: {mpackage + (status.subscription === "trial" && " (Trial)")}
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="body" fontSize="11pt">
                      Valid Until: {validUntil}
                    </MDTypography>
                    &nbsp;&nbsp;
                    <MDTypography
                      variant="body"
                      fontSize="11pt"
                      color={daysRem < 5 ? "error" : "success"}
                    >
                      {validUntil.toLowerCase() !== "infinity" && daysRem + " days remaining"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                {/* Section Tabs */}
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  width="97%"
                  marginX="auto"
                  marginBottom="30px"
                >
                  <Typography
                    width={"30%"}
                    style={{
                      color: active === 1 ? "dodgerblue" : "gray",
                      cursor: "pointer",
                      borderBottom: active === 1 ? "solid 2px dodgerblue" : "solid 1px gray",
                      textAlign: "center",
                    }}
                    onClick={() => setActive(1)}
                  >
                    Packages
                  </Typography>
                  <Typography
                    width={"30%"}
                    style={{
                      color: active === 2 ? "dodgerblue" : "gray",
                      cursor: "pointer",
                      borderBottom: active === 2 ? "solid 2px dodgerblue" : "solid 1px gray",
                      textAlign: "center",
                    }}
                    onClick={() => setActive(2)}
                  >
                    Marketing
                  </Typography>
                  <Typography
                    width={"30%"}
                    style={{
                      color: active === 3 ? "dodgerblue" : "gray",
                      cursor: "pointer",
                      borderBottom: active === 3 ? "solid 2px dodgerblue" : "solid 1px gray",
                      textAlign: "center",
                    }}
                    onClick={() => setActive(3)}
                  >
                    Invoices
                  </Typography>
                </MDBox>
                {/* Sections */}
                {active === 1 && (
                  <Packages
                    profile={profile}
                    invoices={invoices}
                    setInvoices={setInvoices}
                    lastPayment={lastPayment}
                    mpackage={mpackage}
                    setLastPayment={setLastPayment}
                    setSubscription={setSubscription}
                    setPackage={setPackage}
                    status={status}
                  />
                )}
                {active === 2 && <Marketing />}
                {active === 3 && (
                  <Invoices
                    invoices={invoices}
                    setInvoices={setInvoices}
                    setLastPayment={setLastPayment}
                  />
                )}
                <MDBox px={4} fontSize="10pt">
                  All payments are made through our Paybill Number 412 3793 and appropriate account
                  number
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
