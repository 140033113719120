import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTemplate } from "redux/routes/templates";
// import { setTemplate } from "redux/features/templatesSlice";

export default function Template({
  template,
  selectedTheme,
  selectedTemplate,
  setSelectedTemplate,
  setSelectedTheme,
}) {
  const [img, setImg] = useState(template.themes[0].img);
  const [selected, setSelected] = useState(template.themes[0]);
  // const selectedTheme = useSelector((state) => state.templates.selectedTheme);
  // const selectedTemplate = useSelector((state) => state.templates.selectedTemplate);

  const select = (theme) => {
    setSelected(theme);
    setImg(theme.img);
  };
  // const dispatch = useDispatch();
  useEffect(() => {
    template.themes.map((thm) => {
      if (thm.name === selectedTheme && template.name === selectedTemplate) select(thm);
    });
  }, []);

  const [applying, setApplying] = useState(false);

  const applyTemplate = async () => {
    setApplying(true);
    let res = await setTemplate({ template: template.name, theme: selected.name });
    if (res.success) {
      setSelectedTemplate(res.data.selectedTemplate);
      setSelectedTheme(res.data.selectedTheme);
    }
    setApplying(false);
  };

  return (
    <MDBox
      style={{
        width: "95%",
        margin: "10px auto",
        backgroundColor: selectedTemplate === template.name ? "lightgreen" : "#dedede",
        borderRadius: "10px",
        padding: "10px",
        display: "flex",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      }}
    >
      <img src={img} style={{ width: "26%", minWidth: "300px" }} />
      <MDBox
        style={{
          width: "60%",
          minWidth: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h4>{template.name}</h4>
          <MDBox
            style={{
              display: "flex",
              maxHeight: "200px",
              justifyContent: "start",
              flexWrap: "wrap",
              overflowY: "auto",
            }}
          >
            {template.themes.map((theme, index) => {
              return (
                <MDBox
                  key={index + "-" + theme.name}
                  style={{
                    margin: "10px",
                    marginLeft: "0",
                    backgroundColor: "#eeeeee",
                    padding: "3px",
                    borderRadius: "7px",
                    border: theme.img === img ? "solid 2px green" : "",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => select(theme)}
                >
                  {theme.name}
                </MDBox>
              );
            })}
          </MDBox>
        </div>
        <MDBox style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
          <a href={template.preview + "/"} style={{ fontSize: "14px" }}>
            {/* Preview */}
          </a>
          <MDButton
            size="small"
            style={{ backgroundColor: "dodgerblue", color: "white" }}
            onClick={applyTemplate}
            disabled={selectedTemplate === template.name && selectedTheme === selected.name}
          >
            {applying ? "Applying..." : "Apply"}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}
