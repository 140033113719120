import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const initialState = {
  locations: [],
  isAdding: false,
  isDeleting: false,
};

export const addOption = createAsyncThunk(
  "delivery/addOption",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.post("/delivery/add", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteOption = createAsyncThunk(
  "delivery/deleteOption",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.post("/delivery/delete", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDeliveries = createAsyncThunk(
  "delivery/getDeliveries",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.get("/delivery");
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDeliveries.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.locations = action.payload.data;
      }
    });

    builder.addCase(addOption.pending, (state) => {
      state.isAdding = true;
    });
    builder.addCase(addOption.fulfilled, (state, action) => {
      state.isAdding = false;
      if (action.payload.success) {
        state.locations = action.payload.data;
      }
    });
    builder.addCase(addOption.rejected, (state) => {
      state.isAdding = false;
    });

    builder.addCase(deleteOption.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.locations = action.payload.data;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = deliverySlice.actions;

export default deliverySlice.reducer;
