import React, { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import { useDispatch, useSelector } from "react-redux";
import { TextField, Tooltip, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import MDModal from "components/MDModal/MDModal";
import axios from "../../../redux/axios.config";
import { setStatus } from "redux/routes/orders";
import Order from "./Order";
// import { setStatus } from "redux/features/ordersSlice";

function OrdersList({ orders, modifiable, updateOrders }) {
  // const [downloading, setDownloading] = useState("");
  // const [updating, setUpdating] = useState("");
  // const dispatch = useDispatch();
  // const handleSetStatus = async (oid, uid, opid, status) => {
  //   setUpdating(oid + "-" + opid + "-");
  //   let res = await setStatus({ oid, uid, opid, status });
  //   if (res.success) {
  //     updateOrders(res.data);
  //   }
  //   // dispatch(setStatus());
  // };

  // const downloadReceipt = async (oid, uid) => {
  //   setDownloading(oid);
  //   await axios.get("/receipt/" + oid + "/" + uid);
  //   setDownloading("");
  // };

  return (
    <MDBox pt={3}>
      {orders.map((order) => (
        <div key={order.oid + "-" + order.status}>
          <Order order={order} modifiable={modifiable} updateOrders={updateOrders} />
        </div>
      ))}
    </MDBox>
  );
}

export default OrdersList;
