/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getPromotions } from "redux/features/promotionsSlice";
import { styled } from "@mui/material/styles";
import MDButton from "components/MDButton";
import { Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
// import { addPromotion } from "redux/features/promotionsSlice";
// import { deletePromotion } from "redux/features/promotionsSlice";
import { Delete } from "@mui/icons-material";
import { useGlobalContext } from "context/context";
import { addPromotion } from "redux/routes/promotions";
import { deletePromotion } from "redux/routes/promotions";
const MyBox = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    width: "96%",
    padding: "0",
  },
  [theme.breakpoints.up("sm")]: {
    width: "48%",
    padding: "0",
  },
}));

function Marketing({ promotions, setPromotions }) {
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  // const dispatch = useDispatch();
  const { profile } = useGlobalContext();
  const [copying, setCopying] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [addingTag, setAddingTag] = useState(false);

  const [tag, setTag] = useState("");
  const addGTag = async () => {
    if (addingTag) return;
    if (tag === "") {
      setContent("Please provide some content for the promotion tag");
      openErrorSB();
      return;
    }
    let tags = promotions.filter((p) => p.type === "tag");
    if (tags.length >= 2) {
      setContent("You cannot add more than two tags");
      openErrorSB();
      return;
    }
    setAddingTag(true);
    let res = await addPromotion({ type: "tag", content: tag });
    if (res.success) {
      setContent("Promotion tag successfully added");
      openSuccessSB();
      setPromotions(res.data);
    } else {
      setContent("Unable to add tag");
      openErrorSB();
    }
    setAddingTag(false);
    setTag("");
  };

  const deleteGTag = async (id) => {
    if (deleting !== "") {
      return;
    }
    setDeleting(id);
    let res = await deletePromotion({ id });
    if (res.success) {
      setContent("Tag deleted successfully");
      openSuccessSB();
      setPromotions(res.data);
    } else {
      setContent("Unable to delete tag");
      openErrorSB();
    }
    setDeleting("");
  };

  let [content, setContent] = useState("");
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={"Promotions"}
      content={content}
      dateTime="just now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bggreen="true"
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={"Promotions"}
      content={content}
      dateTime="Just now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgindianred="true"
    />
  );
  const [dataURL, setDataUrl] = useState("");
  useEffect(() => {
    if (profile.url) {
      setDataUrl("https://" + profile.url + "/data/structured/xml/" + profile._id);
    }
  }, [profile]);

  const copyLink = () => {
    setCopying(true);
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(dataURL)
        .then(() => {
          setContent("Structured Data URL Copied");
          openSuccessSB();
          setCopying(false);
        })
        .catch((err) => {
          setContent("Failed to Copy Structured Data URL");
          openErrorSB();
          setCopying(false);
        });
    } else {
      setContent("Failed to Copy Structured Data URL");
      openErrorSB();
      setCopying(false);
    }
  };
  return (
    <MyBox
      style={{
        border: "solid 1px #bbb",
        backgroundColor: "#eee",
        margin: "10px auto",
        borderRadius: "7px",
        padding: "7px",
      }}
    >
      <MDBox p={1} display="flex" flexDirection="column" alignItems="center">
        <Typography fontSize={"13pt"} variant="h2" mb={2}>
          Advanced Marketing
        </Typography>
        <MDBox display="flex" width="100%" justifyContent="center">
          <MDInput
            type="text"
            size="small"
            m={2}
            style={{ width: "70%" }}
            value={dataURL.slice(0, 30) + "..." + dataURL.slice(dataURL.length - 3)}
            inputProps={{ maxLength: 50 }}
            label={"Structured Data File Link"}
          />{" "}
          <MDButton
            color="info"
            size="small"
            style={{ marginLeft: "10px" }}
            onClick={() => copyLink()}
          >
            {copying ? "Copying..." : "Copy Link"}
          </MDButton>
        </MDBox>
      </MDBox>
      {renderErrorSB}
      {renderSuccessSB}
    </MyBox>
  );
}

export default Marketing;
