import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

export const getStatistics = async (data) => {
  try {
    let res = await axios.get("/statistics");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const sendPOSInvoice = async (data) => {
  try {
    let res = await axios.post("/pos/invoice", data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getCheckoutInfo = async () => {
  try {
    let res = await axios.get("/checkoutinfo");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getEssentialInfo = async () => {
  try {
    let res = await axios.get("/essentials");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getPOSInvoices = async () => {
  try {
    let res = await axios.get("/pos/invoices");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const payPOSInvoice = async (data) => {
  try {
    let res = await axios.post("/pos/invoice/pay", data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const cancelPOSInvoice = async (data) => {
  try {
    let res = await axios.post("/pos/invoice/cancel", data);
    return res.data;
  } catch (err) {
    return err;
  }
};
