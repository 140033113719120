import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const initialState = {
  newOrders: [],
  pending: [],
  delivered: [],
  refunded: [],
  error: "",
};

export const getOrders = createAsyncThunk("orders/getOrders", async (data, { rejectWithValue }) => {
  try {
    let res = await axios.get("/orders");
    return res.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const setStatus = createAsyncThunk("orders/setStatus", async (data, { rejectWithValue }) => {
  try {
    let res = await axios.post("/order/status", data);
    return res.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.isLoading = true;
      state.error = "";
    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = "";
      if (action.payload.success) {
        state.newOrders = action.payload.data.newOrders;
        state.pending = action.payload.data.pending;
        state.delivered = action.payload.data.delivered;
        state.refunded = action.payload.data.refunded;
      }
    });
    builder.addCase(getOrders.rejected, (state) => {
      state.isLoading = false;
      state.error = "Error Occurred";
    });

    builder.addCase(setStatus.pending, (state) => {
      state.isSettingStatus = true;
    });
    builder.addCase(setStatus.fulfilled, (state, action) => {
      state.isSettingStatus = false;

      if (action.payload.success) {
        let order = action.payload.data;
        state.newOrders = state.newOrders.map((o) => {
          if (o.oid === order.oid) {
            o.products = o.products.map((item) => {
              if (item._id.toString() === order.opid) {
                item.status = order.status;
              }
              return item;
            });
          }
          return o;
        });
        state.pending = state.pending.map((o) => {
          if (o.oid === order.oid) {
            o.products = o.products.map((item) => {
              if (item._id.toString() === order.opid) {
                item.status = order.status;
              }
              return item;
            });
          }
          return o;
        });
        state.delivered = state.delivered.map((o) => {
          if (o.oid === order.oid) {
            o.products = o.products.map((item) => {
              if (item._id.toString() === order.opid) {
                item.status = order.status;
              }
              return item;
            });
          }
          return o;
        });
      }
    });
    builder.addCase(setStatus.rejected, (state) => {
      state.isSettingStatus = false;
      state.error = "Error Occurred";
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = ordersSlice.actions;

export default ordersSlice.reducer;
