import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const initialState = {
  product: {},
  isUpdating: false,
};

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.post("/update/product", payload);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchProduct = createAsyncThunk(
  "product/fetchProduct",
  async (_, { rejectWithValue }) => {
    try {
      return await axios.get("");
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (payload, { rejectWithValue }) => {
    try {
      return await axios.post("/delete/product/" + payload._id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addFeature = createAsyncThunk(
  "product/addFeature",
  async (payload, { rejectWithValue }) => {
    try {
      return await axios.post("/add-feature/", payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateProduct.pending, (state) => {
      state.isUpdating = true;
    }),
      builder.addCase(updateProduct.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.product = action.payload.data.data;
      }),
      builder.addCase(updateProduct.rejected, (state) => {
        state.isUpdating = false;
        state.error = "Error Occurred";
      }),
      builder.addCase(fetchProduct.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.product = action.payload;
      }),
      builder.addCase(fetchProduct.rejected, (state) => {
        state.isLoading = false;
        state.error = "Error Occurred";
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = productSlice.actions;

export default productSlice.reducer;
