import { Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";

export default function PosProduct({ product, addSelected }) {
  const [color, setColor] = useState(product.colors.length > 0 ? product.colors[0] : "-");
  const [size, setSize] = useState(product.sizes.length > 0 ? product.sizes[0] : "-");
  const [option, setOption] = useState(
    product.priceOptions.length > 0 ? product.priceOptions[0] : "-"
  );
  const [price, setPrice] = useState(product.price);

  useEffect(() => {
    if (option === "-") setPrice(product.price);
    else {
      setPrice(option.price);
    }
  }, [option]);

  const addItem = () => {
    let prod = { ...product };
    prod.color = color;
    prod.size = size;
    prod.option = option.option;
    prod.price = price;

    addSelected(prod);
  };

  return (
    <Grid
      container
      spacing={2}
      style={{ backgroundColor: "#dfdfdf", margin: "7px 0", width: "100%", borderRadius: "7px" }}
    >
      <Grid item xs={4}>
        <img src={product.img} style={{ width: "100%" }} />
      </Grid>
      <Grid
        item
        xs={8}
        style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
      >
        <MDBox style={{ fontSize: "12pt" }}>
          <p>{product.name}</p>
          {/* Sizes */}
          <MDBox display="flex" flexWrap="wrap" mt="10px">
            {product.sizes.map((sz) => (
              <div
                onClick={() => setSize(sz)}
                style={{
                  border: size === sz ? "solid 3px dodgerblue" : "solid 3px grey",
                  color: size === sz ? "dodgerblue" : "initial",
                  borderRadius: "3px",
                  margin: "3px",
                  padding: "3px",
                  cursor: "pointer",
                }}
              >
                {sz}
              </div>
            ))}
          </MDBox>
          {/* Colors */}
          <MDBox display="flex" flexWrap="wrap" mt="10px">
            {product.colors.map((cl) => (
              <div
                onClick={() => setColor(cl)}
                style={{
                  border: color === cl ? "solid 3px dodgerblue" : "",
                  boxShadow: color === cl ? "0 5px 0px grey" : "",
                  borderRadius: "50%",
                  backgroundColor: cl,
                  width: "20px",
                  height: "20px",
                  margin: "0 4px",
                  cursor: "pointer",
                }}
              ></div>
            ))}
          </MDBox>
          {/* Price Options */}
          <MDBox display="flex" flexWrap="wrap" mt="10px">
            {product.priceOptions.map((op) => (
              <div
                onClick={() => setOption(op)}
                style={{
                  border: option._id === op._id ? "solid 3px dodgerblue" : "solid 3px grey",
                  color: option._id === op._id ? "dodgerblue" : "initial",
                  borderRadius: "3px",
                  margin: "3px",
                  padding: "3px",
                  cursor: "pointer",
                }}
              >
                {op.option}
              </div>
            ))}
          </MDBox>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" pr="10px" pb="10px" mt="10px">
          <Typography>{"Ksh. " + price}</Typography>
          <MDButton style={{ backgroundColor: "dodgerblue", color: "white" }} onClick={addItem}>
            Add To List
          </MDButton>
        </MDBox>
      </Grid>
    </Grid>
  );
}
