/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";

import Profile from "./components/profile";
import Till from "./components/Till";
import PayBill from "./components/PayBill";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchFullProfile } from "redux/features/profileSlice";
import Deliveries from "./components/Deliveries";
import DisplayDeliveries from "./components/DisplayDeliveries";
import Policies from "./components/Policies";
import { useGlobalContext } from "context/context";
import { getDeliveries } from "redux/routes/delivery";

function Overview() {
  const [active, setActive] = useState("profile");
  const [locations, setLocations] = useState([]);
  const init = async () => {
    let res = await getDeliveries();
    if (res.success) {
      setLocations(res.data);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Business Profile
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-evenly" marginTop="30px">
                <MDTypography
                  onClick={() => setActive("profile")}
                  width="30%"
                  textAlign="center"
                  style={{
                    color: active === "profile" ? "dodgerblue" : "initial",
                    borderBottom: "solid 2px grey",
                    borderColor: active === "profile" ? "dodgerblue" : "initial",
                    cursor: "pointer",
                  }}
                >
                  Profile
                </MDTypography>
                <MDTypography
                  onClick={() => setActive("shipping")}
                  width="30%"
                  textAlign="center"
                  style={{
                    color: active === "shipping" ? "dodgerblue" : "initial",
                    borderBottom: "solid 2px grey",
                    borderColor: active === "shipping" ? "dodgerblue" : "initial",
                    cursor: "pointer",
                  }}
                >
                  Shipping
                </MDTypography>
                <MDTypography
                  onClick={() => setActive("payments")}
                  width="30%"
                  textAlign="center"
                  style={{
                    color: active === "payments" ? "dodgerblue" : "initial",
                    borderBottom: "solid 2px grey",
                    borderColor: active === "payments" ? "dodgerblue" : "initial",
                    cursor: "pointer",
                  }}
                >
                  Payments
                </MDTypography>
              </MDBox>
              <MDBox pt={3} display="flex" justifyContent="space-evenly" flexWrap="wrap">
                {active === "profile" && (
                  <>
                    <Profile />
                    <Policies />
                  </>
                )}
                {active === "shipping" && (
                  <Grid container spacing={2} style={{ padding: "10px" }}>
                    <Grid item xs={12} md={6}>
                      <Deliveries deliveries={locations} setLocations={setLocations} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DisplayDeliveries setLocations={setLocations} locations={locations} />
                    </Grid>
                  </Grid>
                )}
                {active === "payments" && (
                  <Grid container spacing={2} style={{ padding: "10px" }}>
                    <Grid item xs={12} md={6}>
                      <Till />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PayBill />
                    </Grid>
                  </Grid>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
