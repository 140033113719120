import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const initialState = {
  lastPayment: { year: 0, month: 0, day: 0 },
  subscription: "",
  invoices: [],
  package: "",
  error: "",
};

export const getPackageInfo = createAsyncThunk(
  "packages/get",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.get("/packages");
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getInvoices = createAsyncThunk("invoices/get", async (data, { rejectWithValue }) => {
  try {
    let res = await axios.get("/invoices");
    return res.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const payInvoice = createAsyncThunk("invoices/pay", async (data, { rejectWithValue }) => {
  try {
    let res = await axios.post("/invoices/pay", data);
    return res.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deactivatePackage = createAsyncThunk(
  "package/deactivate",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.post("/package/deactivate", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const activatePackage = createAsyncThunk(
  "package/activate",
  async (data, { rejectWithValue }) => {
    try {
      let res = await axios.post("/package/activate", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const billingSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvoices.pending, (state) => {
      state.isLoading = true;
      state.error = "";
    });
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = "";
      if (action.payload.success) {
        state.lastPayment = action.payload.data.lastPayment;
        state.subscription = action.payload.data.subscription;
        state.invoices = action.payload.data.invoices;
        state.package = action.payload.data.package;
        state.invoices.reverse();
      }
    });
    builder.addCase(getInvoices.rejected, (state) => {
      state.isLoading = false;
      state.error = "Error Occurred";
    });
    builder.addCase(payInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = "";
      if (action.payload.success) {
        state.lastPayment = action.payload.data.lastPayment;
        state.invoices = action.payload.data.invoices;
        state.invoices.reverse();
      }
    });
    builder.addCase(deactivatePackage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = "";
      if (action.payload.success) {
        state.lastPayment = action.payload.data.lastPayment;
        state.subscription = action.payload.data.subscription;
        state.package = action.payload.data.package;
        state.invoices.reverse();
      }
    });
    builder.addCase(activatePackage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = "";
      if (action.payload.success) {
        state.lastPayment = action.payload.data.lastPayment;
        state.subscription = action.payload.data.subscription;
        state.package = action.payload.data.package;
        state.invoices.reverse();
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = billingSlice.actions;

export default billingSlice.reducer;
