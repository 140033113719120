/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";

// import { storeData } from "./data/kitchenWareData";
import Product from "./Components/Product";
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
// import { getCategories } from "redux/features/productsSlice";
// import { getGenderizable } from "redux/features/productsSlice";
// import { getNextBatch } from "redux/features/productsSlice";
import NewProduct from "./Components/NewProduct";
// import { getWearables } from "redux/features/productsSlice";
// import { fetchAccountProfile } from "redux/features/profileSlice";
import { getCategories } from "redux/routes/products";
import { getGenderizable } from "redux/routes/products";
import { getWearables } from "redux/routes/products";
import { fetchAccountProfile } from "redux/routes/profile";
import { useGlobalContext } from "context/context";
import { getAllProducts } from "redux/routes/products";

function Products() {
  // let dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [genderizable, setGenderizable] = useState([]);
  const [wearables, setWearables] = useState([]);
  let { account } = useGlobalContext();

  const init = async () => {
    getCategories().then((res) => {
      if (res.success) setCategories(res.data);
    });
    getGenderizable().then((res) => {
      if (res.success) setGenderizable(res.data);
    });
    getWearables().then((res) => {
      if (res.success) setWearables(res.data);
    });
  };

  useEffect(() => {
    init();
  }, []);

  let productsLoader = async () => {
    let res = await getAllProducts();
    if (res.success) {
      setStoreData(res.data);
    }
  };
  const [storeData, setStoreData] = useState([]);
  useEffect(() => {
    productsLoader();
  }, []);
  const [section, setSection] = useState(1);
  const openSection = (sec) => {
    setSection(sec);
  };
  const [prodCount, setProdCount] = useState(storeData.length);
  useEffect(() => {
    setProdCount(storeData.length);
  }, [storeData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Products Management
            </MDTypography>
          </MDBox>
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "8px",
            }}
          >
            <li
              className=""
              style={{
                padding: "7px",
                paddingBottom: "2px",
                width: "190px",
                textAlign: "center",
                fontSize: "11pt",
                borderBottom: section === 1 ? "solid 2px dodgerblue" : "solid 2px grey",
                color: section === 1 ? "dodgerblue" : "grey",
                transition: ".5s",
                textTransform: "uppercase",
                cursor: "pointer",
              }}
              onClick={() => openSection(1)}
            >
              Add Product
            </li>
            <li
              className="p-2 w-[190px] text-center ml-[3px]"
              style={{
                padding: "7px",
                paddingBottom: "2px",
                width: "190px",
                textAlign: "center",
                fontSize: "11pt",
                borderBottom: section === 2 ? "solid 2px dodgerblue" : "solid 2px grey",
                color: section === 2 ? "dodgerblue" : "grey",
                transition: ".5s",
                textTransform: "uppercase",
                cursor: "pointer",
              }}
              onClick={() => openSection(2)}
            >
              My Products ({prodCount})
            </li>
          </ul>
          {section === 1 && (
            <MDBox pt={3}>
              <NewProduct
                storeData={storeData}
                setStoreData={setStoreData}
                categories={categories}
              />
            </MDBox>
          )}
          {section == 2 && (
            <MDBox pt={3}>
              {storeData && (
                <Product
                  storeData={storeData}
                  setProdCount={setProdCount}
                  categories={categories}
                  profile={account}
                ></Product>
              )}
            </MDBox>
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Products;
