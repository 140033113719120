import React, { useState, useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Icon, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import { updateMpesaPaybill } from "redux/features/profileSlice";
import { useDispatch, useSelector } from "react-redux";
// import { deleteMpesaPaybill } from "redux/features/profileSlice";
import MDSnackbar from "components/MDSnackbar";
import { useGlobalContext } from "context/context";
import { updateMpesaPaybill } from "redux/routes/profile";
import { deleteMpesaPaybill } from "redux/routes/profile";
// import { updateMpesaPaybill } from "redux/features/profileSlice";

function PayBill() {
  const [isLoading, setIsLoading] = useState(false);
  // let till = useSelector((state) => state.profile.mpesaTill);
  const { profile, mpesaPaybill, setMpesaPaybill, mpesaTill } = useGlobalContext();
  let [temp, setTemp] = useState(mpesaPaybill);

  const [item, setItem] = useState(mpesaPaybill);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setItem(temp);
  }, [temp]);

  // let dispatch = useDispatch();
  let updateItem = async () => {
    if (!updating) setUpdating(true);
    else if (!isLoading) {
      setIsLoading(true);
      let res = await updateMpesaPaybill(item);
      // dispatch(updateMpesaPaybill(item));
      if (res.success) {
        setMpesaPaybill(res.data);
        setTemp(res.data);
      }
      setUpdating(false);
      setIsLoading(false);
    }
  };

  let cancelUpdating = () => {
    setUpdating(false);
    setItem(temp);
  };

  let deleteItem = async () => {
    if (profile.showPrice && JSON.stringify(mpesaTill) === "{}") {
      setError(
        "You need to disable your 'Show Price' setting before clearing all payment methods "
      );
      openErrorSB();
      return;
    }
    let res = await deleteMpesaPaybill();
    if (res.success) {
      setMpesaPaybill({});
      setItem({});
      setTemp({});
    }
  };

  const [warningSB, setWarningSB] = useState(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  let [warning, setWarning] = useState("");
  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning"
      id="warning"
      title={" Profile"}
      content={warning}
      dateTime="1 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgindianred="true"
    />
  );

  let notUpdating = (event) => {
    let id = event.target.id;
    if (!updating && id !== "edit-btn" && id !== "warning") {
      setWarning("To start editing. Click on the green pencil button");
      openWarningSB();
    }
  };

  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  let [error, setError] = useState("");
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      id="warning"
      title={"Delete Paybill"}
      content={error}
      dateTime="1 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgindianred="true"
    />
  );

  return (
    <>
      <MDBox
        // minWidth="320px"
        // width="47%"
        borderRadius="10px"
        // display="flex"
        // flexDirection="column"
        // justify="center"
        // alignItems="center"
        className="profile"
        // m={2}
        p={1}
        style={{ boxShadow: "0 0 5px grey" }}
        onClick={notUpdating}
      >
        <MDBox display="flex" width="100%" justifyContent="space-evenly" flexWrap="wrap">
          <MDTypography width="100%">MPESA PAYBILL</MDTypography>
          <MDBox minWidth="150px" width="100%" display="flex" mr={1} justifyContent="space-between">
            <MDBox>
              <MDButton
                style={{ backgroundColor: "green", color: "white" }}
                onClick={updateItem}
                id="edit-btn"
              >
                {isLoading ? "..." : <Icon id="edit-btn">{updating ? "done" : "edit"}</Icon>}
              </MDButton>
            </MDBox>
            <MDBox>
              <MDButton
                disabled={!updating}
                style={{ backgroundColor: "goldenrod", color: "white" }}
                onClick={cancelUpdating}
              >
                <Icon>cancel</Icon>
              </MDButton>
            </MDBox>
            <MDBox>
              <MDButton
                id="edit-btn"
                style={{ backgroundColor: "indianred", color: "white" }}
                onClick={deleteItem}
              >
                <Icon id="edit-btn">delete</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <MDBox width="100%" pt={2} textAlign="center">
            <TextField
              size="small"
              label="PayBill Number"
              value={item.paybillNumber || ""}
              inputProps={{ maxLength: 20 }}
              type="text"
              style={{ width: "100%", pointerEvents: updating ? "initial" : "none" }}
              className={item.paybillNumber === temp.paybillNumber ? "" : "input-modified"}
              onChange={(e) => setItem({ ...item, paybillNumber: e.target.value })}
            />
          </MDBox>
          <MDBox width="100%" pt={2} textAlign="center">
            <TextField
              size="small"
              label="Account Number"
              value={item.accountNumber || ""}
              inputProps={{ maxLength: 50 }}
              type="text"
              style={{ width: "100%", pointerEvents: updating ? "initial" : "none" }}
              className={item.accountNumber === temp.accountNumber ? "" : "input-modified"}
              onChange={(e) => setItem({ ...item, accountNumber: e.target.value })}
            />
          </MDBox>
          <MDBox width="100%" pt={2} textAlign="center">
            <TextField
              size="small"
              label="Name"
              value={item.name || ""}
              inputProps={{ maxLength: 100 }}
              type="text"
              style={{ width: "100%", pointerEvents: updating ? "initial" : "none" }}
              className={item.name === temp.name ? "" : "input-modified"}
              onChange={(e) => setItem({ ...item, name: e.target.value })}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      {renderWarningSB}
      {renderErrorSB}
    </>
  );
}

export default PayBill;
