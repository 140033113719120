import axios from "../axios.config";

export const getPackageInfo = async (data) => {
  try {
    let res = await axios.get("/packages");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getInvoices = async () => {
  try {
    let res = await axios.get("/invoices");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const payInvoice = async (data) => {
  try {
    let res = await axios.post("/invoices/pay", data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deactivatePackage = async (data) => {
  try {
    let res = await axios.post("/package/deactivate", data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const activatePackage = async (data) => {
  try {
    let res = await axios.post("/package/activate", data);
    return res.data;
  } catch (err) {
    return err;
  }
};
