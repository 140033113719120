import { Delete, Download } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import { cancelPOSInvoice } from "redux/routes/dashboard";
import { payPOSInvoice } from "redux/routes/dashboard";

export default function PosInvoice({ invoice, setPosInvoices }) {
  console.log(invoice);
  const [paymentCode, setPaymentCode] = useState(invoice.paymentCode ? invoice.paymentCode : "");
  const [paymentMode, setPaymentMode] = useState(
    invoice.paymentMode ? invoice.paymentMode.replace("-", " ") : "Cash"
  );

  const payInvoice = async () => {
    if (!paymentCode || !paymentMode) {
      return;
    }
    let res = await payPOSInvoice(invoice);
    if (res.success) {
      setPosInvoices(res.data);
    }
  };
  const cancelInvoice = async () => {
    let res = await cancelPOSInvoice(invoice);
    if (res.success) {
      setPosInvoices(res.data);
    }
  };

  const [downloading, setDownloading] = useState(false);
  const downloadReceipt = async (oid) => {
    setDownloading(oid);
    await axios.get("/receipt/" + oid + "/pos");
    setDownloading("");
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        marginTop: "10px",
        backgroundColor: "#dfdfdf",
        marginBottom: "7px",
        borderRadius: "7px",
      }}
    >
      <Grid item xs={12} md={6}>
        <MDBox
          style={{
            margin: "7px 0",
            width: "100%",
            maxHeight: "250px",
            overflowY: "auto",
            borderRadius: "7px",
          }}
        >
          {invoice.items.map((prd, idx) => {
            return (
              <MDBox
                key={idx + "-" + prd._id}
                style={{
                  fontSize: "13pt",
                  padding: "10px",
                  paddingRight: "10px",
                  backgroundColor: "#efefef",
                  marginBottom: "7px",
                  borderRadius: "7px",
                }}
              >
                <p style={{ width: "100%" }}>{prd.name}</p>
                <MDBox display={"flex"} justifyContent="space-between">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {prd.size !== "-" && <span style={{ marginRight: "10px" }}>{prd.size}</span>}{" "}
                    {prd.color !== "-" && (
                      <div
                        style={{
                          marginRight: "10px",
                          backgroundColor: prd.color,
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "inline-block",
                        }}
                      ></div>
                    )}
                    {prd.option !== "-" && (
                      <span style={{ fontSize: "13pt" }}>{prd.selectedOption}</span>
                    )}
                  </div>
                  <div>
                    {"Ksh. " + prd.price * prd.amount}{" "}
                    <span
                      style={{
                        borderLeft: "solid 1px",
                        margin: "0 15px",
                        paddingLeft: "15px",
                      }}
                    >
                      {prd.amount}
                    </span>
                  </div>
                </MDBox>
              </MDBox>
            );
          })}
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} style={{ fontSize: "13pt" }}>
        <MDBox display="flex" justifyContent="space-between">
          <Typography style={{ fontSize: "13pt" }}>
            Invoice ID: {invoice._id.slice(16).toUpperCase()}
          </Typography>{" "}
          <Typography style={{ fontSize: "13pt", marginRight: "20px" }}>{invoice.date}</Typography>
        </MDBox>

        <MDBox display="flex" fexWrap="wrap">
          <Typography style={{ fontSize: "13pt", marginRight: "20px" }}>
            Phone: {invoice.phone}
          </Typography>
          <Typography style={{ fontSize: "13pt" }}>Email: {invoice.email}</Typography>
        </MDBox>
        <MDBox display="flex" fexWrap="wrap" justifyContent="space-between">
          <Typography style={{ fontSize: "13pt", marginRight: "20px" }}>
            Total: {"Ksh. " + invoice.total}
          </Typography>
          <Typography
            style={{ fontSize: "13pt", marginRight: "20px", cursor: "pointer" }}
            onClick={() => downloadReceipt(invoice._id, "")}
          >
            {downloading === invoice._id ? "Downloading" : <Download />}
          </Typography>
        </MDBox>
        <MDBox mt="15px" pr="10px">
          <FormControl fullWidth>
            <InputLabel id="county-label">Payment Mode</InputLabel>
            <Select
              value={paymentMode}
              label="County"
              labelId="county-label"
              onChange={(e) => setPaymentMode(e.target.value)}
              style={{ width: "100%", fontSize: "13pt", padding: "4px" }}
            >
              <MenuItem value={"MPesa Paybill"}>MPesa Paybill</MenuItem>
              <MenuItem value={"MPesa Till"}>MPesa Till</MenuItem>
              <MenuItem value={"MPesa Express"}>MPesa Express</MenuItem>
              <MenuItem value={"Cash"}>Cash</MenuItem>
            </Select>
          </FormControl>
        </MDBox>
        <MDBox mt="15px" pr="10px">
          <TextField
            label={"Payment Code"}
            value={paymentCode}
            style={{ width: "100%" }}
            onChange={(e) => setPaymentCode(e.target.value)}
          />
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" p="7px">
          <MDButton style={{ backgroundColor: "dodgerblue", color: "white" }} onClick={payInvoice}>
            Paid
          </MDButton>
          <MDButton
            style={{ backgroundColor: "indianred", color: "white" }}
            onClick={cancelInvoice}
          >
            <Delete />
          </MDButton>
        </MDBox>
      </Grid>
    </Grid>
  );
}
