import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios.config";

const initialState = {
  returns: null,
  shipping: null,
  error: "",
  loading: false,
};

export const fetchPolicies = createAsyncThunk(
  "policies/fetch",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.get("/policies");
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePolicies = createAsyncThunk(
  "policies/update",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.post("/update/policies", payload);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const policiesSlice = createSlice({
  name: "policies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPolicies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPolicies.fulfilled, (state, action) => {
      state.loading = false;
      state.returns = action.payload.data.data.returnsPolicy;
      state.shipping = action.payload.data.data.shippingPolicy;
    });
    builder.addCase(fetchPolicies.rejected, (state) => {
      state.loading = false;
      state.error = "Error Occured";
    });

    builder.addCase(updatePolicies.fulfilled, (state, action) => {
      state.returns = action.payload.data.data.returnsPolicy;
      state.shipping = action.payload.data.data.shippingPolicy;
    });
    builder.addCase(updatePolicies.rejected, (state) => {
      state.error = "Error Occured";
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = policiesSlice.actions;

export default policiesSlice.reducer;
