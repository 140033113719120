import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {
  Autocomplete,
  Checkbox,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import MDSnackbar from "components/MDSnackbar";
// import { fetchPolicies } from "redux/features/policiesSlice";
import { updatePolicies } from "redux/routes/policies";
import { fetchPolicies } from "redux/routes/policies";

// import { fetchPolicies } from "redux/features/policiesSlice";
// import { updatePolicies } from "redux/features/policiesSlice";

function Policies() {
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);

  const [tempReturns, setTempReturns] = useState({});
  const [tempShipping, setTempShipping] = useState({});

  const init = async () => {
    let res = await fetchPolicies();

    if (res.success) {
      setTempReturns(res.data.returnsPolicy);
      setTempShipping(res.data.shippingPolicy);
    }
  };
  // const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(fetchPolicies());
    init();
  }, []);

  useEffect(() => {
    setReturns(tempReturns);
  }, [tempReturns]);

  useEffect(() => {
    setShipping(tempShipping);
  }, [tempShipping]);

  const [returns, setReturns] = useState(tempReturns);
  const [shipping, setShipping] = useState(tempShipping);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);

  let updateItem = async () => {
    setActivity("Update");
    if (!updating) setUpdating(true);
    else if (!loading) {
      if (
        new Date(new Date().toLocaleDateString() + " " + shipping.earliestShipTime) <
          new Date(new Date().toLocaleDateString() + " 01:00") ||
        new Date(new Date().toLocaleDateString() + " " + shipping.earliestShipTime) >
          new Date(new Date().toLocaleDateString() + " 13:00")
      ) {
        setErr("The earliest shipping time should be between 1:00 AM and 1:00 PM");
        openErrorSB();
        return;
      }
      if (
        new Date(new Date().toLocaleDateString() + " " + shipping.cutoffTime) <
          new Date(new Date().toLocaleDateString() + " 11:00") ||
        new Date(new Date().toLocaleDateString() + " " + shipping.cutoffTime) >
          new Date(new Date().toLocaleDateString() + " 23:00")
      ) {
        setErr("The cut-off time should be between 11:00 AM and 11:00 PM");
        openErrorSB();
        return;
      }

      setLoading(true);
      let res = await updatePolicies({ returns, shipping });

      if (res.success) {
        setTempReturns(res.data.returnsPolicy);
        setTempShipping(res.data.shippingPolicy);
        setUpdating(false);
      }

      setLoading(false);

      openSuccessSB();
    }
  };

  let cancelUpdating = () => {
    setUpdating(false);
    setReturns(tempReturns);
    setShipping(tempShipping);
  };

  let [activity, setActivity] = useState("");

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={activity.slice(0, 6) + " Profile"}
      content="Profile Successfully Updated"
      dateTime="just now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bggreen="true"
    />
  );
  let [err, setErr] = useState("");
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={activity.slice(0, 6) + " Profile"}
      content={err}
      dateTime="1 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgindianred="true"
    />
  );

  let [warning, setWarning] = useState("");
  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning"
      id="warning"
      title={" Profile"}
      content={warning}
      dateTime="1 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgindianred="true"
    />
  );

  let notUpdating = (event) => {
    let id = event.target.id;
    if (!updating && id !== "edit-btn" && id !== "warning") {
      setWarning("To start editing. Click on the green pencil button");
      openWarningSB();
    }
  };

  return (
    <>
      <MDBox
        width="100%"
        minWidth="320px"
        borderRadius="10px"
        display="flex"
        flexDirection="column"
        justify="center"
        alignItems="center"
        className="profile"
        m={2}
        p={1}
        style={{ boxShadow: "0 0 5px grey" }}
        onClick={notUpdating}
      >
        <MDBox display="flex" width="100%" justifyContent="space-evenly" flexWrap="wrap">
          <MDTypography width="100%">Policies</MDTypography>
          <MDBox minWidth="150px" width="100%" display="flex" mr={1} justifyContent="space-between">
            <MDBox>
              <MDButton
                style={{ backgroundColor: "green", color: "white" }}
                onClick={updateItem}
                id="edit-btn"
              >
                {loading ? "..." : <Icon id="edit-btn">{updating ? "done" : "edit"}</Icon>}
              </MDButton>
            </MDBox>
            <MDBox>
              <MDButton
                disabled={!updating}
                style={{ backgroundColor: "goldenrod", color: "white" }}
                onClick={cancelUpdating}
              >
                <Icon>cancel</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDTypography width="100%">
          <b>Return Policy</b>
        </MDTypography>
        {/* <MDBox
          minWidth="320px"
          width="100%"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
        > */}
        <Grid container spacing={2} style={{ padding: "7px" }}>
          {/* <MDBox
            minWidth="320px"
            width="47%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            paddingTop="10px"
          > */}
          <Grid item xs={12} md={6}>
            <MDBox>
              <FormControl style={{ width: "100%", textAlign: "left" }}>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{ fontSize: "12pt", color: "black" }}
                >
                  Do you accept returns
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  row
                  value={returns ? returns.accept : false}
                  onChange={(e) => setReturns({ ...returns, accept: e.target.value })}
                  style={{ pointerEvents: updating ? "initial" : "none" }}
                >
                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              {returns && returns.accept ? (
                <FormControl style={{ width: "100%", textAlign: "left" }}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ fontSize: "12pt", color: "black" }}
                  >
                    Do you refund purchase shipping cost?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    row
                    value={returns ? returns.refundPurchaseShipping : ""}
                    onChange={(e) =>
                      setReturns({ ...returns, refundPurchaseShipping: e.target.value })
                    }
                    style={{ pointerEvents: updating ? "initial" : "none" }}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              ) : (
                <></>
              )}
              {returns && returns.accept && (
                <FormControl style={{ width: "100%", textAlign: "left" }}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ fontSize: "12pt", color: "black" }}
                  >
                    Do you refund return shipping cost?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    row
                    value={returns ? returns.refundReturnShipping : ""}
                    onChange={(e) =>
                      setReturns({ ...returns, refundReturnShipping: e.target.value })
                    }
                    style={{ pointerEvents: updating ? "initial" : "none" }}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              )}
              {returns && returns.accept && (
                <FormControl style={{ width: "100%", textAlign: "left" }}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ fontSize: "12pt", color: "black" }}
                  >
                    Do you offer replacement if customer complaint is valid?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    row
                    value={returns ? returns.replace : ""}
                    onChange={(e) => setReturns({ ...returns, replace: e.target.value })}
                    style={{ pointerEvents: updating ? "initial" : "none" }}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              )}
              {returns && returns.accept && (
                <FormControl style={{ width: "100%", textAlign: "left" }}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ fontSize: "12pt", color: "black" }}
                  >
                    Do you offer cash refund when replacement is not possible?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    row
                    value={returns ? returns.cashRefund : ""}
                    onChange={(e) => setReturns({ ...returns, cashRefund: e.target.value })}
                    style={{ pointerEvents: updating ? "initial" : "none" }}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              )}
            </MDBox>
          </Grid>
          {/* </MDBox> */}
          <Grid item xs={12} md={6}>
            {/* <MDBox minWidth="320px" width="47%" display="flex" flexDirection="column"> */}
            {returns && returns.accept && (
              <MDBox>
                <Typography style={{ fontSize: "12pt" }}>
                  How long does a customer have to request refund/replacement after receiving the
                  goods?
                </Typography>
                <MDBox display="flex" justifyContent="start" marginTop="20px">
                  <FormControl width="45%">
                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={returns && returns.raiseTimeline ? returns.raiseTimeline.unit : "days"}
                      label="Unit"
                      style={{
                        width: "150px",
                        padding: "8px",
                        pointerEvents: updating ? "initial" : "none",
                      }}
                      onChange={(e) =>
                        setReturns({
                          ...returns,
                          raiseTimeline: { ...returns.raiseTimeline, unit: e.target.value },
                        })
                      }
                    >
                      <MenuItem value={"hours"}>Hours</MenuItem>
                      <MenuItem value={"days"}>Days</MenuItem>
                      <MenuItem value={"weeks"}>Weeks</MenuItem>
                      <MenuItem value={"months"}>Months</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Value"
                    size="small"
                    value={returns && returns.raiseTimeline ? returns.raiseTimeline.amount : ""}
                    onChange={(e) =>
                      setReturns({
                        ...returns,
                        raiseTimeline: { ...returns.raiseTimeline, amount: e.target.value },
                      })
                    }
                    style={{ marginLeft: "20px", pointerEvents: updating ? "initial" : "none" }}
                  />
                </MDBox>
              </MDBox>
            )}
            {returns && returns.accept && (
              <MDBox paddingTop="20px">
                <Typography style={{ fontSize: "12pt" }}>
                  How long does it take you to respond with a refund/replacement after goods are
                  returned?
                </Typography>
                <MDBox display="flex" justifyContent="start" marginTop="20px">
                  <FormControl width="45%">
                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        returns && returns.refundTimeline ? returns.refundTimeline.unit : "days"
                      }
                      label="Unit"
                      style={{
                        width: "150px",
                        padding: "8px",
                        pointerEvents: updating ? "initial" : "none",
                      }}
                      onChange={(e) =>
                        setReturns({
                          ...returns,
                          refundTimeline: { ...returns.refundTimeline, unit: e.target.value },
                        })
                      }
                    >
                      <MenuItem value={"hours"}>Hours</MenuItem>
                      <MenuItem value={"days"}>Days</MenuItem>
                      <MenuItem value={"weeks"}>Weeks</MenuItem>
                      <MenuItem value={"months"}>Months</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Value"
                    size="small"
                    value={returns && returns.refundTimeline ? returns.refundTimeline.amount : ""}
                    onChange={(e) =>
                      setReturns({
                        ...returns,
                        refundTimeline: { ...returns.refundTimeline, amount: e.target.value },
                      })
                    }
                    style={{ marginLeft: "20px", pointerEvents: updating ? "initial" : "none" }}
                  />
                </MDBox>
              </MDBox>
            )}
            {returns && returns.accept && (
              <MDBox paddingTop="20px">
                <Typography style={{ fontSize: "12pt" }}>
                  In which cases do you accept returns?
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        returns && returns.eligibility && returns.eligibility.includes("mistake")
                      }
                      onChange={(e) =>
                        setReturns({
                          ...returns,
                          eligibility: e.target.checked
                            ? [...returns.eligibility, "mistake"]
                            : returns.eligibility.filter((el) => el !== "mistake"),
                        })
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  style={{ pointerEvents: updating ? "initial" : "none" }}
                  label=" A mistake is made on the seller's part"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        returns && returns.eligibility && returns.eligibility.includes("mismatch")
                      }
                      onChange={(e) =>
                        setReturns({
                          ...returns,
                          eligibility: e.target.checked
                            ? [...returns.eligibility, "mismatch"]
                            : returns.eligibility.filter((el) => el !== "mismatch"),
                        })
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  style={{ pointerEvents: updating ? "initial" : "none" }}
                  label="The product doesn't look as described in product description"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        returns && returns.eligibility && returns.eligibility.includes("defective")
                      }
                      onChange={(e) =>
                        setReturns({
                          ...returns,
                          eligibility: e.target.checked
                            ? [...returns.eligibility, "defective"]
                            : returns.eligibility.filter((el) => el !== "defective"),
                        })
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  style={{ pointerEvents: updating ? "initial" : "none" }}
                  label="The product is defective. (Doesn't work as described in product description)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        returns && returns.eligibility && returns.eligibility.includes("damaged")
                      }
                      onChange={(e) =>
                        setReturns({
                          ...returns,
                          eligibility: e.target.checked
                            ? [...returns.eligibility, "damaged"]
                            : returns.eligibility.filter((el) => el !== "damaged"),
                        })
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  style={{ pointerEvents: updating ? "initial" : "none" }}
                  label="The product product arrives at destination damaged"
                />
              </MDBox>
            )}
            {/* </MDBox> */}
          </Grid>
        </Grid>
        {/* </MDBox> */}
        <MDTypography width="100%" marginTop="40px" fontSize="14pt">
          <b>Shipping Policy</b>
        </MDTypography>
        {/* <MDBox
          minWidth="320px"
          width="100%"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
        > */}
        <Grid container spacing={2} style={{ padding: "10px" }}>
          <Grid item xs={12} md={6}>
            {/* <MDBox
            minWidth="320px"
            width="47%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            paddingTop="10px"
          > */}
            <MDBox>
              <FormControl style={{ width: "100%", textAlign: "left" }}>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{ fontSize: "12pt", color: "black" }}
                >
                  Do you provide shipping for clients who order online?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  row
                  value={shipping ? shipping.accept : ""}
                  onChange={(e) => setShipping({ ...shipping, accept: e.target.value })}
                  style={{ pointerEvents: updating ? "initial" : "none" }}
                >
                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No, Only in-store pickup"
                  />
                </RadioGroup>
              </FormControl>
              {shipping && shipping.accept && (
                <FormControl style={{ width: "100%", textAlign: "left" }}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ fontSize: "12pt", color: "black" }}
                  >
                    Do you guarantee that the couriers you use to ship products to clients will
                    deliver the goods without damage?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    row
                    value={shipping ? shipping.guaranteeCourier : ""}
                    onChange={(e) => setShipping({ ...shipping, guaranteeCourier: e.target.value })}
                    style={{ pointerEvents: updating ? "initial" : "none" }}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              )}
            </MDBox>
            {shipping && shipping.accept && (
              <MDBox paddingTop="20px">
                <Typography style={{ fontSize: "12pt" }}>
                  What is the latest time a user can place an order to be eligible for same day
                  shipping ?
                </Typography>
                <TextField
                  type="time"
                  min="11:00"
                  max="23:00"
                  value={shipping ? shipping.cutoffTime : ""}
                  onChange={(e) => setShipping({ ...shipping, cutoffTime: e.target.value })}
                  style={{ pointerEvents: updating ? "initial" : "none" }}
                />
              </MDBox>
            )}
            {shipping && shipping.accept && (
              <MDBox paddingTop="20px">
                <Typography style={{ fontSize: "12pt" }}>
                  What is the earliest time you can ship a product?
                </Typography>
                <TextField
                  type="time"
                  min="01:00"
                  max="13:00"
                  value={shipping ? shipping.earliestShipTime : ""}
                  onChange={(e) => setShipping({ ...shipping, earliestShipTime: e.target.value })}
                  style={{ pointerEvents: updating ? "initial" : "none" }}
                />
              </MDBox>
            )}
            {/* </MDBox> */}
          </Grid>

          <Grid item xs={12} md={6}>
            {/* <MDBox minWidth="320px" width="47%" display="flex" flexDirection="column"> */}
            <MDBox paddingTop="20px">
              {shipping && shipping.accept && (
                <FormControl style={{ width: "100%", textAlign: "left" }}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ fontSize: "12pt", color: "black" }}
                  >
                    How do you determine how much time it takes to prepare goods for shipping after
                    an order is received?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={shipping ? shipping.handlingType : ""}
                    onChange={(e) => setShipping({ ...shipping, handlingType: e.target.value })}
                    style={{ pointerEvents: updating ? "initial" : "none" }}
                  >
                    <FormControlLabel
                      value={"constant"}
                      control={<Radio />}
                      label="Constant packaging time for all individual order items"
                    />
                    <FormControlLabel
                      value={"custom"}
                      control={<Radio />}
                      label="Packaging time depends on item ordered"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </MDBox>
            {shipping && shipping.accept && shipping.handlingType === "constant" && (
              <MDBox>
                <Typography style={{ fontSize: "12pt" }}>
                  How much time does it take you to prepare goods for shipping?
                </Typography>
                <MDBox display="flex" justifyContent="start" marginTop="20px">
                  <FormControl width="45%">
                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        shipping && shipping.handlingTime ? shipping.handlingTime.unit : "days"
                      }
                      label="Unit"
                      style={{
                        width: "150px",
                        padding: "8px",
                        pointerEvents: updating ? "initial" : "none",
                      }}
                      onChange={(e) =>
                        setShipping({
                          ...shipping,
                          handlingTime: { ...shipping.handlingTime, unit: e.target.value },
                        })
                      }
                    >
                      <MenuItem value={"hours"}>Hours</MenuItem>
                      <MenuItem value={"days"}>Days</MenuItem>
                      <MenuItem value={"weeks"}>Weeks</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Value"
                    size="small"
                    value={shipping && shipping.handlingTime ? shipping.handlingTime.amount : ""}
                    onChange={(e) =>
                      setShipping({
                        ...shipping,
                        handlingTime: { ...shipping.handlingTime, amount: e.target.value },
                      })
                    }
                    style={{ marginLeft: "20px", pointerEvents: updating ? "initial" : "none" }}
                  />
                </MDBox>
              </MDBox>
            )}
            {shipping && shipping.accept && shipping.handlingType === "custom" && (
              <MDBox>
                <Typography style={{ fontSize: "12pt" }}>
                  * Make sure to include the handling time in the description of each individual
                  product
                </Typography>
              </MDBox>
            )}
            {/* </MDBox> */}
          </Grid>
        </Grid>
      </MDBox>
      {renderSuccessSB}
      {renderErrorSB}
      {renderWarningSB}
    </>
  );
}

export default Policies;
