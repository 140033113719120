"use client";
import { createContext, useContext, useState, useEffect } from "react";
import { fetchAccountProfile } from "redux/routes/profile";
import { fetchFullProfile } from "redux/routes/profile";

export const GlobalContext = createContext({});

export const GlobalContextProvider = ({ children }) => {
  const [profile, setProfile] = useState({});
  const [mpesaTill, setMpesaTill] = useState({});
  const [mpesaPaybill, setMpesaPaybill] = useState({});
  const [account, setAccount] = useState({});
  const [business, setBusiness] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);

  const initProfile = async () => {
    let res = await fetchFullProfile();
    if (res.success) {
      setProfile(res.data.profile);
      setMpesaTill(res.data.MPesaTill ? res.data.MPesaTill : {});
      setMpesaPaybill(res.data.MPesaPaybill ? res.data.MPesaPaybill : {});
    }

    res = await fetchAccountProfile();
    if (res.success) setAccount(res.data);
  };

  useEffect(() => {
    initProfile();
    if (window.localStorage.getItem("token")) {
      setLoggedIn(true);
    }
  }, [business]);

  return (
    <div>
      <GlobalContext.Provider
        value={{
          profile,
          setProfile,
          mpesaTill,
          setMpesaTill,
          mpesaPaybill,
          setMpesaPaybill,
          account,
          setAccount,
          loggedIn,
          setLoggedIn,
          business,
          setBusiness,
        }}
      >
        {children}
      </GlobalContext.Provider>
    </div>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
