import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
// import { addProduct } from "redux/features/productsSlice";
import MDSnackbar from "components/MDSnackbar";
import { CancelOutlined } from "@mui/icons-material";
import { addProduct } from "redux/routes/products";

function NewProduct({ storeData, setStoreData, categories }) {
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const [building, setBuilding] = useState("Uploading...");

  let prodObj = {
    img: null,
    name: "",
    category: "",
    subcategory: "",
    price: 0,
    gender: "",
    description: "",
    extras: {},
    brand: "",
    condition: "",
  };

  const [prod, setProd] = useState(prodObj);

  useEffect(() => {
    if (prod.img !== null && prod.img.size / (1024 * 1024) > 10) {
      setErr("Please upload an image under 10MB.");
      openErrorSB();
      return;
    }
  }, [prod.img]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setProd({ ...prod, [name]: value });
  };
  // const categories = useSelector((state) => state.products.categories);

  // const dispatch = useDispatch();

  function containsUnwantedHtmlTags(inputString) {
    const htmlTagPattern = /<[^>]+>/g;

    const allowedTags = [
      "<p>",
      "<ul>",
      "<strong>",
      "<b>",
      "<br>",
      "<ol>",
      "<li>",
      "<em>",
      "<i>",
      "</p>",
      "</ul>",
      "</strong>",
      "</b>",
      "</br>",
      "</ol>",
      "</li>",
      "</em>",
      "</i>",
      "<br/>",
      "</h2>",
      "</h3>",
      "<h2>",
      "<h3>",
    ];

    const foundTags = inputString.match(htmlTagPattern);

    if (foundTags) {
      for (const tag of foundTags) {
        const isAllowed = allowedTags.some((allowedTag) => tag.startsWith(allowedTag));
        if (!isAllowed) {
          return true; // Found a disallowed HTML tag.
        }
      }
    }

    return false; // No disallowed HTML tags found.
  }

  const addProductHandler = async () => {
    if (isAdding) {
      return;
    }
    const fd = new FormData();

    if (prod.img === null) {
      setErr("Please provide an image");
      openErrorSB();
      return;
    }
    if (prod.img.size / (1024 * 1024) > 10) {
      setErr("Please upload an image under 10MB for web compatibility.");
      openErrorSB();
      return;
    }

    if (
      prod.description === "" ||
      (prod.name === "" && prod.subcategory !== "Vehicles") ||
      prod.category === "" ||
      (prod.subcategory === "" && prod.category !== "Other") ||
      (prod.subcategory === "Vehicles" && (make === "" || model === ""))
    ) {
      setErr("Fill in all required fields");
      openErrorSB();
      return;
    }
    if (prod.price < 0) {
      setErr("The price cannot be less than 0");
      openErrorSB();
      return;
    }
    if (containsUnwantedHtmlTags(prod.description)) {
      setErr(
        "Invalid description. Only p, ul, ol, li, strong, b, br, em & i tags are allowed here"
      );
      openErrorSB();
      return;
    }
    if (prod.subcategory === "Vehicles") {
      prod.extras = { make, model };
    }

    fd.append("img", prod.img);
    fd.append("description", prod.description);
    fd.append("name", prod.name);
    fd.append("category", prod.category);
    fd.append(
      "subcategory",
      prod.subcategory === "Other" || prod.category === "Other" ? otherSubCat : prod.subcategory
    );
    fd.append("extras", JSON.stringify(prod.extras));
    fd.append("price", prod.price);
    fd.append("gender", prod.gender);
    fd.append("brand", prod.brand);
    fd.append("condition", prod.condition);
    setBuilding("Uploading...");
    setIsAdding(true);
    setTimeout(() => {
      setBuilding("Building Product Page...");
    }, 10000);
    setTimeout(() => {
      setBuilding("Almost There...");
    }, 20000);
    setTimeout(() => {
      setBuilding("Just a few seconds left...");
    }, 35000);
    let res = await addProduct(fd);

    //dispatch(addProduct(fd));
    if (res.success) {
      openSuccessSB();
      setStoreData([...storeData, res.data]);
      setProd(prodObj);
      document.getElementById("image-select").value = null;
    } else {
      setErr("Unable to add product");
      openErrorSB();
    }

    setIsAdding(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Add Product"
      content="New Product Added Successfully"
      dateTime="just now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bggreen="true"
    />
  );
  let [err, setErr] = useState("");
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Adding Product"
      content={err}
      dateTime="1 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgindianred="true"
    />
  );

  const getURL = (image) => {
    let url = URL.createObjectURL(image);
    return url;
  };

  // const uploadProgress = useSelector((state) => state.products.uploadProgress);
  const changeCondition = (e) => {
    setProd({ ...prod, condition: e.target.value });
  };

  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  useEffect(() => {
    if (prod.subcategory === "Vehicles") {
      setProd({ ...prod, name: make + " " + model });
    }
  }, [make, model]);
  const [otherSubCat, setOther] = useState("");
  return (
    <MDBox
      borderRadius="10px"
      className="product"
      m={2}
      p={1}
      style={{ boxShadow: "0 0 5px grey" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <MDBox
            textAlign="center"
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              // backgroundColor: "red",
              height: "100%",
            }}
            onClick={() => document.getElementById("image-select").click()}
          >
            <MDBox
              style={{
                backgroundColor: "#eeeeee",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                id="image-select"
                style={{ display: "none" }}
                onChange={(e) => {
                  setProd({ ...prod, img: e.target.files[0] });
                }}
              />
              {prod.img ? (
                <img
                  className="item-image"
                  src={getURL(prod.img)}
                  alt={prod.name}
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <MDBox display="flex" justifyContent="center" alignItems="center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                    style={{ width: "100px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                    />
                  </svg>
                </MDBox>
              )}
            </MDBox>
            <p style={{ fontSize: "10pt" }}>
              * Select the primary product Image. You will be able to add more images later
            </p>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <MDBox pr={1} pb={1} textAlign="center">
            <MDBox width="100%" pt={2} textAlign="center">
              <Autocomplete
                size="small"
                disablePortal
                isOptionEqualToValue={(option, value) => option === value}
                options={categories.map((cat) => cat.name)}
                renderInput={(params) => <TextField {...params} label="Select Category" />}
                value={prod.category}
                style={{ width: 100 + "%", margin: "0 auto" }}
                name="type"
                onChange={(e, v) => {
                  setProd({ ...prod, category: v, subcategory: "" });
                }}
              />
            </MDBox>
            {prod.category !== "Other" && (
              <MDBox width="100%" pt={2} textAlign="center">
                <Autocomplete
                  size="small"
                  disablePortal
                  isOptionEqualToValue={(option, value) => option === value}
                  options={
                    categories.filter((cat) => cat.name === prod.category).length > 0
                      ? [
                          ...categories.filter((cat) => cat.name === prod.category)[0]
                            .subcategories,
                          "Other",
                        ]
                      : []
                  }
                  renderInput={(params) => <TextField {...params} label="Select Sub-category" />}
                  value={prod.subcategory}
                  style={{ width: 100 + "%", margin: "0 auto" }}
                  name="subcategory"
                  onChange={(e, v) => {
                    setProd({ ...prod, subcategory: v });
                  }}
                />
              </MDBox>
            )}
            {(prod.category === "Other" || prod.subcategory === "Other") && (
              <MDBox width="100%" pt={2} textAlign="center">
                <MDInput
                  size="small"
                  label="Enter Sub-category"
                  value={otherSubCat}
                  type="text"
                  name="subcategory"
                  inputProps={{ maxLength: 50 }}
                  style={{ width: 100 + "%" }}
                  onChange={(e) => setOther(e.target.value)}
                />
              </MDBox>
            )}
            {prod.subcategory === "Vehicles" && (
              <>
                <MDBox width="100%" pt={2} textAlign="center">
                  <MDInput
                    size="small"
                    label="Make"
                    value={make}
                    type="text"
                    name="Make"
                    inputProps={{ maxLength: 50 }}
                    style={{ width: 100 + "%" }}
                    onChange={(e) => setMake(e.target.value)}
                  />
                </MDBox>
                <MDBox width="100%" pt={2} textAlign="center">
                  <MDInput
                    size="small"
                    label="Model"
                    value={model}
                    type="text"
                    name="Model"
                    inputProps={{ maxLength: 50 }}
                    style={{ width: 100 + "%" }}
                    onChange={(e) => setModel(e.target.value)}
                  />
                </MDBox>
              </>
            )}

            {prod.subcategory !== "Vehicles" && (
              <MDBox width="100%" pt={2} textAlign="center">
                <MDInput
                  size="small"
                  label="Title"
                  value={prod.name}
                  type="text"
                  name="name"
                  inputProps={{ maxLength: 150 }}
                  style={{ width: 100 + "%" }}
                  onChange={onChange}
                />
              </MDBox>
            )}

            {prod.category === "Fashion" && (
              <MDBox width="100%" pt={2} textAlign="center">
                <Autocomplete
                  size="small"
                  disablePortal={true}
                  isOptionEqualToValue={(option, value) => option === value}
                  options={["Male", "Female", "Unisex"]}
                  renderInput={(params) => <TextField {...params} label="Select Gender" />}
                  value={prod.gender}
                  style={{
                    width: 100 + "%",
                    margin: "0 auto",
                  }}
                  name="type"
                  onChange={(e, v) => setProd({ ...prod, gender: v })}
                />
              </MDBox>
            )}
            {prod.subcategory !== "Vehicles" && (
              <MDBox width="100%" pt={2} textAlign="center">
                <MDInput
                  size="small"
                  label="Brand (Optional)"
                  value={prod.brand}
                  type="text"
                  name="brand"
                  inputProps={{ maxLength: 50 }}
                  style={{ width: 100 + "%" }}
                  onChange={onChange}
                />
              </MDBox>
            )}
            {prod.subcategory === "Vehicles" ? (
              <MDBox width="100%" pt={2} pl={1} textAlign="left">
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  size="small"
                  style={{ fontSize: "10pt", textAlign: "left" }}
                >
                  Condition
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={prod.condition}
                  onChange={changeCondition}
                  row
                >
                  <FormControlLabel
                    value="Brand New"
                    control={<Radio />}
                    label="Brand New"
                    style={{ fontWeight: "normal !important" }}
                  />
                  <FormControlLabel
                    value="Foreign Used"
                    control={<Radio />}
                    label="Foreign Used"
                    style={{ fontWeight: "normal !important" }}
                  />
                  <FormControlLabel
                    value="Local Used"
                    control={<Radio />}
                    label="Local Used"
                    style={{ fontWeight: "normal" }}
                  />
                </RadioGroup>
              </MDBox>
            ) : (
              <MDBox width="100%" pt={2} pl={1} textAlign="left">
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  size="small"
                  style={{ fontSize: "10pt", textAlign: "left" }}
                >
                  Condition (Optional)
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={prod.condition}
                  onChange={changeCondition}
                  row
                >
                  <FormControlLabel
                    value="New"
                    control={<Radio />}
                    label="New"
                    style={{ fontWeight: "normal !important" }}
                  />
                  <FormControlLabel
                    value="Used"
                    control={<Radio />}
                    label="Used"
                    style={{ fontWeight: "normal !important" }}
                  />
                  <FormControlLabel
                    value="Refurbished"
                    control={<Radio />}
                    label="Refurbished"
                    style={{ fontWeight: "normal" }}
                  />
                </RadioGroup>
              </MDBox>
            )}
            <MDBox width="100%" pt={2} textAlign="center">
              <MDInput
                size="small"
                label="Price"
                value={prod.price}
                type="number"
                style={{ width: 100 + "%" }}
                name="price"
                onChange={onChange}
                inputProps={{ min: 0, max: 99999999 }}
              />
            </MDBox>
            <MDBox
              width="100%"
              pt={2}
              textAlign="center"
              overflow-y="scroll"
              className="m-textarea"
            >
              <MDInput
                size="small"
                multiline={true}
                label="Description"
                value={prod.description}
                type="text"
                pt={2}
                inputProps={{ maxLength: 5000 }}
                style={{
                  width: 100 + "%",
                  overflowY: "auto",
                  height: 100 + "px",
                  paddingTop: 10 + "px",
                }}
                name="description"
                onChange={onChange}
              />
            </MDBox>
            <MDBox>
              <MDButton
                onClick={addProductHandler}
                style={{ backgroundColor: "dodgerblue", color: "white" }}
              >
                {!isAdding ? "Add Product" : building}
              </MDButton>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      {renderSuccessSB}
      {renderErrorSB}
    </MDBox>
  );
}

export default NewProduct;
