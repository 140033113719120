/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import { useEffect, useState } from "react";
import { Modal, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import axios from "redux/axios.config";
import { payInvoice } from "redux/routes/billing";
import { deactivatePackage } from "redux/routes/billing";
import { activatePackage } from "redux/routes/billing";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default ({
  setInvoices,
  lastPayment,
  mpackage,
  profile,
  invoices,
  setLastPayment,
  setSubscription,
  setPackage,
  status,
}) => {
  const [pending, setPending] = useState(false);
  useEffect(() => {
    invoices.map((inv) => {
      if (inv.purpose === "ACTIVATE - Business" && inv.status === "Processing") setPending(true);
    });
  }, [invoices]);

  const [code, setCode] = useState("");

  const submitPayment = async (amt) => {
    setPayError("");
    if (code === "") {
      setPayError("Please provide the payment code");
      return;
    }
    if (submitting) return;
    setSubmitting(true);
    let res = await payInvoice({ amount: amt, id: invNum, code, purpose: "ACTIVATE - Business" });

    if (res.success) {
      setOpenModal(false);
      setPending(true);
      setLastPayment(res.data.lastPayment);
      setInvoices(res.data.invoices.reverse());
    } else if (res.message) {
      setPayError(res.message);
    } else {
      setPayError("Error Occured. Kindly refresh page and try again");
    }
    setSubmitting(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = async () => {
    if (!invID) {
      await getActivationInvoice();
    }
    setOpenModal(true);
  };
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const openBuyModalHandler = async () => {
    if (!invID) {
      await getPurchaseInvoice();
    }
    setOpenBuyModal(true);
  };
  const [payError, setPayError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [invNum, setInvNum] = useState("GD" + (Date.now() % 100000));
  const [invID, setInvID] = useState("");
  const [invPurpose, setInvPurpose] = useState("");

  const deactivatePackageHandler = async () => {
    let res = await deactivatePackage();
    if (res.success) {
      setSubscription(res.data.subscription);
      setPackage(res.data.package);
      setLastPayment(res.data.lastPayment);
      setInvoices(res.data.invoices.reverse());
    }
  };

  const activatePackageHandler = async () => {
    let res = await activatePackage();
    if (res.success) {
      setSubscription(res.data.subscription);
      setPackage(res.data.package);
      setLastPayment(res.data.lastPayment);
      setInvoices(res.data.invoices.reverse());
    }
  };

  // MPesa Express
  const [paymentPhone, setPaymentPhone] = useState(profile.phone);
  const [paymentStatus, setPaymentStatus] = useState("Waiting");
  const checkForPayment = async (stkData) => {
    let id1 = setInterval(() => {
      if (paymentStatus.length === 19) setPaymentStatus("Waiting for payment.");
      if (paymentStatus.length === 20) setPaymentStatus("Waiting for payment..");
      if (paymentStatus.length === 21) setPaymentStatus("Waiting for payment...");
      if (paymentStatus.length === 22) setPaymentStatus("Waiting for payment");
    }, 1000);

    const queryPayment = async () => {
      let res = await axios.post("/query-payment", stkData);
      res = res.data;
      if (res.success) {
        if (res.data.paid) {
          clearInterval(id1);
          setPaymentStatus(
            "We have successfully received your payment. Kindly refresh the page to update your profile"
          );
        } else {
          setPayError("Unable to complete transaction. Try again?");
          setSentStk(false);
        }

        return true;
      } else {
        return false;
      }
    };
    let intervalId = setInterval(async () => {
      let res = await queryPayment();
      if (res) {
        clearInterval(intervalId);
      }
    }, 2000);

    // if(res.data)
  };
  const [sendingStk, setSendingStk] = useState(false);
  const [sentStk, setSentStk] = useState(false);
  const [manualPayment, setManualPayment] = useState(false);
  const getActivationInvoice = async () => {
    let res = await axios.post("/invoices/generate", {
      amount: 3000,
      id: invNum,
      purpose: "ACTIVATE - Business",
    });
    res = res.data;
    if (res.success) {
      let inv = res.data;
      setInvID(inv._id);
      setInvNum(inv.invNum);
      setInvPurpose(inv.description);
      return true;
    } else {
      return false;
    }
  };
  const getPurchaseInvoice = async () => {
    let res = await axios.post("/invoices/generate", {
      amount: 6000,
      id: invNum,
      purpose: "Purchase - Starter",
    });
    res = res.data;
    if (res.success) {
      let inv = res.data;
      setInvID(inv._id);
      setInvNum(inv.invNum);
      setInvPurpose(inv.description);
      return true;
    } else {
      return false;
    }
  };
  const initPayment = async (amt) => {
    setPayError("");
    if (sendingStk) return;
    setSendingStk(true);

    if (!invID) {
      setPayError("Unable to generate invoice. Refresh the page and try again");
    }
    let iid = invID;

    let res = await axios.post("/initiate-payment", {
      phone: paymentPhone,
      amount: amt,
      invoiceNum: invNum,
      description: iid,
      iid: iid,
    });
    res = res.data;
    if (res.success) {
      setSentStk(true);
      res.data.iid = iid;
      checkForPayment(res.data);
    } else {
      setManualPayment(true);
      setPayError("Unable to initiate payment. Kindly use this procedure to submit the payment");
    }
    setSendingStk(false);
  };

  return (
    <MDBox>
      <MDBox px={4}>
        <MDTypography variant="h3">Packages</MDTypography>
      </MDBox>
      <MDBox px={4} py={2} display="flex" justifyContent="space-evenly" flexWrap="wrap">
        <MDBox style={{ width: "100%" }}>
          <Typography>
            Active Package:{" "}
            {mpackage +
              (lastPayment.stopped ? " (Deactivated)" : "") +
              (status.subscription === "trial" ? " (Trial)" : "")}
          </Typography>
          <Typography>
            Monthly Payment: {mpackage === "Business" ? "Ksh. 3,000/=" : "Ksh 0.00/=="}
          </Typography>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MDBox
              style={{
                border: mpackage === "Starter" ? "solid 5px green" : "solid 1px gray",
                borderRadius: "7px",
                padding: "10px",
                height: "100%",
              }}
            >
              <Typography>Starter Package</Typography>
              <ul style={{ marginLeft: "30px", fontSize: "11pt" }}>
                <li>User-friendly interface and Responsive design</li>
                <li>Product catalog management (Up to 50 products)</li>
                <li>Secure and flexible payment gateways</li>
                <li>Online checkout and order processing</li>
                <li>SEO optimization</li>
                <li>Analytics and reporting</li>
                <li>Social Media Integration</li>
                <li>Live customer support (WhatsApp)</li>
              </ul>
              {status.subscription === "trial" && (
                <div style={{ marginTop: "15px" }}>
                  <MDButton
                    variant="gradient"
                    onClick={() => openBuyModalHandler()}
                    style={{ color: "white", backgroundColor: "green" }}
                  >
                    Purchase
                  </MDButton>

                  <Modal
                    open={openBuyModal}
                    onClose={() => setOpenBuyModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDBox sx={style}>
                      <MDTypography id="modal-modal-title" variant="h6" component="h2">
                        {manualPayment ? "Activate Business Package" : "Lipa na MPesa"}
                      </MDTypography>
                      <MDTypography
                        id="modal-modal-description"
                        variant="text"
                        sx={{ mt: 2, fontSize: "11pt" }}
                      >
                        <br />
                      </MDTypography>
                      <MDTypography
                        id="modal-modal-description"
                        variant="text"
                        sx={{ mt: 2, fontSize: "11pt" }}
                      >
                        {manualPayment ? (
                          <>
                            1. Go to MPesa Menu
                            <br />
                            2. Select Lipa na MPesa <br />
                            3. Select Pay Bill <br />
                            4. Enter business number - 4123793
                            <br />
                            5. Enter account number - {invNum}
                            <br />
                            6. Enter amount Ksh. 6000
                            <br />
                            7. Enter MPesa Pin <br />
                            Business Name: Bunika Solutions
                            <br />I Have Paid:{" "}
                            <MDInput
                              label="MPesa Code"
                              value={code}
                              onChange={(e) => setCode(e.target.value)}
                            />
                            <br />
                            <MDButton
                              size="small"
                              style={{ mt: 2, backgroundColor: "green", color: "white" }}
                              onClick={() => submitPayment(6000)}
                            >
                              {submitting ? "Submitting" : "Submit"}
                            </MDButton>
                          </>
                        ) : (
                          <>
                            <MDTypography sx={{ fontSize: "11pt" }}>
                              You will receive a prompt on your phone to complete the payment
                              <br />
                              <br />
                              <>
                                <b>Purpose</b> {invPurpose}
                              </>
                              <br />
                              <br />
                              Business Name: Bunika Solutions
                              <br />
                              Account Number: {invNum}
                              <br />
                              Amount Ksh. 6000
                            </MDTypography>

                            <MDInput
                              label="Phone Number"
                              value={paymentPhone}
                              onChange={(e) => setPaymentPhone(e.target.value)}
                              style={{ width: "100%", margin: "20px 0" }}
                            />

                            <br />
                            {sentStk ? (
                              <span>{paymentStatus}</span>
                            ) : (
                              <MDButton
                                size="small"
                                style={{
                                  mt: 2,
                                  backgroundColor: "green",
                                  color: "white",
                                  margin: "0 auto",
                                }}
                                onClick={() => initPayment(6000)}
                              >
                                {sendingStk ? "Submitting..." : "Proceed"}
                              </MDButton>
                            )}
                          </>
                        )}

                        <br />
                        {payError && <span style={{ color: "indianred" }}>{payError}</span>}
                      </MDTypography>
                    </MDBox>
                  </Modal>
                </div>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox
              style={{
                border: mpackage === "Business" ? "solid 5px green" : "solid 1px gray",
                borderRadius: "7px",
                padding: "10px",
              }}
            >
              <Typography>Business Package</Typography>
              <ul style={{ marginLeft: "30px", fontSize: "11pt" }}>
                <li>All features of Starter Package</li>
                <li>Unimited number of products</li>
                <li>Marketing and promotion tools</li>
                <li>Structured Product Specifications</li>
                <li>Reviews collection (Coming Soon)</li>
                <li>FAQ List Integration</li>
                <li>Highlight Unique Selling Points per product</li>
                <li>Set different prices for product variants as needed.(Coming Soon)</li>
                <li>Enhanced product descriptions with linked YouTube videos.</li>
                <li>Express Checkout (Buy Now button)</li>
              </ul>
              {status.subscription !== "trial" ? (
                <>
                  {mpackage === "Business" ? (
                    <>
                      {lastPayment.stopped ? (
                        <MDButton
                          variant="gradient"
                          onClick={() => activatePackageHandler()}
                          style={{ color: "white", backgroundColor: "green" }}
                        >
                          Activate
                        </MDButton>
                      ) : (
                        <MDButton
                          variant="gradient"
                          onClick={() => deactivatePackageHandler()}
                          style={{ color: "white", backgroundColor: "green" }}
                        >
                          De-activate
                        </MDButton>
                      )}
                    </>
                  ) : pending ? (
                    <>Pending Approval</>
                  ) : (
                    <>
                      <MDButton
                        variant="gradient"
                        onClick={() => openModalHandler()}
                        style={{ color: "white", backgroundColor: "green" }}
                      >
                        Activate
                      </MDButton>

                      <Modal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <MDBox sx={style}>
                          <MDTypography id="modal-modal-title" variant="h6" component="h2">
                            {manualPayment ? "Activate Business Package" : "Lipa na MPesa"}
                          </MDTypography>
                          <MDTypography
                            id="modal-modal-description"
                            variant="text"
                            sx={{ mt: 2, fontSize: "11pt" }}
                          >
                            <br />
                          </MDTypography>
                          <MDTypography
                            id="modal-modal-description"
                            variant="text"
                            sx={{ mt: 2, fontSize: "11pt" }}
                          >
                            {manualPayment ? (
                              <>
                                1. Go to MPesa Menu
                                <br />
                                2. Select Lipa na MPesa <br />
                                3. Select Pay Bill <br />
                                4. Enter business number - 4123793
                                <br />
                                5. Enter account number - {invNum}
                                <br />
                                6. Enter amount Ksh. 3000
                                <br />
                                7. Enter MPesa Pin <br />
                                Business Name: Bunika Solutions
                                <br />I Have Paid:{" "}
                                <MDInput
                                  label="MPesa Code"
                                  value={code}
                                  onChange={(e) => setCode(e.target.value)}
                                />
                                <br />
                                <MDButton
                                  size="small"
                                  style={{ mt: 2, backgroundColor: "green", color: "white" }}
                                  onClick={() => submitPayment(3000)}
                                >
                                  {submitting ? "Submitting" : "Submit"}
                                </MDButton>
                              </>
                            ) : (
                              <>
                                <MDTypography sx={{ fontSize: "11pt" }}>
                                  You will receive a prompt on your phone to complete the payment
                                  <br />
                                  <br />
                                  <>
                                    <b>Purpose</b> {invPurpose}
                                  </>
                                  <br />
                                  <br />
                                  Business Name: Bunika Solutions
                                  <br />
                                  Account Number: {invNum}
                                  <br />
                                  Amount Ksh. 3000
                                </MDTypography>

                                <MDInput
                                  label="Phone Number"
                                  value={paymentPhone}
                                  onChange={(e) => setPaymentPhone(e.target.value)}
                                  style={{ width: "100%", margin: "20px 0" }}
                                />

                                <br />
                                {sentStk ? (
                                  <span>{paymentStatus}</span>
                                ) : (
                                  <MDButton
                                    size="small"
                                    style={{
                                      mt: 2,
                                      backgroundColor: "green",
                                      color: "white",
                                      margin: "0 auto",
                                    }}
                                    onClick={() => initPayment(3000)}
                                  >
                                    {sendingStk ? "Submitting..." : "Proceed"}
                                  </MDButton>
                                )}
                              </>
                            )}

                            <br />
                            {payError && <span style={{ color: "indianred" }}>{payError}</span>}
                          </MDTypography>
                        </MDBox>
                      </Modal>
                    </>
                  )}
                </>
              ) : (
                <span style={{ fontSize: "11pt" }}>
                  * Purchase the starter package to be able to upgrade to business
                </span>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};
