import axios from "../axios.config";

export const fetchPolicies = async () => {
  try {
    let res = await axios.get("/policies");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updatePolicies = async (payload) => {
  try {
    let res = await axios.post("/update/policies", payload);
    return res.data;
  } catch (err) {
    return err;
  }
};
